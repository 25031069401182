import React from 'react';
import PandoraModel from "traits/PandoraModel";
import PhonelinkLockIcon from '@material-ui/icons/PhonelinkLock';
import * as License from "license.js";
import { randomString } from "utils.js";

class TrustedDevice extends PandoraModel {
	static modelName = "TrustedDevice";
	static getLicense() { return License.ICECLOUD}

	static icon = PhonelinkLockIcon;

	// static orderable = true;
	// static copyable = true;
	// static hasCrudPages = false;
	static crudAsNotification = true;
	// static editableOnTable = true;

	// PANDORA_ANCHOR_INSERT_OVERRIDE_VARIABLES_HERE
	static permissionNamesAreSingular = true;

	static cards = [
		{
			title: "GENERAL_INFO",
			icon: PhonelinkLockIcon,
			fields: {
				// PANDORA_ANCHOR_INSERT_FIELDS_HERE
				key: {type: "text", required: true, defaultOnStore: () => (localStorage.getItem("trusted_device_key") ?? randomString(12)), visible: false, },
				name: {type: "text", required: true, showOnTable: true, },
				allow_nfc_login: {type: "checkbox", },
			},
		},
		// {
		// 	title: "GENERAL_INFO",
		// 	icon: PhonelinkLockIcon,
		// 	fields: {
		//
		// 	},
		// },
		
		// {
		// 	title: "GENERAL_INFO",
		// 	icon: PhonelinkLockIcon,
		// 	fields: {
		// 		// text: {type: "text", required: true, },
		// 		// textarea: {type: "text", multiline: true, rows: 12, required: true, },
		// 		// number: {type: "number", required: true, inputProps: {min: 0, max: 99999, step: 1}, },
		// 		// select: {type: "select", required: true, options: ["OPTION"], },
		// 		// checkbox: {type: "checkbox", },
				
		// 		// md12: {type: "text", md: 12, },
		// 		// showOnTable: {type: "text", showOnTable: true, },
		// 	},
		// },

	];

	// PANDORA_ANCHOR_INSERT_OVERRIDE_FUNCTIONS_HERE
	// static getCopyableChildren() { return [TrustedDeviceItem]; };
}

export default TrustedDevice;