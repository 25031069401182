// PANDORA_ANCHOR_INSERT_PERMISSIONS_HERE
export const ICECLOUD_EMPLOYEES_RESTORE_LIST = 'ICECLOUD_EMPLOYEES_RESTORE_LIST';
export const ICECLOUD_EMPLOYEES_RESTORE = 'ICECLOUD_EMPLOYEES_RESTORE';
export const ICECLOUD_EMPLOYEES_LIST = 'ICECLOUD_EMPLOYEES_LIST';
export const ICECLOUD_EMPLOYEES_ADD = 'ICECLOUD_EMPLOYEES_ADD';
export const ICECLOUD_EMPLOYEES_EDIT = 'ICECLOUD_EMPLOYEES_EDIT';
export const ICECLOUD_EMPLOYEES_DELETE = 'ICECLOUD_EMPLOYEES_DELETE';
export const ICECLOCK_CONTRACTS_MODERATE = 'ICECLOCK_CONTRACTS_MODERATE';
export const ICECLOCK_CONTRACTS_VIEW = 'ICECLOCK_CONTRACTS_VIEW';
export const ICECLOUD_TRUSTED_DEVICE_MODERATE = 'ICECLOUD_TRUSTED_DEVICE_MODERATE';
export const ICECLOUD_TRUSTED_DEVICE_VIEW = 'ICECLOUD_TRUSTED_DEVICE_VIEW';
export const ICESHOP_SHIPMENT_ZONES_MODERATE = 'ICESHOP_SHIPMENT_ZONES_MODERATE';
export const ICESHOP_SHIPMENT_ZONES_VIEW = 'ICESHOP_SHIPMENT_ZONES_VIEW';
export const ICESHOP_DELIVERY_ZONES_MODERATE = 'ICESHOP_DELIVERY_ZONES_MODERATE';
export const ICESHOP_DELIVERY_ZONES_VIEW = 'ICESHOP_DELIVERY_ZONES_VIEW';
export const ICESHOP_PICK_UP_POINTS_MODERATE = 'ICESHOP_PICK_UP_POINTS_MODERATE';
export const ICESHOP_PICK_UP_POINTS_VIEW = 'ICESHOP_PICK_UP_POINTS_VIEW';
export const ICECASH_MENU_MODERATE = 'ICECASH_MENU_MODERATE';
export const ICECASH_MENU_VIEW = 'ICECASH_MENU_VIEW';
export const ICECLOCK_CONTRACT_TEMPLATES_MODERATE = 'ICECLOCK_CONTRACT_TEMPLATES_MODERATE';
export const ICECLOCK_CONTRACT_TEMPLATES_VIEW = 'ICECLOCK_CONTRACT_TEMPLATES_VIEW';
export const ICETRUCK_LOAD_SIZE_MODERATE = 'ICETRUCK_LOAD_SIZE_MODERATE';
export const ICETRUCK_LOAD_SIZE_VIEW = 'ICETRUCK_LOAD_SIZE_VIEW';

/*
_____ _____ ______     _                 _ 
|_   _/ ____|  ____|   | |               | |
	| || |    | |__   ___| | ___  _   _  __| |
	| || |    |  __| / __| |/ _ \| | | |/ _` |
_| || |____| |___| (__| | (_) | |_| | (_| |
|_____\_____|______\___|_|\___/ \__,_|\__,_|
																						
																						
*/

export const ICECLOUD_EMPLOYEES_PERMISSIONS_EDIT = 'ICECLOUD_EMPLOYEES_PERMISSIONS_EDIT';
export const ICECLOUD_EMPLOYEES_PERMISSION_GROUPS_EDIT = 'ICECLOUD_EMPLOYEES_PERMISSION_GROUPS_EDIT';

export const ICECLOUD_EMPLOYEE_NOTES_VIEW = 'ICECLOUD_EMPLOYEE_NOTES_VIEW';
export const ICECLOUD_EMPLOYEE_NOTES_ADD = 'ICECLOUD_EMPLOYEE_NOTES_ADD';
export const ICECLOUD_EMPLOYEE_NOTES_DELETE = 'ICECLOUD_EMPLOYEE_NOTES_DELETE';

export const ICECLOUD_COMPANY_VIEW = 'ICECLOUD_COMPANY_VIEW';
export const ICECLOUD_COMPANY_MODERATE = 'ICECLOUD_COMPANY_MODERATE';

export const ICECLOUD_COMPANYSTRUCTURE_VIEW = 'ICECLOUD_COMPANYSTRUCTURE_VIEW';
export const ICECLOUD_COMPANYSTRUCTURE_MODERATE = 'ICECLOUD_COMPANYSTRUCTURE_MODERATE';

export const ICECLOUD_WAGE_AGREEMENTS_VIEW = 'ICECLOUD_WAGE_AGREEMENTS_VIEW';
export const ICECLOUD_WAGE_AGREEMENTS_MODERATE = 'ICECLOUD_WAGE_AGREEMENTS_MODERATE';

export const ICECLOUD_PLACEHOLDER_ORDERS_VIEW = 'ICECLOUD_PLACEHOLDER_ORDERS_VIEW';
export const ICECLOUD_PLACEHOLDER_ORDERS_MODERATE = 'ICECLOUD_PLACEHOLDER_ORDERS_MODERATE';

export const ICECLOUD_INVOICES_VIEW = 'ICECLOUD_INVOICES_VIEW';
export const ICECLOUD_INVOICES_MODERATE = 'ICECLOUD_INVOICES_MODERATE';

export const ICECLOUD_GIFT_CARDS_BASIC = 'ICECLOUD_GIFT_CARDS_BASIC';
export const ICECLOUD_GIFT_CARDS_ADMIN = 'ICECLOUD_GIFT_CARDS_ADMIN';


export const ICECLOUD_PERMISSION_GROUP_VIEW = 'ICECLOUD_PERMISSION_GROUP_VIEW';
export const ICECLOUD_PERMISSION_GROUP_MODERATE = 'ICECLOUD_PERMISSION_GROUP_MODERATE';

export const ICECLOUD_SUPPLIER_VIEW = 'ICECLOUD_SUPPLIER_VIEW';
export const ICECLOUD_SUPPLIER_MODERATE = 'ICECLOUD_SUPPLIER_MODERATE';

export const ICECLOUD_SUPPLIER_ORDER_BASIC = 'ICECLOUD_SUPPLIER_ORDER_BASIC';
export const ICECLOUD_SUPPLIER_ORDER_EXTENDED = 'ICECLOUD_SUPPLIER_ORDER_EXTENDED';
export const ICECLOUD_SUPPLIER_ORDER_ADMIN = 'ICECLOUD_SUPPLIER_ORDER_ADMIN';

export const ICECLOUD_EMAIL_JOBS_ADD = 'ICECLOUD_EMAIL_JOBS_ADD';

export const ICECLOUD_FULLSCREEN_METRICS = 'ICECLOUD_FULLSCREEN_METRICS';

/*
_____ _____ ______     _                 
|_   _/ ____|  ____|   | |                
	| || |    | |__   ___| |__   ___  _ __  
	| || |    |  __| / __| '_ \ / _ \| '_ \ 
_| || |____| |____\__ \ | | | (_) | |_) |
|_____\_____|______|___/_| |_|\___/| .__/ 
																	| |    
																	|_|    
*/

export const ICESHOP_PRODUCT_VIEW = 'ICESHOP_PRODUCT_VIEW';
export const ICESHOP_PRODUCT_MODERATE = 'ICESHOP_PRODUCT_MODERATE';

export const ICESHOP_STOCK_ITEMS_VIEW = 'ICESHOP_STOCK_ITEMS_VIEW';
export const ICESHOP_STOCK_ITEMS_MODERATE = 'ICESHOP_STOCK_ITEMS_MODERATE';

export const ICESHOP_WORKSHEET_VIEW = 'ICESHOP_WORKSHEET_VIEW';
export const ICESHOP_WEBSHOP_ORDER_VIEW = 'ICESHOP_WEBSHOP_ORDER_VIEW';
export const ICESHOP_WEBSHOP_ORDER_MODERATE = 'ICESHOP_WEBSHOP_ORDER_MODERATE';
export const ICESHOP_WEBSHOP_ORDER_EDIT_STATUS = 'ICESHOP_WEBSHOP_ORDER_EDIT_STATUS';
	
export const ICESHOP_PAYMENT_MODERATE = 'ICESHOP_PAYMENT_MODERATE';

export const ICESHOP_BASKET_MODERATE = 'ICESHOP_BASKET_MODERATE';

export const ICESHOP_WEBSHOP_ACCOUNTS_VIEW = 'ICESHOP_WEBSHOP_ACCOUNTS_VIEW';
export const ICESHOP_WEBSHOP_ACCOUNTS_MODERATE = 'ICESHOP_WEBSHOP_ACCOUNTS_MODERATE';
export const ICESHOP_WEBSHOP_ACCOUNTS_LOGIN = 'ICESHOP_WEBSHOP_ACCOUNTS_LOGIN';

export const ICESHOP_WEBSHOP_ACCOUNT_ADDRESSES_VIEW = 'ICESHOP_WEBSHOP_ACCOUNT_ADDRESSES_VIEW';
export const ICESHOP_WEBSHOP_ACCOUNT_ADDRESSES_MODERATE = 'ICESHOP_WEBSHOP_ACCOUNT_ADDRESSES_MODERATE';

export const ICESHOP_DISCOUNTS_VIEW = 'ICESHOP_DISCOUNTS_VIEW';
export const ICESHOP_DISCOUNTS_MODERATE = 'ICESHOP_DISCOUNTS_MODERATE';

export const ICESHOP_TABLETS_VIEW = 'ICESHOP_TABLETS_VIEW';
export const ICESHOP_TABLETS_MODERATE = 'ICESHOP_TABLETS_MODERATE';
export const ICESHOP_TABLETS_LOGIN = 'ICESHOP_TABLETS_LOGIN';

export const ICESHOP_DELIVERY_VANS_VIEW = 'ICESHOP_DELIVERY_VANS_VIEW';
export const ICESHOP_DELIVERY_VANS_MODERATE = 'ICESHOP_DELIVERY_VANS_MODERATE';

export const ICESHOP_DELIVERY_SCHEDULES_VIEW = 'ICESHOP_DELIVERY_SCHEDULES_VIEW';
export const ICESHOP_DELIVERY_SCHEDULES_MODERATE = 'ICESHOP_DELIVERY_SCHEDULES_MODERATE';

export const ICESHOP_DAILY_REPORT_VIEW = 'ICESHOP_DAILY_REPORT_VIEW';

export const ICESHOP_SUPPLIED_ITEMS_VIEW = 'ICESHOP_SUPPLIED_ITEMS_VIEW';
export const ICESHOP_SUPPLIED_ITEMS_MODERATE = 'ICESHOP_SUPPLIED_ITEMS_MODERATE';




export const ICESHOP_DAMAGE_REPORTS_VIEW = 'ICESHOP_DAMAGE_REPORTS_VIEW';
export const ICESHOP_DAMAGE_REPORTS_MODERATE = 'ICESHOP_DAMAGE_REPORTS_MODERATE';

/*
_____ _____ ______ _                   _    
|_   _/ ____|  ____| |                 | |   
	| || |    | |__  | |_ _ __ _   _  ___| | __
	| || |    |  __| | __| '__| | | |/ __| |/ /
_| || |____| |____| |_| |  | |_| | (__|   < 
|_____\_____|______|\__|_|   \__,_|\___|_|\_\
																						
																						
*/

export const ICETRUCK_TABLETS_VIEW = 'ICETRUCK_TABLETS_VIEW';
export const ICETRUCK_TABLETS_MODERATE = 'ICETRUCK_TABLETS_MODERATE';
export const ICETRUCK_TABLETS_LOGIN = 'ICETRUCK_TABLETS_LOGIN';

export const ICETRUCK_ICECREAM_VANS_VIEW = 'ICETRUCK_ICECREAM_VANS_VIEW';
export const ICETRUCK_ICECREAM_VANS_MODERATE = 'ICETRUCK_ICECREAM_VANS_MODERATE';

export const ICETRUCK_ICECREAM_VAN_SCHEDULES_VIEW = 'ICETRUCK_ICECREAM_VAN_SCHEDULES_VIEW';
export const ICETRUCK_ICECREAM_VAN_SCHEDULES_MODERATE = 'ICETRUCK_ICECREAM_VAN_SCHEDULES_MODERATE';

export const ICETRUCK_ICECREAM_VAN_ZONES_VIEW = 'ICETRUCK_ICECREAM_VAN_ZONES_VIEW';
export const ICETRUCK_ICECREAM_VAN_ZONES_MODERATE = 'ICETRUCK_ICECREAM_VAN_ZONES_MODERATE';


export const ICETRUCK_ICECREAM_VAN_PREFERRED_ZONES_SELF = 'ICETRUCK_ICECREAM_VAN_PREFERRED_ZONES_SELF';
export const ICETRUCK_ICECREAM_VAN_PREFERRED_ZONES_VIEW = 'ICETRUCK_ICECREAM_VAN_PREFERRED_ZONES_VIEW';
export const ICETRUCK_ICECREAM_VAN_PREFERRED_ZONES_MODERATE = 'ICETRUCK_ICECREAM_VAN_PREFERRED_ZONES_MODERATE';

export const ICETRUCK_ICECREAM_VAN_RESERVATIONS_VIEW = 'ICETRUCK_ICECREAM_VAN_RESERVATIONS_VIEW';
export const ICETRUCK_ICECREAM_VAN_RESERVATIONS_MODERATE = 'ICETRUCK_ICECREAM_VAN_RESERVATIONS_MODERATE';

export const ICETRUCK_POINT_OF_INTEREST_VIEW = 'ICETRUCK_POINT_OF_INTEREST_VIEW';
export const ICETRUCK_POINT_OF_INTEREST_MODERATE = 'ICETRUCK_POINT_OF_INTEREST_MODERATE';

export const ICETRUCK_DAILY_SALES_REPORT_VIEW = 'ICETRUCK_DAILY_SALES_REPORT_VIEW';

export const ICECLOCK_AVAILABILITIES_REPORT_VIEW = 'ICECLOCK_AVAILABILITIES_REPORT_VIEW';

export const ICETRUCK_MAP_VIEW = 'ICETRUCK_MAP_VIEW';

export const ICETRUCK_DAMAGE_REPORTS_VIEW = 'ICETRUCK_DAMAGE_REPORTS_VIEW';
export const ICETRUCK_DAMAGE_REPORTS_MODERATE = 'ICETRUCK_DAMAGE_REPORTS_MODERATE';

/*
_____ _____ ______     _            _    
|_   _/ ____|  ____|   | |          | |   
	| || |    | |__   ___| | ___   ___| | __
	| || |    |  __| / __| |/ _ \ / __| |/ /
_| || |____| |___| (__| | (_) | (__|   < 
|_____\_____|______\___|_|\___/ \___|_|\_\
																					
																					
*/

export const ICECLOCK_WORKING_DAYS_SELF = 'ICECLOCK_WORKING_DAYS_SELF';
export const ICECLOCK_WORKING_DAYS_VIEW = 'ICECLOCK_WORKING_DAYS_VIEW';
export const ICECLOCK_WORKING_DAYS_MODERATE = 'ICECLOCK_WORKING_DAYS_MODERATE';

export const ICECLOCK_AVAILABILITIES_SELF = 'ICECLOCK_AVAILABILITIES_SELF';
export const ICECLOCK_ROSTERS_ASSIGN_SELF = 'ICECLOCK_ROSTERS_ASSIGN_SELF';
export const ICECLOCK_AVAILABILITIES_VIEW = 'ICECLOCK_AVAILABILITIES_VIEW';
export const ICECLOCK_AVAILABILITIES_MODERATE = 'ICECLOCK_AVAILABILITIES_MODERATE';
export const ICECLOCK_AVAILABILITIES_COLORS = 'ICECLOCK_AVAILABILITIES_COLORS';

export const ICECLOCK_CONTRACTS_EDIT_NOT_VALIDATED = 'ICECLOCK_CONTRACTS_EDIT_NOT_VALIDATED';


export const ICECLOCK_SCHEDULES_VIEW = 'ICECLOCK_SCHEDULES_VIEW';
export const ICECLOCK_SCHEDULES_MODERATE = 'ICECLOCK_SCHEDULES_MODERATE';

/*
_____ _____ ______              _     
|_   _/ ____|  ____|            | |    
	| || |    | |__   ___ __ _ ___| |__  
	| || |    |  __| / __/ _` / __| '_ \ 
_| || |____| |___| (_| (_| \__ \ | | |
|_____\_____|______\___\__,_|___/_| |_|
																			
																			
*/


export const ICECASH_SALE_MODERATE = 'ICECASH_SALE_MODERATE';

export const LEGACYINTRANET_USE = 'LEGACYINTRANET_USE';

export const ICECLOCK_WORKING_DAYS_ALARM = 'ICECLOCK_WORKING_DAYS_ALARM';
export const ICECLOCK_NOT_TRUSTED_ALARM = 'ICECLOCK_NOT_TRUSTED_ALARM';

export const ICEGUARD_WORKING_DAY_TOO_LONG_BCC = 'ICEGUARD_WORKING_DAY_TOO_LONG_BCC';
export const ICEGUARD_ICECREAM_VAN_SCHEDULE_PROBLEMS = 'ICEGUARD_ICECREAM_VAN_SCHEDULE_PROBLEMS';
export const ICEGUARD_DELIVERY_SCHEDULE_PROBLEMS = 'ICEGUARD_DELIVERY_SCHEDULE_PROBLEMS';
export const ICEGUARD_SCHEDULE_PROBLEMS = 'ICEGUARD_SCHEDULE_PROBLEMS';
export const ICEGUARD_SCHEDULE_OVERDUE = 'ICEGUARD_SCHEDULE_OVERDUE';
export const ICEGUARD_STALE_CONTRACT_SERVICE_HEARTBEAT = 'ICEGUARD_STALE_CONTRACT_SERVICE_HEARTBEAT';
export const ICEGUARD_PRODUCT_OR_STOCK_ITEM_SOLD_OUT = 'ICEGUARD_PRODUCT_OR_STOCK_ITEM_SOLD_OUT';
export const ICEGUARD_ICECREAM_VAN_STILL_AT_DEPOT = 'ICEGUARD_ICECREAM_VAN_STILL_AT_DEPOT';