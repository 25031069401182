import React from 'react';
import PandoraModel from "traits/PandoraModel";
import PeopleIcon from '@material-ui/icons/People';
import ImageIcon from '@material-ui/icons/Image';
import InfoIcon from '@material-ui/icons/Info';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import MapIcon from '@material-ui/icons/Map';
import AttachmentIcon from '@material-ui/icons/Attachment';
import WarningIcon from '@material-ui/icons/Warning';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import * as License from "license.js";
import * as Permissions from "permission.js";

class Employee extends PandoraModel {
	static modelName = "Employee";
	static getLicense() { return License.ICECLOUD}

	static icon = PeopleIcon;

	// static orderable = true;
	// static copyable = true;
	static openNewResourceAfterCopy = false;
	// static loadBeforeCopy = false;
	// static hasCrudPages = false;
	// static crudAsNotification = true;
	// static editableOnTable = true;
	static tableAvatar = "profile_picture_uri";
	// static tableAvatar = () => "";

	// PANDORA_ANCHOR_INSERT_OVERRIDE_VARIABLES_HERE

	static cards = [
		{
			title: "PROFILE_PICTURE",
			icon: ImageIcon,
			md: 3,
			fields: {
				profile_picture_uri: { type: "file", filetypes: [".png", ".jpg", ".jpeg"], required: false, showPreview: true, },
			},
		},
		{
			title: "NAME",
			icon: PeopleIcon,
			md: 9,
			fields: {
				name: { type: "text", show: false, showOnTable: true, value: (resource) => resource.last_name.toUpperCase() + (resource.middle_name ? (" " + resource.middle_name) : "") + " " + resource.first_name, },
				last_name: { type: "text", maxLength: "255", required: true, xs: 12, sm: 6, md: 6, lg: 4, },
				middle_name: { type: "text", maxLength: "255", required: false, xs: 12, sm: 6, md: 6, lg: 4, },
				first_name: { type: "text", maxLength: "255", required: true, xs: 12, sm: 6, md: 6, lg: 4, },
			},
		},
		{
			title: "ADDRESS",
			icon: MapIcon,
			fields: {
				address_street: { type: "text", maxLength: "255", required: true, xs: 12, sm: 12, md: 12, lg: 8, },
				address_nr: { type: "text", maxLength: "255", required: true, xs: 12, sm: 4, md: 4, lg: 2, },
				address_bus: { type: "text", maxLength: "255", required: false, xs: 12, sm: 4, md: 4, lg: 2, },
				address_zipcode: { type: "text", maxLength: "255", required: true, xs: 12, sm: 5, md: 5, lg: 5, },
				address_city: { type: "text", maxLength: "255", required: true, xs: 12, sm: 7, md: 7, lg: 7, },
				address_country: { type: "text", maxLength: "255", required: true, xs: 12, sm: 6, md: 6, lg: 6, },
			},
		},
		{
			title: "GENERAL_INFO",
			icon: InfoIcon,
			fields: {
				company_id: { type: "select", options: (state, props) => state.companyIdOptions ?? [], show: false, showOnCopy: true, },
				user: { type: "hidden", show: false, formdataValue: (resource) => ({"email": resource.email}), }, 
				is_on_leave: { type: "checkbox", required: false, xs: 12, md: 6, },
				"//empty_after_is_on_leave": {type: "empty", sm: 6, },
				national_registry_nr: { type: "text", maxLength: "255", required: true, xs: 12, sm: 12, md: 12, lg: 5, },
				birthday: { type: "date", required: true, default: "", xs: 12, sm: 6, md: 6, lg: 5, },
				sex: { type: "select", options: ["male", "female"], required: true, xs: 12, sm: 6, md: 6, lg: 2, },
				email: { type: "text", subType: "email", showOnTable: true, defaultOnUpdate: (resource) => resource.user.email, defaultOnCopy: (resource) => resource.user.email, valueOnTable: (resource) => resource.user.email, md: 6, }, 
				cellphone_nr: { type: "text", maxLength: "255", required: true, showOnTable: true, md: 6, },
				birthplace: { type: "text", maxLength: "255", required: true, md: 6, },
				nationality: { type: "text", maxLength: "255", required: true, md: 6, },
				iban: { type: "text", maxLength: "255", required: true, },
				drivers_license: { type: "text", maxLength: "255", required: false, xs: 12, sm: 6, md: 3, lg: 3, },
				nfc_token: { type: "text", maxLength: "255", required: false, xs: 12, sm: 6, md: 6, lg: 6, },
			},
		},
		{
			title: "FISCALITY",
			icon: AccountBalanceIcon,
			fields: {
				marital_status: { type: "select", options: ["SINGLE", "MARRIED", "WIDOWED", "DIVORCED", "SEPERATED", "REGISTERED_PARTNERSHIP"], required: true, xs: 12, sm: 12, md: 6, lg: 6, },
				partner_name: { type: "text", maxLength: "255", required: false, xs: 12, sm: 12, md: 6, lg: 6, },
				marriage_date: { type: "date", required: false, default: "", xs: 12, sm: 12, md: 6, lg: 6, },
				seniority_date: { type: "datetime", required: true, xs: 12, sm: 12, md: 6, lg: 6, },
				dependant_children: { type: "number", step: "1", required: false, xs: 12, sm: 12, md: 6, lg: 6, },
				dependant_others: { type: "number", step: "1", required: false, xs: 12, sm: 12, md: 6, lg: 6, },
			},
		},
		{
			title: "ICE",
			icon: WarningIcon,
			fields: {
				ice_name: { type: "text", maxLength: "255", required: false, sm: 6, },
				ice_relation: { type: "text", maxLength: "255", required: false, sm: 6, },
				ice_cellphone_nr: { type: "text", maxLength: "255", required: false, sm: 6, },
				"//empty_after_ice_cellphone_nr": {type: "empty", sm: 6, },
				ice2_name: { type: "text", maxLength: "255", required: false, sm: 6, },
				ice2_relation: { type: "text", maxLength: "255", required: false, sm: 6, },
				ice2_cellphone_nr: { type: "text", maxLength: "255", required: false, sm: 6, },
			},
		},
		{
			title: "CLOTHING",
			icon: AccessibilityNewIcon,
			fields: {
				clothing_general_size: { type: "text", maxLength: "255", required: false, sm: 3, },
				clothing_general_received: { type: "number", step: "1", required: false, sm: 3, },
				clothing_tshirt_size: { type: "text", maxLength: "255", required: false, sm: 3, },
				clothing_tshirt_received: { type: "number", step: "1", required: false, sm: 3, },
				clothing_sweater_size: { type: "text", maxLength: "255", required: false, sm: 3, },
				clothing_sweater_received: { type: "number", step: "1", required: false, sm: 3, },
				clothing_pants_size: { type: "text", maxLength: "255", required: false, sm: 3, },
				clothing_pants_received: { type: "number", step: "1", required: false, sm: 3, },
				clothing_shoes_size: { type: "text", maxLength: "255", required: false, sm: 3, },
				clothing_shoes_received: { type: "number", step: "1", required: false, sm: 3, },
				clothing_bottle_size: { type: "text", maxLength: "255", required: false, sm: 3, },
				clothing_bottle_received: { type: "number", step: "1", required: false, sm: 3, },
			},
		},
		{
			title: "FILES",
			icon: AttachmentIcon,
			fields: {
				electronic_identification_front_uri: { type: "file", filetypes: [".png", ".jpg", ".jpeg", ".pdf"], required: false, xs: 12, sm: 6, md: 6, lg: 6, },
				electronic_identification_back_uri: { type: "file", filetypes: [".png", ".jpg", ".jpeg", ".pdf"], required: false,  xs: 12, sm: 6, md: 6, lg: 6, },
				drivers_license_uri: { type: "file", filetypes: [".png", ".jpg", ".jpeg", ".pdf"], required: false,  xs: 12, sm: 6, md: 6, lg: 6, },
				criminal_record_extract_uri: { type: "file", filetypes: [".png", ".jpg", ".jpeg", ".pdf"], required: false,  xs: 12, sm: 6, md: 6, lg: 6, },
			},
		},
		// {
		// 	title: "GENERAL_INFO",
		// 	icon: UnknownIcon,
		// 	fields: {
		//
		// 	},
		// },
		
		// {
		// 	title: "GENERAL_INFO",
		// 	icon: UnknownIcon,
		// 	fields: {
		// 		// text: {type: "text", required: true, },
		// 		// textarea: {type: "text", multiline: true, rows: 12, required: true, },
		// 		// number: {type: "number", required: true, inputProps: {min: 0, max: 99999, step: 1}, },
		// 		// select: {type: "select", required: true, options: ["OPTION"], },
		// 		// checkbox: {type: "checkbox", },
				
		// 		// md12: {type: "text", md: 12, },
		// 		// showOnTable: {type: "text", showOnTable: true, },
		// 	},
		// },

	];

	// PANDORA_ANCHOR_INSERT_OVERRIDE_FUNCTIONS_HERE
	// static getCopyableChildren() { return [EmployeeItem]; };
	static sortFunction(a, b) {
		return ((a.last_name ?? null) != (b.last_name ?? null) ? (a.last_name > b.last_name ? 1 : -1) : ((a.first_name ?? null) != (b.first_name ?? null) ? (a.first_name > b.first_name ? 1 : -1) : (a.id > b.id ? 1 : -1)));
	}

	static getIndexPermissions() {
		return [
			Permissions.ICECLOUD_EMPLOYEES_LIST,

			Permissions.ICECLOCK_AVAILABILITIES_MODERATE,
			Permissions.ICECLOCK_AVAILABILITIES_VIEW,
			Permissions.ICECLOCK_WORKING_DAYS_MODERATE,
			Permissions.ICECLOCK_WORKING_DAYS_VIEW,
			Permissions.ICETRUCK_ICECREAM_VAN_PREFERRED_ZONES_MODERATE,
			Permissions.ICETRUCK_DAILY_SALES_REPORT_VIEW,
			Permissions.ICETRUCK_ICECREAM_VAN_SCHEDULES_VIEW, Permissions.ICETRUCK_ICECREAM_VAN_SCHEDULES_MODERATE,
			Permissions.ICECLOCK_CONTRACTS_VIEW, Permissions.ICECLOCK_CONTRACTS_EDIT_NOT_VALIDATED, Permissions.ICECLOCK_CONTRACTS_MODERATE,
			Permissions.ICESHOP_DELIVERY_SCHEDULES_VIEW, Permissions.ICESHOP_DELIVERY_SCHEDULES_MODERATE,
		];
	}

	static getShowPermissions() {
		return [
			Permissions.ICECLOUD_EMPLOYEES_LIST,
		];
	}

	static getUpdatePermissions() {
		return [
			Permissions.ICECLOUD_EMPLOYEES_EDIT,
		];
	}

	static getStorePermissions() {
		return [
			Permissions.ICECLOUD_EMPLOYEES_ADD,
		];
	}

	static getDestroyPermissions() {
		return [
			Permissions.ICECLOUD_EMPLOYEES_DELETE,
		];
	}
}

export default Employee;