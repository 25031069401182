// This is used to determine if a user is authenticated and
// if they are allowed to visit the page they navigated to.

// If they are: they proceed to the page
// If not: they are redirected to the login page.
import React from 'react'
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom'
import {store} from "index";
import { setAuthEmployee, clearAuth, setRedirectFrom } from "Redux/AuthActions";
import { setLocale, clearOverrideIntl, setOverrideIntl } from "Redux/I18nActions";
import API from 'api';
import { setNavbarNotifications } from "Redux/NotificationsActions";
import { updateNavbarNotifications, legacyLocaleToMoment } from "utils";
import CircularIndeterminate from 'components/CircularIndeterminate/CircularIndeterminate.js';

class PrivateRoute extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			localeImport: null,
		};

		this.handleLoad = this.handleLoad.bind(this);
	}

	async componentDidMount() {
		window.addEventListener("load", this.handleLoad);

		const localeImport = await import("moment/locale/"+legacyLocaleToMoment(this.props.locale));
		this.setState({
			localeImport: localeImport,
		});
	}

	handleLoad() {
		const that = this;

		API.get("auth/employee").then(res => {
			const data = res.data.data;
			store.dispatch(setAuthEmployee(data));
			updateNavbarNotifications(API, store, setNavbarNotifications);

			fetch(process.env.REACT_APP_OVERRIDE_INTL_BASEHREF+(data.company_id)+"-"+(this.props.locale)+".json")
				.then((res) => res.json())
				.then((overrideMessages) => {
					console.log(overrideMessages);
					store.dispatch(setOverrideIntl(overrideMessages));
				})
				.catch((error) => {
					console.error(error);
				}
			);
		}).catch(function (error) {
			console.log(error);
			store.dispatch(clearAuth());
		}).finally(function () {
			// always executed
		});
	}

	render() {
		const { component: Component, authEmployee, ...rest } = this.props;
		const { localeImport } = this.state;

		// Add your own authentication on the below line.
		const isLoggedIn = !(!this.props.authEmployee);

		if (!isLoggedIn) {
			store.dispatch(setRedirectFrom(this.props.location.pathname+this.props.location.search));
		}

		return (
			<Route
				{...rest}
				render={props =>
					isLoggedIn ? (
						localeImport ? <Component {...props} authEmployee={authEmployee} /> : <CircularIndeterminate />
					) : (
						<Redirect push to={{ pathname: '/auth/login-page', state: { from: props.location } }} />
					)
				}
			/>
		)
	}
}

function mapStateToProps(state) {
	return {
			authEmployee: state.auth.authEmployee ?? null,
			locale: state.I18n.locale,
	}
}

export default connect(mapStateToProps)(PrivateRoute);