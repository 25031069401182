import { lazyRetry } from "lazyRetry";
import { lowerCaseFirstLetter, licenseToSourcecodeName } from "utils";

class PandoraModel {
	static getAttributeNames() {
		return this.cards.map(card => Object.keys(card.fields)).flat();
	}

	static getAttributes(structure = null) {
		let obj = (structure ? structure : Object.assign({}, ...this.cards.map(card => card.fields)));
		Object.keys(obj).forEach(attributeName => {
			obj[attributeName].name = attributeName;
		});
		return obj;
	}

	static getAttributesAsArray(structure = null) {
		let obj = (structure ? structure : Object.assign({}, ...this.cards.map(card => card.fields)));
		Object.keys(obj).forEach(attributeName => {
			obj[attributeName].name = attributeName;
		});
		return Object.values(obj);
	}

	static getI18nBase() {
		return "Models/"+this.modelName;
	}

	static getNamePascal() {
		return this.modelName;
	}

	static getNamecamel() {
		return lowerCaseFirstLetter(this.modelName);
	}

	static getName_snake() {
		return this.modelName.replace(/(?:^|\.?)([A-Z])/g, function (x,y){return "_" + y.toLowerCase()}).replace(/^_/, "");
	}

	static getCollectionNamePascal() {
		return this.modelName + "s";
	}

	static getCollectionNamecamel() {
		return lowerCaseFirstLetter(this.modelName) + "s";
	}

	static getCollectionName_snake() {
		return this.modelName.replace(/(?:^|\.?)([A-Z])/g, function (x,y){return "_" + y.toLowerCase()}).replace(/^_/, "") + "s";
	}

	static getNameurl() {
		return this.modelName.toLowerCase();
	}

	static getNameapi() {
		return this.modelName.toLowerCase();
	}

	static getIndexUrl(withLayout = true) {
		if (!(this.hasCrudPages ?? true)) return null;
		return (withLayout ? '/admin/' : '/') + this.getLicense().toLowerCase() + '/' + this.getNameurl() + '/';
	}

	static getShowUrl(id, withLayout = true) {
		if (!(this.hasCrudPages ?? true) || (this.crudAsNotification ?? false)) return null;
		return (withLayout ? '/admin/' : '/') + this.getLicense().toLowerCase() + '/' + this.getNameurl() + '/view/' + id + '/';
	}

	static getUpdateUrl(id, withLayout = true) {
		if (!(this.hasCrudPages ?? true) || (this.crudAsNotification ?? false)) return null;
		return (withLayout ? '/admin/' : '/') + this.getLicense().toLowerCase() + '/' + this.getNameurl() + '/edit/' + id + '/';
	}

	static getStoreUrl(withLayout = true) {
		if (!(this.hasCrudPages ?? true) || (this.crudAsNotification ?? false)) return null;
		return (withLayout ? '/admin/' : '/') + this.getLicense().toLowerCase() + '/' + this.getNameurl() + '/create/';
	}

	static getPermissions() {
		return [
			...this.getIndexPermissions(),
			...this.getShowPermissions(),
			...this.getUpdatePermissions(),
			...this.getStorePermissions(),
		].filter(permission => permission !== null).filter(function(item, pos, self) {
			return self.indexOf(item) == pos;
		});
	}

	static getIndexPermissions() {
		if (this.getPermissionsFromParent ?? null) return this.getPermissionsFromParent().getIndexPermissions();
		return [
			this.getLicense() + '_' + ((this.permissionNamesAreSingular ?? false) ? this.getName_snake() : this.getCollectionName_snake()).toUpperCase() + '_' + 'VIEW',
			this.getLicense() + '_' + ((this.permissionNamesAreSingular ?? false) ? this.getName_snake() : this.getCollectionName_snake()).toUpperCase() + '_' + 'MODERATE',
		];
	}

	static getShowPermissions() {
		if (this.getPermissionsFromParent ?? null) return this.getPermissionsFromParent().getShowPermissions();
		return [
			this.getLicense() + '_' + ((this.permissionNamesAreSingular ?? false) ? this.getName_snake() : this.getCollectionName_snake()).toUpperCase() + '_' + 'VIEW',
			this.getLicense() + '_' + ((this.permissionNamesAreSingular ?? false) ? this.getName_snake() : this.getCollectionName_snake()).toUpperCase() + '_' + 'MODERATE',
		];
	}

	static getUpdatePermissions() {
		if (this.getPermissionsFromParent ?? null) return this.getPermissionsFromParent().getUpdatePermissions();
		return [
			this.getLicense() + '_' + ((this.permissionNamesAreSingular ?? false) ? this.getName_snake() : this.getCollectionName_snake()).toUpperCase() + '_' + 'MODERATE',
		];
	}

	static getStorePermissions() {
		if (this.getPermissionsFromParent ?? null) return this.getPermissionsFromParent().getStorePermissions();
		return [
			this.getLicense() + '_' + ((this.permissionNamesAreSingular ?? false) ? this.getName_snake() : this.getCollectionName_snake()).toUpperCase() + '_' + 'MODERATE',
		];
	}

	static getDestroyPermissions() {
		if (this.getPermissionsFromParent ?? null) return this.getPermissionsFromParent().getDestroyPermissions();
		return [
			this.getLicense() + '_' + ((this.permissionNamesAreSingular ?? false) ? this.getName_snake() : this.getCollectionName_snake()).toUpperCase() + '_' + 'MODERATE',
		];
	}

	static getViews() {
		return [
			this.getStoreView(),
			this.getUpdateView(),
			this.getShowView(),
			this.getIndexView(),
			...(this.customViews ?? []),
		].filter(view => view !== null);
	}

	static getIndexView() {
		if (!(this.hasCrudPages ?? true)) return null;
		return {
			path: this.getIndexUrl(false),
			name: this.getLicense()+"."+this.getCollectionName_snake().toUpperCase()+".INDEX",
			icon: this.icon,
			fa_icon: this.fa_icon,
			component: lazyRetry(() => import("pandoraviews/"+licenseToSourcecodeName(this.getLicense())+"/"+this.getCollectionNamePascal()+"/"+"Index"+this.getCollectionNamePascal())),
			layout: "/admin",
			hidden: this.hideIndexRoute ?? false,
			license: this.getLicense(),
			permissions: this.getIndexPermissions(),
		};
	}

	static getShowView() {
		if (!(this.hasCrudPages ?? true) || (this.crudAsNotification ?? false)) return null;
		return {
			path: this.getShowUrl(":id", false),
			matchPath: this.getShowUrl("", false).replace(/\/+$/, ""),
			name: this.getLicense()+"."+this.getCollectionName_snake().toUpperCase()+".SHOW",
			icon: this.icon,
			fa_icon: this.fa_icon,
			component: lazyRetry(() => import("pandoraviews/"+licenseToSourcecodeName(this.getLicense())+"/"+this.getCollectionNamePascal()+"/"+"Update"+this.getNamePascal())),
			layout: "/admin",
			hidden: true,
			license: this.getLicense(),
			permissions: this.getShowPermissions(),
		};
	}

	static getUpdateView() {
		if (!(this.hasCrudPages ?? true) || (this.crudAsNotification ?? false)) return null;
		return {
			path: this.getUpdateUrl(":id", false),
			matchPath: this.getUpdateUrl("", false).replace(/\/+$/, ""),
			name: this.getLicense()+"."+this.getCollectionName_snake().toUpperCase()+".UPDATE",
			icon: this.icon,
			fa_icon: this.fa_icon,
			component: lazyRetry(() => import("pandoraviews/"+licenseToSourcecodeName(this.getLicense())+"/"+this.getCollectionNamePascal()+"/"+"Update"+this.getNamePascal())),
			layout: "/admin",
			hidden: true,
			license: this.getLicense(),
			permissions: this.getUpdatePermissions(),
		};
	}

	static getStoreView() {
		if (!(this.hasCrudPages ?? true) || (this.crudAsNotification ?? false)) return null;
		return {
			path: this.getStoreUrl(false),
			name: this.getLicense()+"."+this.getCollectionName_snake().toUpperCase()+".STORE",
			icon: this.icon,
			fa_icon: this.fa_icon,
			component: lazyRetry(() => import("pandoraviews/"+licenseToSourcecodeName(this.getLicense())+"/"+this.getCollectionNamePascal()+"/"+"Store"+this.getNamePascal())),
			layout: "/admin",
			hidden: true,
			license: this.getLicense(),
			permissions: this.getStorePermissions(),
		};
	}

	static getTableColumns(pandoraTableState, pandoraTableProps) {
		return this.getAttributesAsArray().filter(attribute => attribute.hasOwnProperty('showOnTable') && (attribute.showOnTable === true || ((typeof attribute.showOnTable == "function") && attribute.showOnTable(pandoraTableState, pandoraTableProps)))).map(attribute => ({
			name: attribute.name,
			sortable: attribute.sortable ?? false,
		}));
	}

	static getTableClasses(pandoraTableState, pandoraTableProps) {
		return this.getAttributesAsArray().filter(attribute => attribute.hasOwnProperty('showOnTable') && (attribute.showOnTable === true || ((typeof attribute.showOnTable == "function") && attribute.showOnTable(pandoraTableState, pandoraTableProps)))).map((attribute, index) => (
			attribute.tableClasses ? attribute.tableClasses : (attribute.type == "checkbox" ? "shrink" : "")
		));
	}

	static getTableClassesIndices(pandoraTableState, pandoraTableProps) {
		return this.getAttributesAsArray().filter(attribute => attribute.hasOwnProperty('showOnTable') && (attribute.showOnTable === true || ((typeof attribute.showOnTable == "function") && attribute.showOnTable(pandoraTableState, pandoraTableProps)))).map((attribute, index) => (
			index + ((this.orderable ? this.orderable : false) ? 1 : 0)
		));
	}

	static sortFunction(a, b) {
		return ((a.sort_key ?? null) != (b.sort_key ?? null) ? (a.sort_key > b.sort_key ? 1 : -1) : ((a.name ?? null) != (b.name ?? null) ? (a.name > b.name ? 1 : -1) : (a.id > b.id ? 1 : -1)));
	}
}

export default PandoraModel;