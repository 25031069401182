import React from 'react';
import PandoraModel from "traits/PandoraModel";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import ColorLensIcon from '@material-ui/icons/ColorLens';
import * as License from "license.js";
import * as Permissions from "permission.js";
import VanButton from 'components/VanButton/VanButton';
import LocaleFromNow from 'components/LocaleFromNow/LocaleFromNow';

class DeliveryVan extends PandoraModel {
	static modelName = "DeliveryVan";
	static getLicense() { return License.ICESHOP}

	static icon = LocalShippingIcon;

	// static orderable = true;
	// static copyable = true;
	// static loadBeforeCopy = false;
	// static hasCrudPages = false;
	// static crudAsNotification = true;
	// static editableOnTable = true;

	// PANDORA_ANCHOR_INSERT_OVERRIDE_VARIABLES_HERE

	static cards = [
		{
			title: "GENERAL_INFO",
			icon: LocalShippingIcon,
			fields: {
				// PANDORA_ANCHOR_INSERT_FIELDS_HERE
				nr: { type: "number", step: "1", required: true, showOnTable: true, md: 4, },
				name: { type: "text", maxLength: "255", required: true, showOnTable: true, md: 4, valueOnTable: (resource) => <VanButton name={resource.name} color_background={resource.color_background} color_text={resource.color_text} />, },
				license_plate: { type: "text", maxLength: "255", required: false, md: 4, showOnTable: true, },
				subtitle: { type: "text", maxLength: "255", required: false, },
				internal_note: { type: "text", maxLength: "255", required: false, },
				damage_report_background_image_uri: { type: "file", filetypes: [".png", ".jpg", ".jpeg"], required: false, },

				latitude: { type: "number", step: "0.01", required: false, visible: false, },
				longitude: { type: "number", step: "0.01", required: false, visible: false, },
				velocity: { type: "number", step: "0.01", required: false, visible: false, },

				updated_at: { type: "datetime", required: false, visible: false, showOnTable: true, valueOnTable: (resource) => <LocaleFromNow value={resource.updated_at} />, },
			},
		},
		{
			title: "COLORS",
			icon: ColorLensIcon,
			fields: {
				color_text: { type: "color", palette: "text", required: true, md: 6, },
				color_background: { type: "color", palette: "background", required: true, md: 6, },
			},
		},
		
		// {
		// 	title: "GENERAL_INFO",
		// 	icon: FiberNewIcon,
		// 	fields: {
		//
		// 	},
		// },
		
		// {
		// 	title: "GENERAL_INFO",
		// 	icon: FiberNewIcon,
		// 	fields: {
		// 		// text: {type: "text", required: true, },
		// 		// textarea: {type: "text", multiline: true, rows: 12, required: true, },
		// 		// number: {type: "number", required: true, inputProps: {min: 0, max: 99999, step: 1}, },
		// 		// select: {type: "select", required: true, options: ["OPTION"], },
		// 		// checkbox: {type: "checkbox", },
				
		// 		// md12: {type: "text", md: 12, },
		// 		// showOnTable: {type: "text", showOnTable: true, },
		// 	},
		// },

	];

	// PANDORA_ANCHOR_INSERT_OVERRIDE_FUNCTIONS_HERE
	// static getCopyableChildren() { return [DeliveryVanItem]; };
}

export default DeliveryVan;