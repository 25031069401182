import React, {Component} from "react"
import { connect } from 'react-redux';

class LocaleFromNow extends Component {	
	render() {
		return (
			<span>{this.props.value.lang(this.props.locale).fromNow()}</span>
		)
	}
}

function mapStateToProps(state) {
	return {
		locale: state.I18n.locale,
	}
}

export default connect(mapStateToProps)(LocaleFromNow)