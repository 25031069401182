import { lazyRetry } from "lazyRetry";

import * as Permissions from "permission.js";
import * as License from "license.js";

const Setup = lazyRetry(() => import("views/Screen/Setup/Setup.js"));
const Metrics = lazyRetry(() => import("views/Screen/Metrics/Metrics.js"));
const Map = lazyRetry(() => import("views/Screen/Map/Map.js"));

var dashRoutes = [
	{
		path: "/setup",
		name: "SETUP",
		component: Setup,
		layout: "/screen"
	},
	{
		path: "/metrics",
		name: "METICS",
		component: Metrics,
		layout: "/screen"
	},
	{
		path: "/map",
		name: "MAP",
		component: Map,
		layout: "/screen",
		permissions: [
			Permissions.ICETRUCK_MAP_VIEW,
		],
	},
];
export default dashRoutes;
