import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import StoreIcon from '@material-ui/icons/Store';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

const styles = {
	deliverableSpan: {
		backgroundColor: "black",
		color: "white",
		padding: "5px",
		marginBottom: "5px",
		marginRight: "5px",
		whiteSpace: "nowrap",

		"& svg": {
			marginBottom: "-5px",
		}
	},
};

class DeliverableIcon extends React.Component {
	render() {
		const { classes, type } = this.props;

		return (
			<>
				<span className={classes.deliverableSpan}>
					{type == "PICK_UP_POINT" ? <StoreIcon /> : null}
					{type == "DELIVERY_ZONE" ? <LocalShippingIcon /> : null}
					{type == "SHIPMENT_ZONE" ? <i className={"fas fa-box-open"} /> : null}
					{type == "DIGITAL_DELIVERY_TYPE" ? <MailOutlineIcon /> : null}
					&nbsp;
					{this.props.resource.abbreviation}
				</span>
				&nbsp;
				{this.props.resource.name}
			</>
		);
	}
}

export default withStyles((theme) => ({ ...styles }), { withTheme: true })(DeliverableIcon);