import * as actionType from './AuthActionType';

export function setAuthEmployee(authEmployee){
		return {
				type: actionType.SET_AUTH_EMPLOYEE,
				payload: authEmployee,
		}
};

export function setAuthScreen(authScreen){
	return {
			type: actionType.SET_AUTH_SCREEN,
			payload: authScreen,
	}
};

export function setLastValidCall(lastValidCall){
	return {
			type: actionType.SET_LAST_VALID_CALL,
			payload: {
				lvc: lastValidCall,
			}
	}
};

export function setToken(token, exp, expires_in = 3600){
		return {
				type: actionType.SET_TOKEN,
				payload: {
					token: token,
					exp: exp,
					expires_in: expires_in,
				}
		}
};

export function clearAuth(){
		return {
				type: actionType.CLEAR_AUTH,
				payload: null,
		}
};

export function setRedirectFrom(redirectFrom){
	return {
			type: actionType.SET_REDIRECT_FROM,
			payload: redirectFrom,
	}
};