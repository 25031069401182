import React from 'react';
import PandoraModel from "traits/PandoraModel";
import PeopleIcon from '@material-ui/icons/People';
import ImageIcon from '@material-ui/icons/Image';
import InfoIcon from '@material-ui/icons/Info';
import DeleteIcon from '@material-ui/icons/Delete';
import * as License from "license.js";
import * as Permissions from "permission.js";

class ExEmployee extends PandoraModel {
	static modelName = "ExEmployee";
	static getLicense() { return License.ICECLOUD}

	static icon = DeleteIcon;

	// static orderable = true;
	// static copyable = true;
	// static loadBeforeCopy = false;
	// static hasCrudPages = false;
	static crudAsNotification = true;
	// static editableOnTable = true;
	static tableAvatar = "profile_picture_uri";
	static hideIndexRoute = true;

	// PANDORA_ANCHOR_INSERT_OVERRIDE_VARIABLES_HERE

	static cards = [
		{
			title: "PROFILE_PICTURE",
			icon: ImageIcon,
			md: 3,
			fields: {
				profile_picture_uri: { type: "file", filetypes: [".png", ".jpg", ".jpeg"], required: false, showPreview: true, },
			},
		},
		{
			title: "NAME",
			icon: PeopleIcon,
			md: 9,
			fields: {
				name: { type: "text", show: false, showOnTable: true, value: (resource) => resource.last_name.toUpperCase() + (resource.middle_name ? (" " + resource.middle_name) : "") + " " + resource.first_name, },
				last_name: { type: "text", maxLength: "255", required: true, xs: 12, sm: 6, md: 6, lg: 4, },
				middle_name: { type: "text", maxLength: "255", required: false, xs: 12, sm: 6, md: 6, lg: 4, },
				first_name: { type: "text", maxLength: "255", required: true, xs: 12, sm: 6, md: 6, lg: 4, },
			},
		},
		{
			title: "GENERAL_INFO",
			icon: InfoIcon,
			fields: {
				user: { type: "hidden", show: false, formdataValue: (resource) => ({"email": resource.email}), }, 
				national_registry_nr: { type: "text", maxLength: "255", required: true, xs: 12, sm: 12, md: 12, lg: 5, },
				birthday: { type: "date", required: true, xs: 12, sm: 6, md: 6, lg: 5, },
				sex: { type: "select", options: ["male", "female"], required: true, xs: 12, sm: 6, md: 6, lg: 2, },
				email: { type: "text", subType: "email", showOnTable: true, defaultOnUpdate: (resource) => resource.user.email, valueOnTable: (resource) => resource.user.email, md: 6, }, 
				cellphone_nr: { type: "text", maxLength: "255", required: true, showOnTable: true, md: 6, },
				birthplace: { type: "text", maxLength: "255", required: true, md: 6, },
				nationality: { type: "text", maxLength: "255", required: true, md: 6, },
			},
		},
		// {
		// 	title: "GENERAL_INFO",
		// 	icon: FiberNewIcon,
		// 	fields: {
		//
		// 	},
		// },
		
		// {
		// 	title: "GENERAL_INFO",
		// 	icon: FiberNewIcon,
		// 	fields: {
		// 		// text: {type: "text", required: true, },
		// 		// textarea: {type: "text", multiline: true, rows: 12, required: true, },
		// 		// number: {type: "number", required: true, inputProps: {min: 0, max: 99999, step: 1}, },
		// 		// select: {type: "select", required: true, options: ["OPTION"], },
		// 		// checkbox: {type: "checkbox", },
				
		// 		// md12: {type: "text", md: 12, },
		// 		// showOnTable: {type: "text", showOnTable: true, },
		// 	},
		// },

	];

	// PANDORA_ANCHOR_INSERT_OVERRIDE_FUNCTIONS_HERE
	// static getCopyableChildren() { return [ExEmployeeItem]; };
	static getNameapi() {
		return "softdelete/employee";
	}

	static sortFunction(a, b) {
		return ((a.last_name ?? null) != (b.last_name ?? null) ? (a.last_name > b.last_name ? 1 : -1) : ((a.first_name ?? null) != (b.first_name ?? null) ? (a.first_name > b.first_name ? 1 : -1) : (a.id > b.id ? 1 : -1)));
	}

	static getIndexPermissions() {
		return [
			Permissions.ICECLOUD_EMPLOYEES_RESTORE_LIST,
		];
	}

	static getShowPermissions() {
		return [
			Permissions.ICECLOUD_EMPLOYEES_RESTORE_LIST,
		];
	}

	static getUpdatePermissions() {
		return [
			Permissions.ICECLOUD_EMPLOYEES_RESTORE,
		];
	}

	static getStorePermissions() {
		return [
			null,
		];
	}

	static getDestroyPermissions() {
		return [
			null,
		];
	}
}

export default ExEmployee;