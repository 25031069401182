import React, { Suspense } from "react";
import cx from "classnames";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { hasPermission } from "utils.js";
import { store } from "index";
import { setBlipHeightFeelingDiv } from "Redux/LayersActions";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";
import CircularIndeterminate from 'components/CircularIndeterminate/CircularIndeterminate.js';

import fixedRoutes from "routes.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";

import { lazyRetry } from "lazyRetry";

const CustomPage = lazyRetry(() => import("views/Custom/CustomPage"));

var ps;

const useStyles = makeStyles(styles);

export default function Admin(props) {
	const { authEmployee, ...rest } = props;
	const applicableCustomFrontendables = (authEmployee?.company?.custom_frontendables ?? []).filter(route => route.platform == "ICECLOUD");
	const routes = fixedRoutes.concat(applicableCustomFrontendables);
	// states and functions
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const [miniActive, setMiniActive] = React.useState(false);
	const [image, setImage] = React.useState(require("assets/img/sidebar-2.jpg"));
	const [color, setColor] = React.useState("blue");
	const [bgColor, setBgColor] = React.useState("black");
	// const [hasImage, setHasImage] = React.useState(true);
	const [fixedClasses, setFixedClasses] = React.useState("dropdown");
	const [logo, setLogo] = React.useState(require("assets/img/logo-white.svg"));
	// styles
	const classes = useStyles();
	const mainPanelClasses =
		classes.mainPanel +
		" " +
		cx({
			[classes.mainPanelSidebarMini]: miniActive,
			// [classes.mainPanelWithPerfectScrollbar]:
			// 	navigator.platform.indexOf("Win") > -1
		});
	// ref for main panel div
	const mainPanel = React.createRef();
	// effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
	React.useEffect(() => {
		if (navigator.platform.indexOf("Win") > -1) {
			// ps = new PerfectScrollbar(mainPanel.current, {
			// 	suppressScrollX: true,
			// 	suppressScrollY: false
			// });
			// document.body.style.overflow = "hidden";
		}
		window.addEventListener("resize", resizeFunction);

		// Specify how to clean up after this effect:
		return function cleanup() {
			if (navigator.platform.indexOf("Win") > -1) {
				// ps.destroy();
			}
			window.removeEventListener("resize", resizeFunction);
		};
	});
	// functions for changeing the states from components
	const handleImageClick = image => {
		setImage(image);
	};
	const handleColorClick = color => {
		setColor(color);
	};
	const handleBgColorClick = bgColor => {
		switch (bgColor) {
			case "white":
				setLogo(require("assets/img/logo.svg"));
				break;
			default:
				setLogo(require("assets/img/logo-white.svg"));
				break;
		}
		setBgColor(bgColor);
	};
	const handleFixedClick = () => {
		if (fixedClasses === "dropdown") {
			setFixedClasses("dropdown show");
		} else {
			setFixedClasses("dropdown");
		}
	};
	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};
	const isFullscreenRoute = () => {
		return (
			window.location.pathname == "/admin/full-screen-maps" ||
			window.location.pathname == "/admin/icetruck/map" ||
			window.location.pathname == "/admin/legacy/intranet" ||
			window.location.pathname.indexOf("/admin/custom") >= 0
		);
	};
	const getActiveRoute = routes => {
		let activeRoute = "Default Brand Text";
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse || routes[i].mdl == "App\\CustomFrontendPageGroup") {
				let collapseActiveRoute = getActiveRoute(routes[i].views ?? routes[i].custom_frontendables);
				if (collapseActiveRoute !== activeRoute) {
					return collapseActiveRoute;
				}
			} else {
				const index = window.location.href.indexOf((routes[i].layout ?? "/admin") + (routes[i].matchPath ?? routes[i].path ?? "/custom/"+encodeURIComponent(routes[i].name)));
				const nextChar = index > -1 ? window.location.href.charAt(index + ((routes[i].layout ?? "/admin") + (routes[i].matchPath ?? routes[i].path ?? "/custom/"+encodeURIComponent(routes[i].name))).length) : null;
				if (
					index > -1 && (nextChar == "" || nextChar == "/" || nextChar == "\\" || nextChar == "?" || nextChar == "#")
				) {
					return routes[i].name;
				}
			}
		}
		return activeRoute;
	};
	const getRoutes = routes => {
		return routes.filter(function(route) {
			return (typeof route.permissions == 'undefined' || route.permissions == null || route.permissions == "" || route.permissions.length == 0 || hasPermission(authEmployee, route.permissions));
		}).map((prop, key) => {
			if (prop.collapse || prop.mdl == "App\\CustomFrontendPageGroup") {
				return getRoutes(prop.views ?? prop.custom_frontendables);
			}
			if (prop.mdl == "App\\CustomFrontendPage") {
				return (
					<Route
						path={(prop.layout ?? "/admin") + (prop.path ?? "/custom/"+prop.name)}
						render={(props) => <CustomPage {...props} url={prop.url} allow={prop.allow ?? ""} />}
						key={key}
					/>
				);
			} else if (prop.layout === "/admin") {
				return (
					<Route
						path={prop.layout + prop.path}
						component={prop.component}
						key={key}
					/>
				);
			} else {
				return null;
			}
		});
	};
	const sidebarMinimize = () => {
		setMiniActive(!miniActive);

		store.dispatch(setBlipHeightFeelingDiv(true));
		setTimeout(() => {store.dispatch(setBlipHeightFeelingDiv(false));}, 100);
	};
	const resizeFunction = () => {
		if (window.innerWidth >= 960) {
			setMobileOpen(false);
		}
	};

	return (
		<div id="overrideheightforprint" className={classes.wrapper}>
			<style type="text/css">
			{`@media print {
					#overrideheightforprint {height: 100%}
			}`}
			</style>
			<style>
				{/* {"@media print { .no-print, .no-print * { display: none !important; } }"} */}
				{".print-this-element { display: none; }"}
				{"@media print { .print-this-element { display: initial; } }"}
				{".only-print { display: none; }"}
				{"@media print { .only-print { display: initial; } }"}
				{"@media print { .no-print { display: none; } }"}
				{"@media print { .print-no-margin { margin: 0 !important; } }"}
				{"@media print { .print-no-top-padding { padding-top: 0 !important; } }"}
				{"@media print { .print-no-bottom-padding { padding-bottom: 0 !important; } }"}
				{"@media print { .print-auto-height tr { height: auto !important; } }"}
				{"@media print { .print-avoid-break { break-inside: avoid; } }"}
			</style>
			<Sidebar
				routes={routes}
				logoText={"ICEcloud"}
				logo={logo}
				image={image}
				handleDrawerToggle={handleDrawerToggle}
				open={mobileOpen}
				color={color}
				bgColor={bgColor}
				miniActive={miniActive}
				{...rest}
			/>
			<div className={mainPanelClasses} ref={mainPanel} id="scrollanchor">
				<Suspense fallback={<CircularIndeterminate />}>
					<AdminNavbar
						sidebarMinimize={sidebarMinimize.bind(this)}
						miniActive={miniActive}
						brandText={getActiveRoute(routes)}
						handleDrawerToggle={handleDrawerToggle}
						{...rest}
					/>
					{/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
					{!isFullscreenRoute() ? (
						<div className={classes.content}>
							<div className={classes.container}>
								<Switch>
									{getRoutes(routes)}
									<Redirect from="/admin" to="/admin/dashboard" />
								</Switch>
							</div>
						</div>
					) : (
						<div className={classes.map+" "+classes.mainPanelWithPerfectScrollbar}>
							<Switch>
								{getRoutes(routes)}
								<Redirect from="/admin" to="/admin/dashboard" />
							</Switch>
						</div>
					)}
					{isFullscreenRoute() ? null : <Footer fluid />}
					{/* <FixedPlugin
						handleImageClick={handleImageClick}
						handleColorClick={handleColorClick}
						handleBgColorClick={handleBgColorClick}
						color={color}
						bgColor={bgColor}
						bgImage={image}
						handleFixedClick={handleFixedClick}
						fixedClasses={fixedClasses}
						sidebarMinimize={sidebarMinimize.bind(this)}
						miniActive={miniActive}
					/> */}
				</Suspense>
			</div>
		</div>
	);
}
