"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _createSvgIcon = _interopRequireDefault(require("@material-ui/icons/utils/createSvgIcon"));

var _default = (0, _createSvgIcon.default)(_react.default.createElement(_react.default.Fragment, null, _react.default.createElement("path", {
  d: "M 22.92 16.367 L 22.345 16.367 L 22.345 12.188 C 22.345 11.505 22.067 10.838 21.586 10.357 L 18.566 7.337 C 18.083 6.854 17.414 6.578 16.734 6.578 L 15.435 6.578 L 15.435 5.137 C 15.435 4.025 14.531 3.122 13.419 3.122 L 2.477 3.122 C 1.365 3.122 0.462 4.025 0.462 5.137 L 0.462 16.079 C 0.462 17.191 1.365 18.095 2.477 18.095 L 2.766 18.095 C 2.766 20.002 4.313 21.549 6.22 21.549 C 8.128 21.549 9.676 20.002 9.676 18.095 L 14.283 18.095 C 14.283 20.002 15.831 21.549 17.738 21.549 C 19.646 21.549 21.193 20.002 21.193 18.095 L 22.92 18.095 C 23.238 18.095 23.497 17.835 23.497 17.518 L 23.497 16.943 C 23.497 16.627 23.238 16.367 22.92 16.367 Z M 6.22 19.822 C 5.267 19.822 4.493 19.049 4.493 18.095 C 4.493 17.141 5.267 16.367 6.22 16.367 C 7.174 16.367 7.949 17.141 7.949 18.095 C 7.949 19.049 7.174 19.822 6.22 19.822 Z M 13.707 16.367 L 9.197 16.367 C 8.6 15.338 7.498 14.639 6.22 14.639 C 4.942 14.639 3.842 15.338 3.244 16.367 L 2.477 16.367 C 2.319 16.367 2.189 16.237 2.189 16.079 L 2.189 5.137 C 2.189 4.979 2.319 4.849 2.477 4.849 L 13.419 4.849 C 13.577 4.849 13.707 4.979 13.707 5.137 L 13.707 16.367 Z M 15.435 8.305 L 16.734 8.305 C 16.96 8.305 17.184 8.397 17.346 8.556 L 19.974 11.184 L 15.435 11.184 L 15.435 8.305 Z M 17.738 19.822 C 16.785 19.822 16.01 19.049 16.01 18.095 C 16.01 17.141 16.785 16.367 17.738 16.367 C 18.692 16.367 19.466 17.141 19.466 18.095 C 19.466 19.049 18.692 19.822 17.738 19.822 Z M 20.617 16.191 C 19.998 15.259 18.941 14.639 17.738 14.639 C 16.848 14.639 16.046 14.985 15.435 15.536 L 15.435 12.912 L 20.617 12.912 L 20.617 16.191 Z"
}), _react.default.createElement("path", {
  d: "M 10.25 8.377 L 10.236 8.377 C 10.442 6.576 8.623 5.227 6.959 5.948 C 6.007 6.36 5.439 7.348 5.557 8.377 L 5.542 8.377 C 4.938 8.377 4.56 9.031 4.862 9.554 C 5.002 9.797 5.261 9.946 5.542 9.946 L 10.25 9.946 C 10.854 9.946 11.233 9.292 10.93 8.769 C 10.79 8.526 10.532 8.377 10.25 8.377 Z M 7.428 13.833 C 7.594 14.192 8.088 14.238 8.317 13.914 C 8.335 13.889 8.35 13.862 8.363 13.833 L 9.989 10.47 L 5.803 10.47 Z",
})), 'IcecreamVan');

exports.default = _default;