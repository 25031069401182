import * as actionType from './NotificationsActionType';

const initialState = {
    navbar: [],
}

const NotificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.SET_NAVBAR_NOTIFICATIONS:
      return {...state, navbar: action.payload};
    default:
      return state
  }
}

export default NotificationsReducer;