import React from 'react';
import PandoraModel from "traits/PandoraModel";
import NoteIcon from '@material-ui/icons/Note';
import * as License from "license.js";
import * as Permissions from "permission.js";
import moment from "moment";

class EmployeeNote extends PandoraModel {
	static modelName = "EmployeeNote";
	static getLicense() { return License.ICECLOUD}

	static icon = NoteIcon;

	// static orderable = true;
	// static copyable = true;
	// static loadBeforeCopy = false;
	static hasCrudPages = false;
	static crudAsNotification = true;
	// static editableOnTable = true;

	// PANDORA_ANCHOR_INSERT_OVERRIDE_VARIABLES_HERE

	static cards = [
		{
			title: "GENERAL_INFO",
			icon: NoteIcon,
			fields: {
				// PANDORA_ANCHOR_INSERT_FIELDS_HERE
				subject_employee_id: { type: "hidden", },
				author_employee_id: { type: "hidden", },
				author_employee: { type: "hidden", },
				content: { type: "text", multiline: true, rows: 12, required: true, showOnTable: true, valueOnTable: (resource) => <><b>{moment.utc(resource.created_at).local().format("DD MMM YYYY")} &#8209; {resource.author_employee.last_name.toUpperCase()} {resource.author_employee.first_name}</b><br /><span>{resource.content}</span></>, },
				visible_to_subject: { type: "checkbox", required: false, md: 6, },
				send_email: { type: "checkbox", required: false, visible: "visible_to_subject", show: false, showOnStore: true, showOnCopy: true, md: 6, },
			},
		},
		// {
		// 	title: "GENERAL_INFO",
		// 	icon: FiberNewIcon,
		// 	fields: {
		//
		// 	},
		// },
		
		// {
		// 	title: "GENERAL_INFO",
		// 	icon: FiberNewIcon,
		// 	fields: {
		// 		// text: {type: "text", required: true, },
		// 		// textarea: {type: "text", multiline: true, rows: 12, required: true, },
		// 		// number: {type: "number", required: true, inputProps: {min: 0, max: 99999, step: 1}, },
		// 		// select: {type: "select", required: true, options: ["OPTION"], },
		// 		// checkbox: {type: "checkbox", },
				
		// 		// md12: {type: "text", md: 12, },
		// 		// showOnTable: {type: "text", showOnTable: true, },
		// 	},
		// },

	];

	// PANDORA_ANCHOR_INSERT_OVERRIDE_FUNCTIONS_HERE
	// static getCopyableChildren() { return [EmployeeNoteItem]; };
	static getIndexPermissions() {
		return [
			Permissions.ICECLOUD_EMPLOYEE_NOTES_VIEW,
		];
	}

	static getShowPermissions() {
		return [
			Permissions.ICECLOUD_EMPLOYEE_NOTES_VIEW,
		];
	}

	static getUpdatePermissions() {
		return [
			null,
		];
	}

	static getStorePermissions() {
		return [
			Permissions.ICECLOUD_EMPLOYEE_NOTES_ADD,
		];
	}

	static getDestroyPermissions() {
		return [
			Permissions.ICECLOUD_EMPLOYEE_NOTES_DELETE,
		];
	}
}

export default EmployeeNote;