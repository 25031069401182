import React from "react";
import { connect } from 'react-redux';
import { IntlProvider } from "react-intl";
import CircularIndeterminate from 'components/CircularIndeterminate/CircularIndeterminate.js';

class I18nModule extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			messages: null,
		};
	}

	componentDidMount() {
		const {overrideIntl} = this.props;

		if (this.props.base) {
			if (Array.isArray(this.props.base)) {
				this.loadArrayBases(this.props.base);
			} else {
				const base = (this.props.base ? ((typeof this.props.base === 'string' || this.props.base instanceof String) ? this.props.base : this.props.base.getI18nBase()) : null);
				const localeImport = import("./messages/"+this.props.locale+"/"+base+".json").then((messages) => {
					const legacyLocaleImport = import("./messages/"+this.props.locale).then((legacyMessages) => {
						this.setState({
							messages: {
								...messages,
								...legacyMessages,
								...overrideIntl[base ?? "legacy"],
							},
						});
					});
				});
			}
		} else {
			const base = (this.props.base ? ((typeof this.props.base === 'string' || this.props.base instanceof String) ? this.props.base : this.props.base.getI18nBase()) : null);
			const legacyLocaleImport = import("./messages/"+this.props.locale).then((legacyMessages) => {
				this.setState({
					messages: {
						...legacyMessages,
						...overrideIntl[base ?? "legacy"],
					},
				});
			});
		}
	}

	async loadArrayBases(bases) {
		const {overrideIntl} = this.props;

		let messages = {};
		let overrideMessages = {};
		await Promise.all(bases.map(async base => {
			base = (base ? ((typeof base === 'string' || base instanceof String) ? base : base.getI18nBase()) : null);
			const localeImport = await import("./messages/"+this.props.locale+"/"+base+".json");
			Object.assign(messages, localeImport);
			Object.assign(overrideMessages, overrideIntl[base ?? "legacy"]);
		}));
		const legacyLocaleImport = import("./messages/"+this.props.locale).then((legacyMessages) => {
			this.setState({
				messages: {
					...messages,
					...legacyMessages,
					...overrideMessages,
				},
			});
		});
	}

	render() {
		const { children } = this.props;
		const { messages } = this.state;
		const locale = this.props.locale;

		return (
			messages ?
				<IntlProvider locale={locale} messages={messages}>
					{children}
				</IntlProvider>
			:
				<CircularIndeterminate />
		);
	}
}

function mapStateToProps(state) {
	return {
		locale: state.I18n.locale,
		overrideIntl: state.I18n.overrideIntl ?? {},
	}
}

export default connect(mapStateToProps)(I18nModule);