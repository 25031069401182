import React from "react";
import I18nModule from "./I18nModule";

// This function takes a component...
export const injectIntlModule = (base, WrappedComponent) => {
	// ...and returns another component...
	return class extends React.Component {
		constructor(props) {
			super(props);
		}

		render() {
			// ... and renders the wrapped component with the fresh data!
			// Notice that we pass through any additional props
			return <I18nModule base={base}><WrappedComponent {...this.props} /></I18nModule>;
		}
	};
}