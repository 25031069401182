import React, {Component} from "react"
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from "react-intl";
import { Redirect, Link } from "react-router-dom";
import {store} from "index";
import { setToken, setAuthEmployee, setRedirectFrom } from "Redux/AuthActions";
import { setLocale, clearOverrideIntl, setOverrideIntl } from "Redux/I18nActions";
import API from 'api';
import { setNavbarNotifications } from "Redux/NotificationsActions";
import { updateNavbarNotifications, legacyLocaleToMoment, parseJwt } from "utils";

// @material-ui/core components
import { withStyles } from '@material-ui/core/styles';
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CircularIndeterminate from 'components/CircularIndeterminate/CircularIndeterminate.js';
import SnackbarContent from "components/Snackbar/SnackbarContent.js";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

const i18nBase = "PAGES.LOGIN_PAGE.";
class LoginPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			cardAnimaton: "cardHidden",
			busyLoading: false,
			email: (process.env.REACT_APP_DEFAULT_EMAIL ?? ""),
			password: (process.env.REACT_APP_DEFAULT_PASSWORD ?? ""),
			errorMessage: null,

			redirectToDashboard: false,
			redirectToResetPassword: false,
		};

		this.trustedDeviceKey = (localStorage.getItem("trusted_device_key") ?? null);

		this.handleChange =this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.getAuthEmployee = this.getAuthEmployee.bind(this);
		this.onResetPasswordClicked = this.onResetPasswordClicked.bind(this);
	}

	componentDidMount() {
		const that = this;

		setTimeout(function() {
			that.setState({
				cardAnimaton: "",
			});

			if (that.trustedDeviceKey) {
				const script = document.createElement('script');
				script.setAttribute('src', process.env.PUBLIC_URL + '/loginpage_nfcreader_wrap.js');
				document.head.appendChild(script);
			}
		}, 700);
	}

	async handleChange(event) {
		const {name, value, type, checked} = event.target;
		const target = event.target;
		let cursor = -1;
		
		if (target.setSelectionRange &&
				(
						type === 'text' ||
						type === 'search' ||
						type === 'password' ||
						type === 'url' ||
						type === 'tel'
				)
		) {
				cursor = ('selectionStart' in event.target ? (event.target.selectionStart ?? -1) : -1);
		}
		await (type === "checkbox" ? this.setState({ [name]: checked, errorMessage: null, }) : this.setState({ [name]: value, errorMessage: null, }));
		if (cursor != -1 && target.setSelectionRange) {
				target.setSelectionRange(cursor, cursor);
		}
		if (this.state.continuesValidation) {
				this.validateForm(false);
		}
	}

	handleSubmit(event) {
		const that = this;
		const { classes, intl } = this.props;
		event.preventDefault();

		that.setState({
			busyLoading: true,
		});

		const config = this.trustedDeviceKey !== null ? {
			headers: {
				"Trusted-Device-Key": this.trustedDeviceKey,
			},
			_retry: true,
		} : {};

		const nfcToken = document.getElementById("nfc_token")?.value ?? null;

		API.post("auth/login", { 
			"email": this.state.email,
			"username": this.state.email,
			"password": this.state.password,
			"nfc_token": nfcToken,
		}, config).then(res => {
			const data = res.data;
			const jwt = parseJwt(data.access_token);
			store.dispatch(setToken(data.access_token, jwt.exp, data.expires_in));
			that.getAuthEmployee();
		}).catch(function (error) {
			console.log(error);

			if ((error.response?.status ?? null) === 401) {
				that.setState({
					busyLoading: false,
					errorMessage: intl.formatMessage({id: i18nBase+"PASSWORD_OR_EMAIL_INCORRECT"}),
				});
			} else if ((error.response?.status ?? null) === 500 || error.response === undefined) {
				that.setState({
					busyLoading: false,
					errorMessage: intl.formatMessage({id: i18nBase+"SERVER_ERROR"}),
				});
			} else if ((error.response?.status ?? null) === 503) {
				that.setState({
					busyLoading: false,
					errorMessage: intl.formatMessage({id: i18nBase+"MAINTENANCE_ERROR"}),
				});
			} else {
				that.setState({
					busyLoading: false,
					errorMessage: intl.formatMessage({id: i18nBase+"CLIENT_ERROR"}),
				});
			}
		}).finally(function () {
			// always executed
		});
	}

	getAuthEmployee() {
		const that = this;
		const { classes, intl } = this.props;

		that.setState({
			busyLoading: true,
		});

		API.get("auth/employee").then(res => {
			const data = res.data.data;

			store.dispatch(clearOverrideIntl());
			fetch(process.env.REACT_APP_OVERRIDE_INTL_BASEHREF+(data.company_id)+"-"+(data.user.default_locale)+".json")
				.then((res) => res.json())
				.then((overrideMessages) => {
					console.log(overrideMessages);
					store.dispatch(setOverrideIntl(overrideMessages));
					
					store.dispatch(setAuthEmployee(data));
					
					const localeImport = import("moment/locale/"+legacyLocaleToMoment(data.user.default_locale)).then(() => {store.dispatch(setLocale(data.user.default_locale));});
					updateNavbarNotifications(API, store, setNavbarNotifications);
					that.setState({
						redirectToDashboard: true,
					});
				})
				.catch((error) => {
					console.error(error);

					store.dispatch(setAuthEmployee(data));
			
					const localeImport = import("moment/locale/"+legacyLocaleToMoment(data.user.default_locale)).then(() => {store.dispatch(setLocale(data.user.default_locale));});
					updateNavbarNotifications(API, store, setNavbarNotifications);
					that.setState({
						redirectToDashboard: true,
					});
				}
			);
		}).catch(function (error) {
			console.log(error);
			that.setState({
				busyLoading: false,
				errorMessage: intl.formatMessage({id: i18nBase+"SERVER_ERROR"}),
			});
		}).finally(function () {
			// always executed
		});
	}

	onResetPasswordClicked() {
		this.setState({
			redirectToResetPassword: true,
		});
	}

	render() {
		const { classes, intl } = this.props;

		if (this.state.redirectToDashboard === true) {
			store.dispatch(setRedirectFrom(null));
			return <Redirect push to={ this.props.redirectFrom ?? '/admin/dashboard' } />
		}

		if (this.state.redirectToResetPassword === true) {
			return <Redirect push to='/auth/password-reset' />
		}

		return (
			<div className={classes.container}>
				<GridContainer justify="center">
					<GridItem xs={12} sm={6} md={4}>
						<GridContainer>
							<GridItem xs={12}>
								<form onSubmit={this.handleSubmit}>
									<Card login className={classes[this.state.cardAnimaton]}>
										<CardHeader
											className={`${classes.cardHeader} ${classes.textCenter}`}
											color="rose"
										>
											<h4 className={classes.cardTitle}><FormattedMessage id={i18nBase+"LOG_IN"} /></h4>
											{/* <div className={classes.socialLine}>
												{[
													"fab fa-facebook-square",
													"fab fa-twitter",
													"fab fa-google-plus"
												].map((prop, key) => {
													return (
														<Button
															color="transparent"
															justIcon
															key={key}
															className={classes.customButtonClass}
														>
															<i className={prop} />
														</Button>
													);
												})}
											</div> */}
										</CardHeader>
										<CardBody>
											<CustomInput
												labelText={intl.formatMessage({id: i18nBase+"EMAIL"})}
												id="email"
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													endAdornment: (
														<InputAdornment position="end">
															<Email className={classes.inputAdornmentIcon} />
														</InputAdornment>
													),
													name: "email",
													value: this.state.email,
													onChange: this.handleChange,
												}}
											/>
											<CustomInput
												labelText={intl.formatMessage({id: i18nBase+"PASSWORD"})}
												id="password"
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													endAdornment: (
														<InputAdornment position="end">
															<Icon className={classes.inputAdornmentIcon}>
																lock_outline
															</Icon>
														</InputAdornment>
													),
													type: "password",
													autoComplete: "off",
													name: "password",
													value: this.state.password,
													onChange: this.handleChange,
												}}
											/>
											<input type="hidden" id="nfc_token" name="nfc_token" />
										</CardBody>
										<CardFooter className={classes.justifyContentCenter}>
											{this.state.busyLoading ? 
												<CircularIndeterminate></CircularIndeterminate>
												:
												<GridContainer>
													<GridItem xs={12}>
														<Button color="rose" simple size="lg" block type="submit" id="submit_login_button">
															<FormattedMessage id={i18nBase+"LETS_GO"} />
														</Button>
													</GridItem>
													<GridItem xs={12}>
														<Button color="rose" simple size="sm" block onClick={() => this.onResetPasswordClicked()}>
															<FormattedMessage id={i18nBase+"RESET_PASSWORD"} />
														</Button>
													</GridItem>
												</GridContainer>
											}
										</CardFooter>
									</Card>
								</form>
							</GridItem>
							{this.state.errorMessage ?
								<GridItem xs={12}>
									<SnackbarContent
										message={this.state.errorMessage}
										color="danger"
									/>
								</GridItem>
							: null}
						</GridContainer>
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		authEmployee: state.auth.authEmployee ?? null,
		redirectFrom: state.auth.redirectFrom ?? null,
	}
}

export default injectIntl(withStyles(styles, { withTheme: true })(connect(mapStateToProps)(LoginPage)));