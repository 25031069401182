import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footer/Footer.js";

import LoginPage from "views/Pages/LoginPage.js";
import ScreenPage from "views/Pages/ScreenPage.js";
import PasswordResetPage from "views/Pages/PasswordResetPage.js";
import LogoutPage from "views/Pages/LogoutPage";
import SwitchPage from "views/Pages/SwitchPage";

import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";

import login from "assets/img/login.jpeg";

const useStyles = makeStyles(styles);

const routes = [
	{
		collapse: true,
		name: "Pages",
		icon: Image,
		state: "pageCollapse",
		views: [
			{
				path: "/login-page",
				name: "Login Page",
				mini: "L",
				component: LoginPage,
				layout: "/auth"
			},
			{
				path: "/screen",
				name: "Authenticate Screen",
				mini: "AS",
				component: ScreenPage,
				layout: "/auth"
			},
			{
				path: "/password-reset",
				name: "Password Reset",
				mini: "PR",
				component: PasswordResetPage,
				layout: "/auth"
			},
			{
				path: "/logout-page",
				name: "Logout Page",
				mini: "L",
				component: LogoutPage,
				layout: "/auth"
			},
			{
				path: "/switch/:id",
				name: "Switch",
				mini: "S",
				component: SwitchPage,
				layout: "/auth"
			},
		]
	},
];

export default function Auth(props) {
	const { ...rest } = props;
	// ref for the wrapper div
	const wrapper = React.createRef();
	// styles
	const classes = useStyles();
	React.useEffect(() => {
		document.body.style.overflow = "unset";
		// Specify how to clean up after this effect:
		return function cleanup() {};
	});
	const getRoutes = routes => {
		return routes.map((prop, key) => {
			if (prop.collapse) {
				return getRoutes(prop.views);
			}
			if (prop.layout === "/auth") {
				return (
					<Route
						path={prop.layout + prop.path}
						component={prop.component}
						key={key}
					/>
				);
			} else {
				return null;
			}
		});
	};
	const getBgImage = () => {
		if (window.location.pathname.indexOf("/auth/login-page") !== -1) {
			return login;
		} else if (window.location.pathname.indexOf("/auth/password-reset") !== -1) {
			return login;
		} else if (window.location.pathname.indexOf("/auth/screen") !== -1) {
			return login;
		}
	};
	const getActiveRoute = routes => {
		let activeRoute = "Default Brand Text";
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse) {
				let collapseActiveRoute = getActiveRoute(routes[i].views);
				if (collapseActiveRoute !== activeRoute) {
					return collapseActiveRoute;
				}
			} else {
				if (
					window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
				) {
					return routes[i].name;
				}
			}
		}
		return activeRoute;
	};
	return (
		<div>
			{/* <AuthNavbar brandText={getActiveRoute(routes)} {...rest} /> */}
			<div className={classes.wrapper} ref={wrapper}>
				<div
					className={classes.fullPage}
					style={{ backgroundImage: "url(" + getBgImage() + ")" }}
				>
					<Switch>
						{getRoutes(routes)}
						<Redirect from="/auth" to="/auth/login-page" />
					</Switch>
					<Footer white />
				</div>
			</div>
		</div>
	);
}
