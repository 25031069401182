import React from "react";
import { connect } from 'react-redux';
import { IntlProvider } from "react-intl";
import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/dist/locale-data/en";
import "@formatjs/intl-relativetimeformat/dist/locale-data/nl";
import "@formatjs/intl-relativetimeformat/dist/locale-data/fr";
import "@formatjs/intl-relativetimeformat/dist/locale-data/de";

import enMessages from "./messages/en";
import nlMessages from "./messages/nl";
import frMessages from "./messages/fr";
import deMessages from "./messages/de";
const allMessages = {
	en: enMessages,
	nl: nlMessages,
	fr: frMessages,
	de: deMessages,
};

class I18nProvider extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { children } = this.props;
		const locale = this.props.locale;
		const messages = allMessages[locale];

		return (
				<IntlProvider locale={locale} messages={messages}>
					{children}
				</IntlProvider>
		);
	}
}

function mapStateToProps(state) {
	return {
			locale: state.I18n.locale
	}
}

export default connect(mapStateToProps)(I18nProvider);