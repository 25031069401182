import Icon from "@material-ui/core/Icon";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/core components
import { withStyles } from '@material-ui/core/styles';
import Email from "@material-ui/icons/Email";
import API from 'api';
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardHeader from "components/Card/CardHeader.js";
import CircularIndeterminate from 'components/CircularIndeterminate/CircularIndeterminate.js';
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
// import LockOutline from "@material-ui/icons/LockOutline";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import { store } from "index";
import queryString from 'query-string';
import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import { setAuthEmployee, setToken } from "Redux/AuthActions";
import { setLocale, clearOverrideIntl, setOverrideIntl } from "Redux/I18nActions";
import { setNavbarNotifications } from "Redux/NotificationsActions";
import { updateNavbarNotifications, legacyLocaleToMoment, parseJwt } from "utils";

const i18nBase = "PAGES.PASSWORD_RESET_PAGE.";
class PasswordResetPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			viewState: "ENTER_EMAIL",
			cardAnimaton: "cardHidden",
			busyLoading: false,

			email: "",
			password: "",
			passwordConfirmation: "",
			errorMessage: null,
			succesMessage: null,

			redirectToLogin: false,
			redirectToDashboard: false,
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmitSendEmail = this.handleSubmitSendEmail.bind(this);
		this.handleSubmitSendPassword = this.handleSubmitSendPassword.bind(this);
		this.onReturnToLoginClicked = this.onReturnToLoginClicked.bind(this);
		this.validateToken = this.validateToken.bind(this);
		this.getAuthEmployee = this.getAuthEmployee.bind(this);
	}

	componentDidMount() {
		const that = this;

		let params = queryString.parse(this.props.location.search);

		if (params.token) {
			that.setState({
				viewState: "VALIDATING_TOKEN",
			});
			this.validateToken(params.token);
		}

		setTimeout(function () {
			that.setState({
				cardAnimaton: "",
			});
		}, 700);
	}

	async handleChange(event) {
		const { name, value, type, checked } = event.target;
		const target = event.target;
		let cursor = -1;

		if (target.setSelectionRange &&
			(
				type === 'text' ||
				type === 'search' ||
				type === 'password' ||
				type === 'url' ||
				type === 'tel'
			)
		) {
			cursor = ('selectionStart' in event.target ? (event.target.selectionStart ?? -1) : -1);
		}
		await (type === "checkbox" ? this.setState({ [name]: checked, errorMessage: null, }) : this.setState({ [name]: value, errorMessage: null, }));
		if (cursor != -1 && target.setSelectionRange) {
			target.setSelectionRange(cursor, cursor);
		}
		if (this.state.continuesValidation) {
			this.validateForm(false);
		}
	}

	validateToken(token) {
		const that = this;
		const { classes, intl } = this.props;

		that.setState({
			busyLoading: true,
		});

		API.get("auth/passwordreset/token/" + token).then(res => {
			const data = res.data;
			that.setState({
				busyLoading: false,
				viewState: "ENTER_PASSWORD",
				email: data.email,
				token: data.token,
			});
			console.log(data);
		}).catch(function (error) {
			console.log(error);

			that.setState({
				viewState: "INVALID_TOKEN",
				busyLoading: false,
				errorMessage: intl.formatMessage({ id: i18nBase + "INVALID_TOKEN" }),
			});
		}).finally(function () {
			// always executed
		});
	}

	handleSubmitSendEmail(event) {
		const that = this;
		const { classes, intl } = this.props;
		event.preventDefault();

		if (this.state.email == "") {
			this.setState({
				errorMessage: intl.formatMessage({ id: i18nBase + "EMAIL_EMPTY" }),
			});
			return;
		}

		that.setState({
			busyLoading: true,
		});

		API.post("auth/passwordreset/token", {
			"email": this.state.email,
		}).then(res => {
			const data = res.data;
			that.setState({
				busyLoading: false,
				viewState: "EMAIL_SENT",
				succesMessage: intl.formatMessage({ id: i18nBase + "EMAIL_SENT" }),
			});
		}).catch(function (error) {
			console.log(error);

			that.setState({
				busyLoading: false,
				errorMessage: intl.formatMessage({ id: i18nBase + "SOMETHING_WENT_WRONG" }),
			});
		}).finally(function () {
			// always executed
		});
	}

	handleSubmitSendPassword(event) {
		const that = this;
		const { classes, intl } = this.props;
		event.preventDefault();

		if (this.state.password == "" || (this.state.password != this.state.passwordConfirmation)) {
			this.setState({
				errorMessage: intl.formatMessage({ id: i18nBase + "PASSWORD_EMPTY" }),
			});
			return;
		}

		that.setState({
			busyLoading: true,
		});

		API.patch("auth/passwordreset", {
			"email": this.state.email,
			"token": this.state.token,
			"password": this.state.password,
		}).then(res => {
			const data = res.data;
			const jwt = parseJwt(data.access_token);
			store.dispatch(setToken(data.access_token, jwt.exp, data.expires_in));
			that.getAuthEmployee();
		}).catch(function (error) {
			console.log(error);

			if (error.response.status === 406) {
				that.setState({
					busyLoading: false,
					errorMessage: intl.formatMessage({ id: i18nBase + "TOO_SIMPLE" }),
				});
			} else {
				that.setState({
					busyLoading: false,
					errorMessage: intl.formatMessage({ id: i18nBase + "SOMETHING_WENT_WRONG" }),
				});
			}
		}).finally(function () {
			// always executed
		});
	}

	getAuthEmployee() {
		const that = this;
		const { classes, intl } = this.props;

		that.setState({
			busyLoading: true,
		});

		API.get("auth/employee").then(res => {
			const data = res.data.data;

			store.dispatch(clearOverrideIntl());
			fetch(process.env.REACT_APP_OVERRIDE_INTL_BASEHREF+(data.company_id)+"-"+(data.user.default_locale)+".json")
				.then((res) => res.json())
				.then((overrideMessages) => {
					console.log(overrideMessages);
					store.dispatch(setOverrideIntl(overrideMessages));
					
					store.dispatch(setAuthEmployee(data));
					
					const localeImport = import("moment/locale/"+legacyLocaleToMoment(data.user.default_locale)).then(() => {store.dispatch(setLocale(data.user.default_locale));});
					updateNavbarNotifications(API, store, setNavbarNotifications);
					that.setState({
						redirectToDashboard: true,
					});
				})
				.catch((error) => {
					console.error(error);

					store.dispatch(setAuthEmployee(data));
			
					const localeImport = import("moment/locale/"+legacyLocaleToMoment(data.user.default_locale)).then(() => {store.dispatch(setLocale(data.user.default_locale));});
					updateNavbarNotifications(API, store, setNavbarNotifications);
					that.setState({
						redirectToDashboard: true,
					});
				}
			);
		}).catch(function (error) {
			console.log(error);
			that.setState({
				busyLoading: false,
				errorMessage: intl.formatMessage({id: i18nBase+"PASSWORD_OR_EMAIL_INCORRECT"}),
			});
		}).finally(function () {
			// always executed
		});
	}

	onReturnToLoginClicked() {
		this.setState({
			redirectToLogin: true,
		});
	}

	render() {
		const { classes, intl } = this.props;

		if (this.state.redirectToLogin === true) {
			return <Redirect push to='/auth/login-page' />
		}

		if (this.state.redirectToDashboard === true) {
			return <Redirect push to='/admin/dashboard' />
		}

		return (
			<div className={classes.container}>
				<GridContainer justify="center">
					<GridItem xs={12} sm={6} md={4}>
						<GridContainer>
							{this.state.viewState == "ENTER_EMAIL" ?
								<GridItem xs={12}>
									<form onSubmit={this.handleSubmitSendEmail}>
										<Card login className={classes[this.state.cardAnimaton]}>
											<CardHeader
												className={`${classes.cardHeader} ${classes.textCenter}`}
												color="rose"
											>
												<h4 className={classes.cardTitle}><FormattedMessage id={i18nBase + "TITLE"} /></h4>
											</CardHeader>
											<CardBody>
												<FormattedMessage id={i18nBase + "EXPLANATION"} />
												<CustomInput
													labelText={intl.formatMessage({ id: i18nBase + "EMAIL" })}
													id="email"
													formControlProps={{
														fullWidth: true
													}}
													inputProps={{
														endAdornment: (
															<InputAdornment position="end">
																<Email className={classes.inputAdornmentIcon} />
															</InputAdornment>
														),
														name: "email",
														value: this.state.email,
														onChange: this.handleChange,
													}}
												/>
											</CardBody>
											<CardFooter className={classes.justifyContentCenter}>
												{this.state.busyLoading ?
													<CircularIndeterminate></CircularIndeterminate>
													:
													<GridContainer>
														<GridItem xs={12}>
															<Button color="rose" simple size="lg" block type="submit">
																<FormattedMessage id={i18nBase + "SEND_EMAIL"} />
															</Button>
														</GridItem>
														<GridItem xs={12}>
															<Button color="rose" simple size="xs" block onClick={() => this.onReturnToLoginClicked()}>
																<FormattedMessage id={i18nBase + "RETURN_TO_LOGIN"} />
															</Button>
														</GridItem>
													</GridContainer>
												}
											</CardFooter>
										</Card>
									</form>
								</GridItem>
								: null}
							{this.state.viewState == "ENTER_PASSWORD" ?
								<GridItem xs={12}>
									<form onSubmit={this.handleSubmitSendPassword}>
										<Card login className={classes[this.state.cardAnimaton]}>
											<CardHeader
												className={`${classes.cardHeader} ${classes.textCenter}`}
												color="rose"
											>
												<h4 className={classes.cardTitle}><FormattedMessage id={i18nBase + "TITLE"} /></h4>
											</CardHeader>
											<CardBody>
												<CustomInput
													labelText={intl.formatMessage({ id: i18nBase + "PASSWORD" })}
													id="password"
													formControlProps={{
														fullWidth: true
													}}
													inputProps={{
														endAdornment: (
															<InputAdornment position="end">
																<Icon className={classes.inputAdornmentIcon}>
																	lock_outline
																</Icon>
															</InputAdornment>
														),
														type: "password",
														autoComplete: "off",
														name: "password",
														value: this.state.password,
														onChange: this.handleChange,
													}}
												/>
												<CustomInput
													labelText={intl.formatMessage({ id: i18nBase + "PASSWORD_CONFIRMATION" })}
													id="passwordConfirmation"
													formControlProps={{
														fullWidth: true
													}}
													inputProps={{
														endAdornment: (
															<InputAdornment position="end">
																<Icon className={classes.inputAdornmentIcon}>
																	lock_outline
																</Icon>
															</InputAdornment>
														),
														type: "password",
														autoComplete: "off",
														name: "passwordConfirmation",
														value: this.state.passwordConfirmation,
														onChange: this.handleChange,
													}}
												/>
											</CardBody>
											<CardFooter className={classes.justifyContentCenter}>
												{this.state.busyLoading ?
													<CircularIndeterminate></CircularIndeterminate>
													:
													<GridContainer>
														<GridItem xs={12}>
															<Button color="rose" simple size="lg" block type="submit">
																<FormattedMessage id={i18nBase + "SEND_PASSWORD"} />
															</Button>
														</GridItem>
														<GridItem xs={12}>
															<Button color="rose" simple size="xs" block onClick={() => this.onReturnToLoginClicked()}>
																<FormattedMessage id={i18nBase + "RETURN_TO_LOGIN"} />
															</Button>
														</GridItem>
													</GridContainer>
												}
											</CardFooter>
										</Card>
									</form>
								</GridItem>
								: null}
							{this.state.viewState == "EMAIL_SENT" || this.state.viewState == "VALIDATING_TOKEN" || this.state.viewState == "INVALID_TOKEN" ?
								<GridItem xs={12}>
									<Card login className={classes[this.state.cardAnimaton]}>
										<CardHeader
											className={`${classes.cardHeader} ${classes.textCenter}`}
											color="rose"
										>
											<h4 className={classes.cardTitle}><FormattedMessage id={i18nBase + "TITLE"} /></h4>
										</CardHeader>
										<CardFooter className={classes.justifyContentCenter}>
											{this.state.busyLoading ?
												<CircularIndeterminate></CircularIndeterminate>
												:
												<GridContainer>
													<GridItem xs={12}>
														<Button color="rose" simple size="xs" block onClick={() => this.onReturnToLoginClicked()}>
															<FormattedMessage id={i18nBase + "RETURN_TO_LOGIN"} />
														</Button>
													</GridItem>
												</GridContainer>
											}
										</CardFooter>
									</Card>
								</GridItem>
								: null}
							{this.state.errorMessage ?
								<GridItem xs={12}>
									<SnackbarContent
										message={this.state.errorMessage}
										color="danger"
									/>
								</GridItem>
								: null}
							{this.state.succesMessage ?
								<GridItem xs={12}>
									<SnackbarContent
										message={this.state.succesMessage}
										color="success"
									/>
								</GridItem>
								: null}
						</GridContainer>
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		authEmployee: state.auth.authEmployee || null
	}
}

export default injectIntl(withStyles(styles, { withTheme: true })(connect(mapStateToProps)(PasswordResetPage)));