import * as actionType from './I18nActionType';

const initialState = {
		locale: "en",
		overrideIntl: null,
}

const I18nReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionType.SET_LOCALE:
			return {...state, locale: action.payload};
		case actionType.SET_OVERRIDE_INTL:
			return {...state, overrideIntl: action.payload};
		case actionType.CLEAR_OVERRIDE_INTL:
			return {...state, overrideIntl: null};
		
		default:
			return state
	}
}

export default I18nReducer;