import React from 'react';
import PandoraModel from "traits/PandoraModel";
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import * as License from "license.js";
import * as Permissions from "permission.js";

class EmployeeNoteTemplate extends PandoraModel {
	static modelName = "EmployeeNoteTemplate";
	static getLicense() { return License.ICECLOUD}

	static icon = BookmarksIcon;

	// static orderable = true;
	// static copyable = true;
	// static loadBeforeCopy = false;
	// static hasCrudPages = false;
	// static crudAsNotification = true;
	// static editableOnTable = true;

	// PANDORA_ANCHOR_INSERT_OVERRIDE_VARIABLES_HERE

	static cards = [
		{
			title: "GENERAL_INFO",
			icon: BookmarksIcon,
			fields: {
				// PANDORA_ANCHOR_INSERT_FIELDS_HERE
				name: { type: "text", maxLength: "255", required: true, showOnTable: true, },
				content: { type: "text", multiline: true, rows: 12, required: true, },
			},
		},
		// {
		// 	title: "GENERAL_INFO",
		// 	icon: FiberNewIcon,
		// 	fields: {
		//
		// 	},
		// },
		
		// {
		// 	title: "GENERAL_INFO",
		// 	icon: FiberNewIcon,
		// 	fields: {
		// 		// text: {type: "text", required: true, },
		// 		// textarea: {type: "text", multiline: true, rows: 12, required: true, },
		// 		// number: {type: "number", required: true, inputProps: {min: 0, max: 99999, step: 1}, },
		// 		// select: {type: "select", required: true, options: ["OPTION"], },
		// 		// checkbox: {type: "checkbox", },
				
		// 		// md12: {type: "text", md: 12, },
		// 		// showOnTable: {type: "text", showOnTable: true, },
		// 	},
		// },

	];

	// PANDORA_ANCHOR_INSERT_OVERRIDE_FUNCTIONS_HERE
	// static getCopyableChildren() { return [EmployeeNoteTemplateItem]; };
	static getIndexPermissions() {
		return [
			Permissions.ICECLOUD_EMPLOYEE_NOTES_ADD,
		];
	}

	static getShowPermissions() {
		return [
			Permissions.ICECLOUD_EMPLOYEE_NOTES_ADD,
		];
	}

	static getUpdatePermissions() {
		return [
			Permissions.ICECLOUD_EMPLOYEE_NOTES_ADD,
		];
	}

	static getStorePermissions() {
		return [
			Permissions.ICECLOUD_EMPLOYEE_NOTES_ADD,
		];
	}

	static getDestroyPermissions() {
		return [
			Permissions.ICECLOUD_EMPLOYEE_NOTES_ADD,
		];
	}
}

export default EmployeeNoteTemplate;