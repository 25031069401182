import * as actionType from './LayersActionType';

export function setTrackedObject(type, id){
    return {
        type: actionType.SET_TRACKED_OBJECT,
        payload: {
            type: type,
            id: id,
        },
    }
};

export function clearTrackedObject(){
    return {
        type: actionType.CLEAR_TRACKED_OBJECT,
    }
};

export function setTrackedLocation(latitude, longitude){
    return {
        type: actionType.SET_TRACKED_LOCATION,
        payload: {
            latitude: latitude,
            longitude: longitude,
        },
    }
};

export function setBlipHeightFeelingDiv(blip){
    return {
        type: actionType.SET_BLIP_HEIGHT_FILLING_DIV,
        payload: blip,
    }
};