import PandoraModel from "traits/PandoraModel";
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import * as License from "license.js";

class LoadSize extends PandoraModel {
	static modelName = "LoadSize";
	static getLicense() { return License.ICETRUCK}

	static icon = UnarchiveIcon;

	// PANDORA_ANCHOR_INSERT_OVERRIDE_VARIABLES_HERE
	static permissionNamesAreSingular = true;
	
	static cards = [
		{
			title: "GENERAL_INFO",
			icon: UnarchiveIcon,
			fields: {
				abbreviation: {type: "text", required: true, showOnTable: true, },
				description: {type: "text", required: false, showOnTable: true, },
				auto_apply_at_nr_of_people: {type: "number", required: false, inputProps: {min: 0, max: 99999, step: 1}, showOnTable: true, },

				// text: {type: "text", required: true},
				// textarea: {type: "text", multiline: true, rows: 12, required: true},
				// number: {type: "number", required: true, inputProps: {min: 0, max: 99999, step: 1}},
				// select: {type: "select", required: true, options: ["OPTION"]},
				
				// md12: {type: "text", md: 12},
				// showOnTable: {type: "text", showOnTable: false},
			},
		},
	];

	// PANDORA_ANCHOR_INSERT_OVERRIDE_FUNCTIONS_HERE
	static sortFunction(a, b) {
		return (a.abbreviation != b.abbreviation ? (a.abbreviation > b.abbreviation ? 1 : -1) : (a.description > b.description ? 1 : -1));
	}
}

export default LoadSize;