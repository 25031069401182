import { lazyRetry } from "lazyRetry";

import * as Permissions from "permission.js";
import * as License from "license.js";

// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import BookmarksIcon from "@material-ui/icons/Bookmarks";
import CloudQueue from "@material-ui/icons/CloudQueue";
import People from "@material-ui/icons/People";
import BusinessCenter from "@material-ui/icons/BusinessCenter";
import Business from "@material-ui/icons/Business";
import Alarm from "@material-ui/icons/Alarm";
import CalendarToday from "@material-ui/icons/CalendarToday";
import LocalShipping from "@material-ui/icons/LocalShipping";
import IcecreamVanIcon from "assets/icons/IcecreamVan.js";
import History from "@material-ui/icons/History";
import WatchLater from "@material-ui/icons/WatchLater";
import NotListedLocation from "@material-ui/icons/NotListedLocation";
import EventNote from "@material-ui/icons/EventNote";
import Assessment from "@material-ui/icons/Assessment";
import MapIcon from "@material-ui/icons/Map";
import LocalTaxi from "@material-ui/icons/LocalTaxi";
import Receipt from "@material-ui/icons/Receipt";
import PolicyIcon from '@material-ui/icons/Policy';
import HistoryIcon from '@material-ui/icons/History';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import StorefrontIcon from '@material-ui/icons/Storefront';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import GridOn from '@material-ui/icons/GridOn';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import PhonelinkLockIcon from '@material-ui/icons/PhonelinkLock';
import LockIcon from '@material-ui/icons/Lock';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import ExploreIcon from '@material-ui/icons/Explore';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import AssignmentInd from "@material-ui/icons/AssignmentInd";
import StoreIcon from "@material-ui/icons/Store";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import FavoriteIcon from '@material-ui/icons/Favorite';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';

// PANDORA_ANCHOR_INSERT_IMPORT_HERE
import Tablet from 'models/Tablet';
import DeliveryVan from 'models/DeliveryVan';
import IcecreamVan from 'models/IcecreamVan';
import EmployeeNoteTemplate from 'models/EmployeeNoteTemplate';
import EmployeeNote from 'models/EmployeeNote';
import WageAgreement from 'models/WageAgreement';
import ExEmployee from 'models/ExEmployee';
import Employee from 'models/Employee';
import Contract from 'models/Contract';
import TrustedDevice from 'models/TrustedDevice';
import ShipmentZone from 'models/ShipmentZone';
import DeliveryZone from 'models/DeliveryZone';
import PickUpPoint from 'models/PickUpPoint';
import MenuItem from 'models/MenuItem';
import Menu from 'models/Menu';
import ContractTemplate from 'models/ContractTemplate';
import LoadSize from 'models/LoadSize';

// PANDORA_ANCHOR !! Don't touch the next lines
const models = [
	Tablet,
	DeliveryVan,
	IcecreamVan,
	EmployeeNoteTemplate,
	EmployeeNote,
	WageAgreement,
	ExEmployee,
	Employee,
	Contract,
	TrustedDevice,
	ShipmentZone,
	DeliveryZone,
	PickUpPoint,
	MenuItem,
	Menu,
	ContractTemplate,
	LoadSize,
];

const getViews = (license) => {{
	return models.map(model => model.getViews().filter(view => (view.license ?? model.getLicense()) == license)).flat(1);
}}

const Dashboard = lazyRetry(() => import("views/Dashboard/Dashboard.js"));

const MyProfile = lazyRetry(() => import("pandoraviews/MyProfile/MyProfile.js"));

//const Employees = lazyRetry(() => import("views/ICEcloud/Employees/Employees.js"));
//const CreateEmployee = lazyRetry(() => import("views/ICEcloud/Employees/CreateEmployee"));
//const EditEmployee = lazyRetry(() => import("views/ICEcloud/Employees/EditEmployee"));

const PermissionGroups = lazyRetry(() => import("views/ICEcloud/PermissionGroups/PermissionGroups"));
const EditPermissionGroup = lazyRetry(() => import("views/ICEcloud/PermissionGroups/EditPermissionGroup"));
const CreatePermissionGroup = lazyRetry(() => import("views/ICEcloud/PermissionGroups/CreatePermissionGroup"));

const Company = lazyRetry(() => import("views/ICEcloud/Company/Company"));

const CompanyStructure = lazyRetry(() => import("views/ICEcloud/CompanyStructure/CompanyStructure"));

const GiftCardTransactions = lazyRetry(() => import("views/ICEcloud/GiftCards/GiftCardTransactions"));

const EditGiftCard = lazyRetry(() => import("views/ICEcloud/GiftCards/EditGiftCard"));

const Invoices = lazyRetry(() => import("views/ICEcloud/Invoices/Invoices"));
const EditInvoice = lazyRetry(() => import("views/ICEcloud/Invoices/EditInvoice"));

//const TrustedDevices = lazyRetry(() => import("views/ICEcloud/TrustedDevices/TrustedDevices"));

const Suppliers = lazyRetry(() => import("views/ICEcloud/Suppliers/Suppliers"));
const EditSupplier = lazyRetry(() => import("views/ICEcloud/Suppliers/EditSupplier"));
const CreateSupplier = lazyRetry(() => import("views/ICEcloud/Suppliers/CreateSupplier"));
const SupplierOrders = lazyRetry(() => import("views/ICEcloud/SupplierOrders/SupplierOrders"));
const EditSupplierOrder = lazyRetry(() => import("views/ICEcloud/SupplierOrders/EditSupplierOrder"));
const CreateSupplierOrder = lazyRetry(() => import("views/ICEcloud/SupplierOrders/CreateSupplierOrder"));

const SuppliedItems = lazyRetry(() => import("views/ICEshop/SuppliedItems/SuppliedItems"));

const CreateWebshopOrder = lazyRetry(() => import("views/ICEshop/WebshopOrders/CreateWebshopOrder"));
const EditWebshopOrder = lazyRetry(() => import("views/ICEshop/WebshopOrders/EditWebshopOrder"));
const WebshopOrders = lazyRetry(() => import("views/ICEshop/WebshopOrders/WebshopOrders"));
const Worksheet = lazyRetry(() => import("views/ICEshop/Worksheet/Worksheet"));
const Products = lazyRetry(() => import("views/ICEshop/Products/Products"));
const EditProduct = lazyRetry(() => import("views/ICEshop/Products/EditProduct"));
const CreateProduct = lazyRetry(() => import("views/ICEshop/Products/CreateProduct"));
//const DeliveryVans = lazyRetry(() => import("views/ICEshop/DeliveryVans/DeliveryVans"));
//const EditDeliveryVan = lazyRetry(() => import("views/ICEshop/DeliveryVans/EditDeliveryVan"));
//const CreateDeliveryVan = lazyRetry(() => import("views/ICEshop/DeliveryVans/CreateDeliveryVan"));
const DeliverySchedules = lazyRetry(() => import("views/ICEshop/DeliverySchedules/DeliverySchedules"));
const WebshopAccounts = lazyRetry(() => import("views/ICEshop/WebshopAccounts/WebshopAccounts"));
const EditWebshopAccount = lazyRetry(() => import("views/ICEshop/WebshopAccounts/EditWebshopAccount"));
const CreateWebshopAccount = lazyRetry(() => import("views/ICEshop/WebshopAccounts/CreateWebshopAccount"));
const Discounts = lazyRetry(() => import("views/ICEshop/Discounts/Discounts"));
const EditDiscount = lazyRetry(() => import("views/ICEshop/Discounts/EditDiscount"));
const CreateDiscount = lazyRetry(() => import("views/ICEshop/Discounts/CreateDiscount"));
const DailyReport = lazyRetry(() => import("views/ICEshop/DailyReport/DailyReport"));
// const PickUpPoints = lazyRetry(() => import("views/ICEshop/PickUpPoints/PickUpPoints"));
// const EditPickUpPoint = lazyRetry(() => import("views/ICEshop/PickUpPoints/EditPickUpPoint"));
// const CreatePickUpPoint = lazyRetry(() => import("views/ICEshop/PickUpPoints/CreatePickUpPoint"));
//const DeliveryZones = lazyRetry(() => import("views/ICEshop/DeliveryZones/DeliveryZones"));
//const EditDeliveryZone = lazyRetry(() => import("views/ICEshop/DeliveryZones/EditDeliveryZone"));
//const CreateDeliveryZone = lazyRetry(() => import("views/ICEshop/DeliveryZones/CreateDeliveryZone"));
//const ShipmentZones = lazyRetry(() => import("views/ICEshop/ShipmentZones/ShipmentZones"));
//const EditShipmentZone = lazyRetry(() => import("views/ICEshop/ShipmentZones/EditShipmentZone"));
//const CreateShipmentZone = lazyRetry(() => import("views/ICEshop/ShipmentZones/CreateShipmentZone"));

const MetricHistory = lazyRetry(() => import("views/ICEguard/Metric/History"));

//const IcecreamVans = lazyRetry(() => import("views/ICEtruck/IcecreamVans/IcecreamVans"));
//const EditIcecreamVan = lazyRetry(() => import("views/ICEtruck/IcecreamVans/EditIcecreamVan"));
//const CreateIcecreamVan = lazyRetry(() => import("views/ICEtruck/IcecreamVans/CreateIcecreamVan"));
const IcecreamVanPreferredZones = lazyRetry(() => import("views/ICEtruck/IcecreamVanPreferredZones/IcecreamVanPreferredZones"));
const CreateIcecreamVanReservation = lazyRetry(() => import("views/ICEtruck/IcecreamVanReservations/CreateIcecreamVanReservation"));
const EditIcecreamVanReservation = lazyRetry(() => import("views/ICEtruck/IcecreamVanReservations/EditIcecreamVanReservation"));
const IcecreamVanReservations = lazyRetry(() => import("views/ICEtruck/IcecreamVanReservations/IcecreamVanReservations"));
const IcecreamVanSchedules = lazyRetry(() => import("views/ICEtruck/IcecreamVanSchedules/IcecreamVanSchedules"));
const DailySalesReport = lazyRetry(() => import("views/ICEtruck/DailySalesReport/DailySalesReport"));
const Map = lazyRetry(() => import("views/ICEtruck/NewMap/Map"));
const DevicesIcon = lazyRetry(() => import('@material-ui/icons/Devices'));
//const Tablets = lazyRetry(() => import("views/ICEtruck/Tablets/Tablets"));
//const CreateTablet = lazyRetry(() => import("views/ICEtruck/Tablets/CreateTablet"));
//const EditTablet = lazyRetry(() => import("views/ICEtruck/Tablets/EditTablet"));
// const ListLoadSize = lazyRetry(() => import("views/ICEtruck/LoadSizes/ListLoadSize"));
// const CreateLoadSize = lazyRetry(() => import("views/ICEtruck/LoadSizes/CreateLoadSize"));
// const EditLoadSize = lazyRetry(() => import("views/ICEtruck/LoadSizes/EditLoadSize"));
const IcecreamVanZones = lazyRetry(() => import("views/ICEtruck/IcecreamVanZones/IcecreamVanZones"));
const CreateIcecreamVanZone = lazyRetry(() => import("views/ICEtruck/IcecreamVanZones/CreateIcecreamVanZone"));
const EditIcecreamVanZone = lazyRetry(() => import("views/ICEtruck/IcecreamVanZones/EditIcecreamVanZone"));
const DamageReports = lazyRetry(() => import("views/ICEtruck/DamageReports/DamageReports"));

const Availabilities = lazyRetry(() => import("views/ICEclock/Availabilities/Availabilities"));
const ShiftsOverview = lazyRetry(() => import("views/ICEclock/ShiftsOverview/ShiftsOverview"));
const Schedules = lazyRetry(() => import("views/ICEclock/Schedules/Schedules"));

//const ContractTemplates = lazyRetry(() => import("views/ICEclock/ContractTemplates/ContractTemplates"));
//const EditContractTemplate = lazyRetry(() => import("views/ICEclock/ContractTemplates/EditContractTemplate"));
//const CreateContractTemplate = lazyRetry(() => import("views/ICEclock/ContractTemplates/CreateContractTemplate"));

const AvailabilitiesReport = lazyRetry(() => import("views/ICEclock/AvailabilitiesReport/AvailabilitiesReport"));

//const Contracts = lazyRetry(() => import("views/ICEclock/Contracts/Contracts"));

//const Menus = lazyRetry(() => import("views/ICEcash/Menus/Menus"));
//const EditMenu = lazyRetry(() => import("views/ICEcash/Menus/EditMenu"));
//const CreateMenu = lazyRetry(() => import("views/ICEcash/Menus/CreateMenu"));

const LegacyIntranet = lazyRetry(() => import("views/LegacyIntranet/LegacyIntranet"));

var dashRoutes = [
	{
		sortFirst: 1,
		path: "/dashboard",
		name: "DASHBOARD",
		icon: DashboardIcon,
		component: Dashboard,
		layout: "/admin"
	},
	{
		sortFirst: 1,
		path: "/fullscreenmetrics",
		name: "ICECLOUD.FULLSCREEN_METRICS",
		icon: DashboardIcon,
		component: Dashboard,
		layout: "/admin",
		permissions: [
			Permissions.ICECLOUD_FULLSCREEN_METRICS
		],
	},
	{
		path: "/my-profile",
		name: "MY_PROFILE",
		icon: DashboardIcon,
		component: MyProfile,
		layout: "/admin",
		hidden: true,
	},
	{
		collapse: true,
		name: "ICECLOUD",
		icon: CloudQueue,
		license: License.ICECLOUD,
		state: "icecloudCollapse",
		views: getViews(License.ICECLOUD).concat([
		/*{
				path: "/icecloud/employee/create",
				name: "ICECLOUD.EMPLOYEES.CREATE",
				icon: People,
				component: CreateEmployee,
				layout: "/admin",
				hidden: true,
				license: License.ICECLOUD,
				permissions: [
					Permissions.ICECLOUD_EMPLOYEES_ADD
				],
			},*/
		/*{
				path: "/icecloud/employee/edit/:id",
				matchPath: "/icecloud/employee/edit",
				name: "ICECLOUD.EMPLOYEES.EDIT",
				icon: People,
				component: EditEmployee,
				layout: "/admin",
				hidden: true,
				license: License.ICECLOUD,
				permissions: [
					Permissions.ICECLOUD_EMPLOYEES_EDIT
				],
			},*/
		/*{
				path: "/icecloud/employee/view/:id",
				matchPath: "/icecloud/employee/view",
				name: "ICECLOUD.EMPLOYEES.VIEW",
				icon: People,
				component: EditEmployee,
				layout: "/admin",
				hidden: true,
				license: License.ICECLOUD,
				permissions: [
					Permissions.ICECLOUD_EMPLOYEES_LIST
				],
			},*/
		/*{
				path: "/icecloud/employee",
				name: "ICECLOUD.EMPLOYEES",
				icon: People,
				component: Employees,
				layout: "/admin",
				license: License.ICECLOUD,
				permissions: [
					Permissions.ICECLOUD_EMPLOYEES_LIST,
					Permissions.ICECLOUD_EMPLOYEES_EDIT,
					Permissions.ICECLOUD_EMPLOYEES_DELETE,
					Permissions.ICECLOUD_EMPLOYEES_ADD,
					Permissions.ICECLOUD_EMPLOYEES_RESTORE_LIST,
					Permissions.ICECLOUD_EMPLOYEES_RESTORE
				],
			},*/
			{
				path: "/icecloud/permissiongroup/create",
				name: "ICECLOUD.PERMISSION_GROUPS.CREATE",
				icon: LockIcon,
				component: CreatePermissionGroup,
				layout: "/admin",
				hidden: true,
				license: License.ICECLOUD,
				permissions: [
					Permissions.ICECLOUD_PERMISSION_GROUP_MODERATE
				],
			},
			{
				path: "/icecloud/permissiongroup/edit/:id",
				matchPath: "/icecloud/permissiongroup/edit",
				name: "ICECLOUD.PERMISSION_GROUPS.EDIT",
				icon: LockIcon,
				component: EditPermissionGroup,
				layout: "/admin",
				hidden: true,
				license: License.ICECLOUD,
				permissions: [
					Permissions.ICECLOUD_PERMISSION_GROUP_MODERATE
				],
			},
			{
				path: "/icecloud/permissiongroup/view/:id",
				matchPath: "/icecloud/permissiongroup/view",
				name: "ICECLOUD.PERMISSION_GROUPS.VIEW",
				icon: LockIcon,
				component: EditPermissionGroup,
				layout: "/admin",
				hidden: true,
				license: License.ICECLOUD,
				permissions: [
					Permissions.ICECLOUD_PERMISSION_GROUP_VIEW
				],
			},
			{
				path: "/icecloud/permissiongroup",
				name: "ICECLOUD.PERMISSION_GROUPS",
				icon: LockIcon,
				component: PermissionGroups,
				layout: "/admin",
				hidden: false,
				license: License.ICECLOUD,
				permissions: [
					Permissions.ICECLOUD_PERMISSION_GROUP_VIEW,
					Permissions.ICECLOUD_PERMISSION_GROUP_MODERATE
				],
			},
			{
				path: "/icecloud/company",
				name: "ICECLOUD.COMPANY",
				icon: BusinessCenter,
				component: Company,
				layout: "/admin",
				license: License.ICECLOUD,
				permissions: [
					Permissions.ICECLOUD_COMPANY_VIEW,
					Permissions.ICECLOUD_COMPANY_MODERATE,
				],
			},
			{
				path: "/icecloud/companystructure",
				name: "ICECLOUD.COMPANYSTRUCTURE",
				icon: Business,
				component: CompanyStructure,
				layout: "/admin",
				license: License.ICECLOUD,
				permissions: [
					Permissions.ICECLOUD_COMPANYSTRUCTURE_VIEW,
					Permissions.ICECLOUD_COMPANYSTRUCTURE_MODERATE,
				],
			},
			{
				path: "/icecloud/invoices/edit/:id",
				matchPath: "/icecloud/invoices/edit",
				name: "ICECLOUD.INVOICES_EDIT",
				icon: Receipt,
				component: EditInvoice,
				layout: "/admin",
				hidden: true,
				license: License.ICECLOUD,
				permissions: [
					Permissions.ICECLOUD_INVOICES_MODERATE,
				],
			},
			{
				path: "/icecloud/invoices",
				name: "ICECLOUD.INVOICES",
				icon: Receipt,
				component: Invoices,
				layout: "/admin",
				hidden: false,
				license: License.ICECLOUD,
				permissions: [
					Permissions.ICECLOUD_INVOICES_VIEW,
					Permissions.ICECLOUD_INVOICES_MODERATE,
				],
			},
			{
				path: "/icecloud/giftcard/edit/:id",
				matchPath: "/icecloud/giftcard/edit",
				name: "ICECLOUD.GIFTCARD_EDIT",
				icon: CardGiftcardIcon,
				component: EditGiftCard,
				layout: "/admin",
				hidden: true,
				license: License.ICECLOUD,
				permissions: [
					Permissions.ICECLOUD_GIFT_CARDS_BASIC,
					Permissions.ICECLOUD_GIFT_CARDS_ADMIN,
				],
			},
			{
				path: "/icecloud/giftcard",
				name: "ICECLOUD.GIFTCARD",
				icon: CardGiftcardIcon,
				component: GiftCardTransactions,
				layout: "/admin",
				hidden: false,
				license: License.ICECLOUD,
				permissions: [
					Permissions.ICECLOUD_GIFT_CARDS_ADMIN,
				],
			},
		/*{
				path: "/icecloud/trusteddevices",
				name: "ICECLOUD.TRUSTED_DEVICES",
				icon: PhonelinkLockIcon,
				component: TrustedDevices,
				layout: "/admin",
				hidden: false,
				license: License.ICECLOUD,
				permissions: [
					Permissions.ICECLOUD_TRUSTED_DEVICE_VIEW,
					Permissions.ICECLOUD_TRUSTED_DEVICE_MODERATE,
				],
			},*/
			{
				path: "/icecloud/supplier/create",
				name: "ICECLOUD.SUPPLIERS.CREATE",
				icon: FastfoodIcon,
				component: CreateSupplier,
				layout: "/admin",
				hidden: true,
				license: License.ICECLOUD,
				permissions: [
					Permissions.ICECLOUD_SUPPLIER_MODERATE,
				],
			},
			{
				path: "/icecloud/supplier/edit/:id",
				matchPath: "/icecloud/supplier/edit",
				name: "ICECLOUD.SUPPLIERS.EDIT",
				icon: FastfoodIcon,
				component: EditSupplier,
				layout: "/admin",
				hidden: true,
				license: License.ICECLOUD,
				permissions: [
					Permissions.ICECLOUD_SUPPLIER_MODERATE,
				],
			},
			{
				path: "/icecloud/supplier/view/:id",
				matchPath: "/icecloud/supplier/view",
				name: "ICECLOUD.SUPPLIERS.VIEW",
				icon: FastfoodIcon,
				component: EditSupplier,
				layout: "/admin",
				hidden: true,
				license: License.ICECLOUD,
				permissions: [
					Permissions.ICECLOUD_SUPPLIER_VIEW,
				],
			},
			{
				path: "/icecloud/supplier",
				name: "ICECLOUD.SUPPLIERS",
				icon: FastfoodIcon,
				component: Suppliers,
				layout: "/admin",
				hidden: false,
				license: License.ICECLOUD,
				permissions: [
					Permissions.ICECLOUD_SUPPLIER_VIEW,
					Permissions.ICECLOUD_SUPPLIER_MODERATE,
				],
			},
			{
				path: "/icecloud/supplierorder/create",
				name: "ICECLOUD.SUPPLIER_ORDERS.CREATE",
				icon: FastfoodIcon,
				component: CreateSupplierOrder,
				layout: "/admin",
				hidden: true,
				license: License.ICECLOUD,
				permissions: [
					Permissions.ICECLOUD_SUPPLIER_ORDER_BASIC,
					Permissions.ICECLOUD_SUPPLIER_ORDER_EXTENDED,
					Permissions.ICECLOUD_SUPPLIER_ORDER_ADMIN,
				],
			},
			{
				path: "/icecloud/supplierorder/edit/:id",
				matchPath: "/icecloud/supplierorder/edit",
				name: "ICECLOUD.SUPPLIER_ORDERS.EDIT",
				icon: FastfoodIcon,
				component: EditSupplierOrder,
				layout: "/admin",
				hidden: true,
				license: License.ICECLOUD,
				permissions: [
					Permissions.ICECLOUD_SUPPLIER_ORDER_BASIC,
					Permissions.ICECLOUD_SUPPLIER_ORDER_EXTENDED,
					Permissions.ICECLOUD_SUPPLIER_ORDER_ADMIN,
				],
			},
			{
				path: "/icecloud/supplierorder/view/:id",
				matchPath: "/icecloud/supplierorder/view",
				name: "ICECLOUD.SUPPLIER_ORDERS.VIEW",
				icon: FastfoodIcon,
				component: EditSupplierOrder,
				layout: "/admin",
				hidden: true,
				license: License.ICECLOUD,
				permissions: [
					Permissions.ICECLOUD_SUPPLIER_ORDER_BASIC,
					Permissions.ICECLOUD_SUPPLIER_ORDER_EXTENDED,
					Permissions.ICECLOUD_SUPPLIER_ORDER_ADMIN,
				],
			},
			{
				path: "/icecloud/supplierorder",
				name: "ICECLOUD.SUPPLIER_ORDERS",
				icon: FastfoodIcon,
				component: SupplierOrders,
				layout: "/admin",
				hidden: false,
				license: License.ICECLOUD,
				permissions: [
					Permissions.ICECLOUD_SUPPLIER_ORDER_BASIC,
					Permissions.ICECLOUD_SUPPLIER_ORDER_EXTENDED,
					Permissions.ICECLOUD_SUPPLIER_ORDER_ADMIN,
				],
			},
		]),
	},
	{
		collapse: true,
		name: "ICESHOP",
		icon: StorefrontIcon,
		license: License.ICESHOP,
		state: "iceshopCollapse",
		views: getViews(License.ICESHOP).concat([
			{
				path: "/iceshop/supplieditems",
				name: "ICESHOP.SUPPLIED_ITEMS",
				icon: FastfoodIcon,
				component: SuppliedItems,
				layout: "/admin",
				hidden: false,
				license: License.ICESHOP,
				permissions: [
					Permissions.ICESHOP_SUPPLIED_ITEMS_VIEW,
					Permissions.ICESHOP_SUPPLIED_ITEMS_MODERATE,
				],
			},
			{
				path: "/iceshop/dailyreport",
				name: "ICESHOP.DAILY_REPORT",
				icon: Assessment,
				component: DailyReport,
				layout: "/admin",
				hidden: false,
				license: License.ICESHOP,
				permissions: [
					Permissions.ICESHOP_DAILY_REPORT_VIEW,
				],
			},
			{
				path: "/iceshop/webshoporders/create",
				matchPath: "/iceshop/webshoporders/create",
				name: "ICESHOP.CREATE_WEBSHOP_ORDER",
				icon: ShoppingBasketIcon,
				component: CreateWebshopOrder,
				layout: "/admin",
				hidden: true,
				license: License.ICESHOP,
				permissions: [
					Permissions.ICESHOP_WEBSHOP_ORDER_MODERATE,
				],
			},
			{
				path: "/iceshop/webshoporders/edit/:id",
				matchPath: "/iceshop/webshoporders/edit",
				name: "ICESHOP.EDIT_WEBSHOP_ORDER",
				icon: ShoppingBasketIcon,
				component: EditWebshopOrder,
				layout: "/admin",
				hidden: true,
				license: License.ICESHOP,
				permissions: [
					Permissions.ICESHOP_WEBSHOP_ORDER_MODERATE,
				],
			},
			{
				path: "/iceshop/webshoporders",
				name: "ICESHOP.WEBSHOP_ORDERS",
				icon: ShoppingBasketIcon,
				component: WebshopOrders,
				layout: "/admin",
				hidden: false,
				license: License.ICESHOP,
				permissions: [
					Permissions.ICESHOP_WEBSHOP_ORDER_VIEW,
					Permissions.ICESHOP_WEBSHOP_ORDER_MODERATE,
					Permissions.ICESHOP_WEBSHOP_ORDER_EDIT_STATUS,
				],
			},
			{
				path: "/iceshop/worksheet",
				name: "ICESHOP.WORKSHEET",
				icon: GridOn,
				component: Worksheet,
				layout: "/admin",
				hidden: false,
				license: License.ICESHOP,
				permissions: [
					Permissions.ICESHOP_WORKSHEET_VIEW,
				],
			},
			{
				path: "/iceshop/product/create",
				name: "ICESHOP.PRODUCTS.CREATE",
				icon: MenuBookIcon,
				component: CreateProduct,
				layout: "/admin",
				hidden: true,
				license: License.ICESHOP,
				permissions: [
					Permissions.ICESHOP_PRODUCT_MODERATE,
				],
			},
			{
				path: "/iceshop/product/edit/:id",
				matchPath: "/iceshop/product/edit",
				name: "ICESHOP.PRODUCTS.EDIT",
				icon: MenuBookIcon,
				component: EditProduct,
				layout: "/admin",
				hidden: true,
				license: License.ICESHOP,
				permissions: [
					Permissions.ICESHOP_PRODUCT_MODERATE,
				],
			},
			{
				path: "/iceshop/product/view/:id",
				matchPath: "/iceshop/product/view",
				name: "ICESHOP.PRODUCTS.VIEW",
				icon: MenuBookIcon,
				component: EditProduct,
				layout: "/admin",
				hidden: true,
				license: License.ICESHOP,
				permissions: [
					Permissions.ICESHOP_PRODUCT_VIEW,
				],
			},
			{
				path: "/iceshop/product",
				name: "ICESHOP.PRODUCTS",
				icon: MenuBookIcon,
				component: Products,
				layout: "/admin",
				hidden: false,
				license: License.ICESHOP,
				permissions: [
					Permissions.ICESHOP_PRODUCT_VIEW,
					Permissions.ICESHOP_PRODUCT_MODERATE,
				],
			},
		/*{
				path: "/iceshop/tablet/create",
				name: "ICESHOP.TABLETS_CREATE",
				icon: DevicesIcon,
				component: CreateTablet,
				layout: "/admin",
				hidden: true,
				license: License.ICESHOP,
				permissions: [
					Permissions.ICESHOP_TABLETS_MODERATE
				],
			},*/
		/*{
				path: "/iceshop/tablet/edit/:id",
				matchPath: "/iceshop/tablet/edit",
				name: "ICESHOP.TABLETS_EDIT",
				icon: DevicesIcon,
				component: EditTablet,
				layout: "/admin",
				hidden: true,
				license: License.ICESHOP,
				permissions: [
					Permissions.ICESHOP_TABLETS_MODERATE
				],
			},*/
		/*{
				path: "/iceshop/tablet/view/:id",
				matchPath: "/iceshop/tablet/view",
				name: "ICESHOP.TABLETS_VIEW",
				icon: DevicesIcon,
				component: EditTablet,
				layout: "/admin",
				hidden: true,
				license: License.ICESHOP,
				permissions: [
					Permissions.ICESHOP_TABLETS_VIEW
				],
			},*/
		/*{
				path: "/iceshop/tablet",
				name: "ICESHOP.TABLETS",
				icon: DevicesIcon,
				component: Tablets,
				layout: "/admin",
				hidden: false,
				license: License.ICESHOP,
				permissions: [
					Permissions.ICESHOP_TABLETS_VIEW,
					Permissions.ICESHOP_TABLETS_MODERATE
				],
			},*/
		/*{
				path: "/iceshop/deliveryvan/create",
				name: "ICESHOP.DELIVERY_VANS_CREATE",
				icon: LocalShipping,
				component: CreateDeliveryVan,
				layout: "/admin",
				hidden: true,
				license: License.ICESHOP,
				permissions: [
					Permissions.ICESHOP_DELIVERY_VANS_MODERATE
				],
			},*/
		/*{
				path: "/iceshop/deliveryvan/edit/:id",
				matchPath: "/iceshop/deliveryvan/edit",
				name: "ICESHOP.DELIVERY_VANS_EDIT",
				icon: LocalShipping,
				component: EditDeliveryVan,
				layout: "/admin",
				hidden: true,
				license: License.ICESHOP,
				permissions: [
					Permissions.ICESHOP_DELIVERY_VANS_MODERATE
				],
			},*/
		/*{
				path: "/iceshop/deliveryvan/view/:id",
				matchPath: "/iceshop/deliveryvan/view",
				name: "ICESHOP.DELIVERY_VANS_VIEW",
				icon: LocalShipping,
				component: EditDeliveryVan,
				layout: "/admin",
				hidden: true,
				license: License.ICESHOP,
				permissions: [
					Permissions.ICESHOP_DELIVERY_VANS_VIEW
				],
			},*/
		/*{
				path: "/iceshop/deliveryvan",
				name: "ICESHOP.DELIVERY_VANS",
				icon: LocalShipping,
				component: DeliveryVans,
				layout: "/admin",
				hidden: false,
				license: License.ICESHOP,
				permissions: [
					Permissions.ICESHOP_DELIVERY_VANS_VIEW,
					Permissions.ICESHOP_DELIVERY_VANS_MODERATE
				],
			},*/
			{
				path: "/iceshop/deliveryschedules",
				name: "ICESHOP.DELIVERY_SCHEDULES",
				icon: EventNote,
				component: DeliverySchedules,
				layout: "/admin",
				hidden: false,
				license: License.ICESHOP,
				permissions: [
					Permissions.ICESHOP_DELIVERY_SCHEDULES_VIEW,
					Permissions.ICESHOP_DELIVERY_SCHEDULES_MODERATE,
				],
			},
			{
				path: "/iceshop/webshopaccount/create",
				name: "ICESHOP.WEBSHOP_ACCOUNTS.CREATE",
				icon: AccountBoxIcon,
				component: CreateWebshopAccount,
				layout: "/admin",
				hidden: true,
				license: License.ICESHOP,
				permissions: [
					Permissions.ICESHOP_WEBSHOP_ACCOUNTS_MODERATE,
				],
			},
			{
				path: "/iceshop/webshopaccount/edit/:id",
				matchPath: "/iceshop/webshopaccount/edit",
				name: "ICESHOP.WEBSHOP_ACCOUNTS.EDIT",
				icon: AccountBoxIcon,
				component: EditWebshopAccount,
				layout: "/admin",
				hidden: true,
				license: License.ICESHOP,
				permissions: [
					Permissions.ICESHOP_WEBSHOP_ACCOUNTS_MODERATE,
					Permissions.ICESHOP_WEBSHOP_ACCOUNT_ADDRESSES_MODERATE,
				],
			},
			{
				path: "/iceshop/webshopaccount/view/:id",
				matchPath: "/iceshop/webshopaccount/view",
				name: "ICESHOP.WEBSHOP_ACCOUNTS.VIEW",
				icon: AccountBoxIcon,
				component: EditWebshopAccount,
				layout: "/admin",
				hidden: true,
				license: License.ICESHOP,
				permissions: [
					Permissions.ICESHOP_WEBSHOP_ACCOUNTS_VIEW,
					Permissions.ICESHOP_WEBSHOP_ACCOUNT_ADDRESSES_VIEW,
					Permissions.ICESHOP_WEBSHOP_ACCOUNT_ADDRESSES_MODERATE,
				],
			},
			{
				path: "/iceshop/webshopaccount",
				name: "ICESHOP.WEBSHOP_ACCOUNTS",
				icon: AccountBoxIcon,
				component: WebshopAccounts,
				layout: "/admin",
				hidden: false,
				license: License.ICESHOP,
				permissions: [
					Permissions.ICESHOP_WEBSHOP_ACCOUNTS_LOGIN,
					Permissions.ICESHOP_WEBSHOP_ACCOUNTS_VIEW,
					Permissions.ICESHOP_WEBSHOP_ACCOUNTS_MODERATE,
					Permissions.ICESHOP_WEBSHOP_ACCOUNT_ADDRESSES_VIEW,
					Permissions.ICESHOP_WEBSHOP_ACCOUNT_ADDRESSES_MODERATE,
				],
			},
			{
				path: "/iceshop/discount/create",
				name: "ICESHOP.DISCOUNTS.CREATE",
				icon: MoneyOffIcon,
				component: CreateDiscount,
				layout: "/admin",
				hidden: true,
				license: License.ICESHOP,
				permissions: [
					Permissions.ICESHOP_DISCOUNTS_MODERATE,
				],
			},
			{
				path: "/iceshop/discount/edit/:id",
				matchPath: "/iceshop/discount/edit",
				name: "ICESHOP.DISCOUNTS.EDIT",
				icon: MoneyOffIcon,
				component: EditDiscount,
				layout: "/admin",
				hidden: true,
				license: License.ICESHOP,
				permissions: [
					Permissions.ICESHOP_DISCOUNTS_MODERATE,
				],
			},
			{
				path: "/iceshop/discount/view/:id",
				matchPath: "/iceshop/discount/view",
				name: "ICESHOP.DISCOUNTS.VIEW",
				icon: MoneyOffIcon,
				component: EditDiscount,
				layout: "/admin",
				hidden: true,
				license: License.ICESHOP,
				permissions: [
					Permissions.ICESHOP_DISCOUNTS_VIEW,
				],
			},
			{
				path: "/iceshop/discount",
				name: "ICESHOP.DISCOUNTS",
				icon: MoneyOffIcon,
				component: Discounts,
				layout: "/admin",
				hidden: false,
				license: License.ICESHOP,
				permissions: [
					Permissions.ICESHOP_DISCOUNTS_VIEW,
					Permissions.ICESHOP_DISCOUNTS_MODERATE,
				],
			},
		/*{
				path: "/iceshop/pickuppoint/create",
				name: "ICESHOP.PICK_UP_POINTS.CREATE",
				icon: StoreIcon,
				component: CreatePickUpPoint,
				layout: "/admin",
				hidden: true,
				license: License.ICESHOP,
				permissions: [
					Permissions.ICESHOP_PICK_UP_POINTS_MODERATE
				],
			},*/
		/*{
				path: "/iceshop/pickuppoint/edit/:id",
				matchPath: "/iceshop/pickuppoint/edit",
				name: "ICESHOP.PICK_UP_POINTS.EDIT",
				icon: StoreIcon,
				component: EditPickUpPoint,
				layout: "/admin",
				hidden: true,
				license: License.ICESHOP,
				permissions: [
					Permissions.ICESHOP_PICK_UP_POINTS_MODERATE
				],
			},*/
		/*{
				path: "/iceshop/pickuppoint/view/:id",
				matchPath: "/iceshop/pickuppoint/view",
				name: "ICESHOP.PICK_UP_POINTS.VIEW",
				icon: StoreIcon,
				component: EditPickUpPoint,
				layout: "/admin",
				hidden: true,
				license: License.ICESHOP,
				permissions: [
					Permissions.ICESHOP_PICK_UP_POINTS_VIEW
				],
			},*/
		/*{
				path: "/iceshop/pickuppoint",
				name: "ICESHOP.PICK_UP_POINTS",
				icon: StoreIcon,
				component: PickUpPoints,
				layout: "/admin",
				hidden: false,
				license: License.ICESHOP,
				permissions: [
					Permissions.ICESHOP_PICK_UP_POINTS_VIEW,
					Permissions.ICESHOP_PICK_UP_POINTS_MODERATE
				],
			},*/
		/*{
				path: "/iceshop/deliveryzone/create",
				name: "ICESHOP.DELIVERY_ZONES.CREATE",
				icon: LocalShippingIcon,
				component: CreateDeliveryZone,
				layout: "/admin",
				hidden: true,
				license: License.ICESHOP,
				permissions: [
					Permissions.ICESHOP_DELIVERY_ZONES_MODERATE
				],
			},*/
		/*{
				path: "/iceshop/deliveryzone/edit/:id",
				matchPath: "/iceshop/deliveryzone/edit",
				name: "ICESHOP.DELIVERY_ZONES.EDIT",
				icon: LocalShippingIcon,
				component: EditDeliveryZone,
				layout: "/admin",
				hidden: true,
				license: License.ICESHOP,
				permissions: [
					Permissions.ICESHOP_DELIVERY_ZONES_MODERATE
				],
			},*/
		/*{
				path: "/iceshop/deliveryzone/view/:id",
				matchPath: "/iceshop/deliveryzone/view",
				name: "ICESHOP.DELIVERY_ZONES.VIEW",
				icon: LocalShippingIcon,
				component: EditDeliveryZone,
				layout: "/admin",
				hidden: true,
				license: License.ICESHOP,
				permissions: [
					Permissions.ICESHOP_DELIVERY_ZONES_VIEW
				],
			},*/
		/*{
				path: "/iceshop/deliveryzone",
				name: "ICESHOP.DELIVERY_ZONES",
				icon: LocalShippingIcon,
				component: DeliveryZones,
				layout: "/admin",
				hidden: false,
				license: License.ICESHOP,
				permissions: [
					Permissions.ICESHOP_DELIVERY_ZONES_VIEW,
					Permissions.ICESHOP_DELIVERY_ZONES_MODERATE
				],
			},*/
		/*{
				path: "/iceshop/shipmentzone/create",
				name: "ICESHOP.SHIPMENT_ZONES.CREATE",
				fa_icon: "fas fa-box-open",
				component: CreateShipmentZone,
				layout: "/admin",
				hidden: true,
				license: License.ICESHOP,
				permissions: [
					Permissions.ICESHOP_SHIPMENT_ZONES_MODERATE
				],
			},*/
		/*{
				path: "/iceshop/shipmentzone/edit/:id",
				matchPath: "/iceshop/shipmentzone/edit",
				name: "ICESHOP.SHIPMENT_ZONES.EDIT",
				fa_icon: "fas fa-box-open",
				component: EditShipmentZone,
				layout: "/admin",
				hidden: true,
				license: License.ICESHOP,
				permissions: [
					Permissions.ICESHOP_SHIPMENT_ZONES_MODERATE
				],
			},*/
		/*{
				path: "/iceshop/shipmentzone/view/:id",
				matchPath: "/iceshop/shipmentzone/view",
				name: "ICESHOP.SHIPMENT_ZONES.VIEW",
				fa_icon: "fas fa-box-open",
				component: EditShipmentZone,
				layout: "/admin",
				hidden: true,
				license: License.ICESHOP,
				permissions: [
					Permissions.ICESHOP_SHIPMENT_ZONES_VIEW
				],
			},*/
		/*{
				path: "/iceshop/shipmentzone",
				name: "ICESHOP.SHIPMENT_ZONES",
				fa_icon: "fas fa-box-open",
				component: ShipmentZones,
				layout: "/admin",
				hidden: false,
				license: License.ICESHOP,
				permissions: [
					Permissions.ICESHOP_SHIPMENT_ZONES_VIEW,
					Permissions.ICESHOP_SHIPMENT_ZONES_MODERATE
				],
			},*/
			{
				path: "/iceshop/damagereport",
				name: "ICESHOP.DAMAGE_REPORTS",
				icon: NotificationImportantIcon,
				component: DamageReports,
				layout: "/admin",
				hidden: false,
				license: License.ICESHOP,
				permissions: [
					Permissions.ICESHOP_DAMAGE_REPORTS_VIEW,
					Permissions.ICESHOP_DAMAGE_REPORTS_MODERATE,
				],
			},
		]),
	},
	{
		collapse: true,
		name: "ICETRUCK",
		icon: LocalShipping,
		license: License.ICETRUCK,
		state: "icetruckCollapse",
		views: getViews(License.ICETRUCK).concat([
			{
				path: "/icetruck/icecreamvanpreferredzone",
				name: "ICETRUCK.ICECREAM_VAN_PREFERRED_ZONE",
				icon: FavoriteIcon,
				component: IcecreamVanPreferredZones,
				layout: "/admin",
				// hidden: true,
				license: License.ICETRUCK,
				permissions: [
					Permissions.ICETRUCK_ICECREAM_VAN_PREFERRED_ZONES_SELF,
				],
			},
			{
				path: "/icetruck/icecreamvanschedules",
				name: "ICETRUCK.ICECREAM_VAN_SCHEDULES",
				icon: EventNote,
				component: IcecreamVanSchedules,
				layout: "/admin",
				hidden: false,
				license: License.ICETRUCK,
				permissions: [
					Permissions.ICETRUCK_ICECREAM_VAN_SCHEDULES_VIEW,
					Permissions.ICETRUCK_ICECREAM_VAN_SCHEDULES_MODERATE,
				],
			},
			{
				path: "/icetruck/icecreamvanreservations/create",
				name: "ICETRUCK.ICECREAM_VAN_RESERVATIONS.CREATE",
				icon: LocalTaxi,
				component: CreateIcecreamVanReservation,
				layout: "/admin",
				hidden: true,
				license: License.ICETRUCK,
				permissions: [
					Permissions.ICETRUCK_ICECREAM_VAN_RESERVATIONS_MODERATE,
				],
			},
			{
				path: "/icetruck/icecreamvanreservations/edit/:id",
				matchPath: "/icetruck/icecreamvanreservations/edit",
				name: "ICETRUCK.ICECREAM_VAN_RESERVATIONS.EDIT",
				icon: LocalTaxi,
				component: EditIcecreamVanReservation,
				layout: "/admin",
				hidden: true,
				license: License.ICETRUCK,
				permissions: [
					Permissions.ICETRUCK_ICECREAM_VAN_RESERVATIONS_MODERATE,
				],
			},
			{
				path: "/icetruck/icecreamvanreservations",
				name: "ICETRUCK.ICECREAM_VAN_RESERVATIONS",
				icon: LocalTaxi,
				component: IcecreamVanReservations,
				layout: "/admin",
				hidden: false,
				license: License.ICETRUCK,
				permissions: [
					Permissions.ICETRUCK_ICECREAM_VAN_RESERVATIONS_VIEW,
					Permissions.ICETRUCK_ICECREAM_VAN_RESERVATIONS_MODERATE,
				],
			},
			{
				path: "/icetruck/dailysalesreport",
				name: "ICETRUCK.DAILY_SALES_REPORT",
				icon: Assessment,
				component: DailySalesReport,
				layout: "/admin",
				hidden: false,
				license: License.ICETRUCK,
				permissions: [
					Permissions.ICETRUCK_DAILY_SALES_REPORT_VIEW,
				],
			},
			{
				path: "/icetruck/map",
				name: "ICETRUCK.MAP",
				icon: MapIcon,
				component: Map,
				layout: "/admin",
				hidden: false,
				license: License.ICETRUCK,
				permissions: [
					Permissions.ICETRUCK_MAP_VIEW,
				],
			},
		/*{
				path: "/icetruck/icecreamvan/create",
				name: "ICETRUCK.ICECREAM_VANS_CREATE",
				icon: IcecreamVanIcon,
				component: CreateIcecreamVan,
				layout: "/admin",
				hidden: true,
				license: License.ICETRUCK,
				permissions: [
					Permissions.ICETRUCK_ICECREAM_VANS_MODERATE
				],
			},*/
		/*{
				path: "/icetruck/icecreamvan/edit/:id",
				matchPath: "/icetruck/icecreamvan/edit",
				name: "ICETRUCK.ICECREAM_VANS_EDIT",
				icon: IcecreamVanIcon,
				component: EditIcecreamVan,
				layout: "/admin",
				hidden: true,
				license: License.ICETRUCK,
				permissions: [
					Permissions.ICETRUCK_ICECREAM_VANS_MODERATE
				],
			},*/
		/*{
				path: "/icetruck/icecreamvan/view/:id",
				matchPath: "/icetruck/icecreamvan/view",
				name: "ICETRUCK.ICECREAM_VANS_VIEW",
				icon: IcecreamVanIcon,
				component: EditIcecreamVan,
				layout: "/admin",
				hidden: true,
				license: License.ICETRUCK,
				permissions: [
					Permissions.ICETRUCK_ICECREAM_VANS_VIEW
				],
			},*/
		/*{
				path: "/icetruck/icecreamvan",
				name: "ICETRUCK.ICECREAM_VANS",
				icon: IcecreamVanIcon,
				component: IcecreamVans,
				layout: "/admin",
				hidden: false,
				license: License.ICETRUCK,
				permissions: [
					Permissions.ICETRUCK_ICECREAM_VANS_VIEW,
					Permissions.ICETRUCK_ICECREAM_VANS_MODERATE
				],
			},*/
			{
				path: "/icetruck/icecreamvanzone/create",
				name: "ICETRUCK.ICECREAM_VAN_ZONES_CREATE",
				icon: ExploreIcon,
				component: CreateIcecreamVanZone,
				layout: "/admin",
				hidden: true,
				license: License.ICETRUCK,
				permissions: [
					Permissions.ICETRUCK_ICECREAM_VAN_ZONES_MODERATE
				],
			},
			{
				path: "/icetruck/icecreamvanzone/edit/:id",
				matchPath: "/icetruck/icecreamvanzone/edit",
				name: "ICETRUCK.ICECREAM_VAN_ZONES_EDIT",
				icon: ExploreIcon,
				component: EditIcecreamVanZone,
				layout: "/admin",
				hidden: true,
				license: License.ICETRUCK,
				permissions: [
					Permissions.ICETRUCK_ICECREAM_VAN_ZONES_MODERATE
				],
			},
			{
				path: "/icetruck/icecreamvanzone/view/:id",
				matchPath: "/icetruck/icecreamvanzone/view",
				name: "ICETRUCK.ICECREAM_VAN_ZONES_VIEW",
				icon: ExploreIcon,
				component: EditIcecreamVanZone,
				layout: "/admin",
				hidden: true,
				license: License.ICETRUCK,
				permissions: [
					Permissions.ICETRUCK_ICECREAM_VAN_ZONES_VIEW
				],
			},
			{
				path: "/icetruck/icecreamvanzone",
				name: "ICETRUCK.ICECREAM_VAN_ZONES",
				icon: ExploreIcon,
				component: IcecreamVanZones,
				layout: "/admin",
				hidden: false,
				license: License.ICETRUCK,
				permissions: [
					Permissions.ICETRUCK_ICECREAM_VAN_ZONES_VIEW,
					Permissions.ICETRUCK_ICECREAM_VAN_ZONES_MODERATE
				],
			},
		/*{
				path: "/icetruck/tablet/create",
				name: "ICETRUCK.TABLETS_CREATE",
				icon: DevicesIcon,
				component: CreateTablet,
				layout: "/admin",
				hidden: true,
				license: License.ICETRUCK,
				permissions: [
					Permissions.ICETRUCK_TABLETS_MODERATE
				],
			},*/
		/*{
				path: "/icetruck/tablet/edit/:id",
				matchPath: "/icetruck/tablet/edit",
				name: "ICETRUCK.TABLETS_EDIT",
				icon: DevicesIcon,
				component: EditTablet,
				layout: "/admin",
				hidden: true,
				license: License.ICETRUCK,
				permissions: [
					Permissions.ICETRUCK_TABLETS_MODERATE
				],
			},*/
		/*{
				path: "/icetruck/tablet/view/:id",
				matchPath: "/icetruck/tablet/view",
				name: "ICETRUCK.TABLETS_VIEW",
				icon: DevicesIcon,
				component: EditTablet,
				layout: "/admin",
				hidden: true,
				license: License.ICETRUCK,
				permissions: [
					Permissions.ICETRUCK_TABLETS_VIEW
				],
			},*/
		/*{
				path: "/icetruck/tablet",
				name: "ICETRUCK.TABLETS",
				icon: DevicesIcon,
				component: Tablets,
				layout: "/admin",
				hidden: false,
				license: License.ICETRUCK,
				permissions: [
					Permissions.ICETRUCK_TABLETS_VIEW,
					Permissions.ICETRUCK_TABLETS_MODERATE
				],
			},*/
			{
				path: "/icetruck/damagereport",
				name: "ICETRUCK.DAMAGE_REPORTS",
				icon: NotificationImportantIcon,
				component: DamageReports,
				layout: "/admin",
				hidden: false,
				license: License.ICETRUCK,
				permissions: [
					Permissions.ICETRUCK_DAMAGE_REPORTS_VIEW,
					Permissions.ICETRUCK_DAMAGE_REPORTS_MODERATE,
				],
			},
		]),
	},
	{
		collapse: true,
		name: "ICECLOCK",
		icon: Alarm,
		license: License.ICECLOCK,
		state: "iceclockCollapse",
		views: getViews(License.ICECLOCK).concat([
			{
				path: "/iceclock/schedules",
				name: "ICECLOCK.SCHEDULES",
				icon: EventNote,
				component: Schedules,
				layout: "/admin",
				hidden: false,
				license: License.ICECLOCK,
				permissions: [
					Permissions.ICECLOCK_SCHEDULES_VIEW,
					Permissions.ICECLOCK_SCHEDULES_MODERATE,
				],
			},
			{
				path: "/iceclock/availabilities",
				name: "ICECLOCK.AVAILABILITIES",
				icon: CalendarToday,
				component: Availabilities,
				layout: "/admin",
				hidden: false,
				license: License.ICECLOCK,
				permissions: [
					Permissions.ICECLOCK_AVAILABILITIES_SELF,
					Permissions.ICECLOCK_AVAILABILITIES_VIEW,
					Permissions.ICECLOCK_AVAILABILITIES_MODERATE,
					Permissions.ICECLOCK_ROSTERS_ASSIGN_SELF,
				],
			},
			{
				path: "/iceclock/availabilitiesreport",
				name: "ICECLOCK.AVAILABILITIES_REPORT",
				icon: CalendarToday,
				component: AvailabilitiesReport,
				layout: "/admin",
				hidden: false,
				license: License.ICECLOCK,
				permissions: [
					Permissions.ICECLOCK_AVAILABILITIES_REPORT_VIEW,
				],
			},
			{
				path: "/iceclock/shifts_overview",
				name: "ICECLOCK.SHIFTS_OVERVIEW",
				icon: WatchLater,
				component: ShiftsOverview,
				layout: "/admin",
				hidden: false,
				license: License.ICECLOCK,
				permissions: [
					Permissions.ICECLOCK_WORKING_DAYS_SELF,
					Permissions.ICECLOCK_WORKING_DAYS_VIEW,
					Permissions.ICECLOCK_WORKING_DAYS_MODERATE,
				],
			},
		/*{
				path: "/iceclock/contracts",
				name: "ICECLOCK.CONTRACTS",
				icon: AssignmentInd,
				component: Contracts,
				layout: "/admin",
				hidden: false,
				license: License.ICECLOCK,
				permissions: [
					Permissions.ICECLOCK_CONTRACTS_VIEW,
					Permissions.ICECLOCK_CONTRACTS_EDIT_NOT_VALIDATED,
					Permissions.ICECLOCK_CONTRACTS_MODERATE,
				],
			},*/

		/*{
				path: "/iceclock/contracttemplate/create",
				name: "ICECLOCK.CONTRACT_TEMPLATES.CREATE",
				icon: BookmarksIcon,
				component: CreateContractTemplate,
				layout: "/admin",
				hidden: true,
				license: License.ICECLOCK,
				permissions: [
					Permissions.ICECLOCK_CONTRACT_TEMPLATES_MODERATE
				],
			},*/
		/*{
				path: "/iceclock/contracttemplate/edit/:id",
				matchPath: "/iceclock/contracttemplate/edit",
				name: "ICECLOCK.CONTRACT_TEMPLATES.EDIT",
				icon: BookmarksIcon,
				component: EditContractTemplate,
				layout: "/admin",
				hidden: true,
				license: License.ICECLOCK,
				permissions: [
					Permissions.ICECLOCK_CONTRACT_TEMPLATES_MODERATE
				],
			},*/
		/*{
				path: "/iceclock/contracttemplate/view/:id",
				matchPath: "/iceclock/contracttemplate/view",
				name: "ICECLOCK.CONTRACT_TEMPLATES.VIEW",
				icon: BookmarksIcon,
				component: EditContractTemplate,
				layout: "/admin",
				hidden: true,
				license: License.ICECLOCK,
				permissions: [
					Permissions.ICECLOCK_CONTRACT_TEMPLATES_VIEW
				],
			},*/
		/*{
				path: "/iceclock/contracttemplate",
				name: "ICECLOCK.CONTRACT_TEMPLATES",
				icon: BookmarksIcon,
				component: ContractTemplates,
				layout: "/admin",
				hidden: false,
				license: License.ICECLOCK,
				permissions: [
					Permissions.ICECLOCK_CONTRACT_TEMPLATES_VIEW,
					Permissions.ICECLOCK_CONTRACT_TEMPLATES_MODERATE
				],
			},*/
		]),
	},
	{
		collapse: true,
		name: "ICECASH",
		icon: LocalAtmIcon,
		license: License.ICECASH,
		state: "icecashCollapse",
		views: getViews(License.ICECASH).concat([
		/*{
				path: "/icecash/menu/create",
				name: "ICECASH.MENUS.CREATE",
				icon: MenuBookIcon,
				component: CreateMenu,
				layout: "/admin",
				hidden: true,
				license: License.ICECASH,
				permissions: [
					Permissions.ICECASH_MENU_MODERATE
				],
			},*/
		/*{
				path: "/icecash/menu/edit/:id",
				matchPath: "/icecash/menu/edit",
				name: "ICECASH.MENUS.EDIT",
				icon: MenuBookIcon,
				component: EditMenu,
				layout: "/admin",
				hidden: true,
				license: License.ICECASH,
				permissions: [
					Permissions.ICECASH_MENU_MODERATE
				],
			},*/
		/*{
				path: "/icecash/menu/view/:id",
				matchPath: "/icecash/menu/view",
				name: "ICECASH.MENUS.VIEW",
				icon: MenuBookIcon,
				component: EditMenu,
				layout: "/admin",
				hidden: true,
				license: License.ICECASH,
				permissions: [
					Permissions.ICECASH_MENU_VIEW
				],
			},*/
		/*{
				path: "/icecash/menu",
				name: "ICECASH.MENUS",
				icon: MenuBookIcon,
				component: Menus,
				layout: "/admin",
				hidden: false,
				license: License.ICECASH,
				permissions: [
					Permissions.ICECASH_MENU_VIEW,
					Permissions.ICECASH_MENU_MODERATE
				],
			},*/
		]),
	},
	{
		collapse: true,
		name: "ICEGUARD",
		icon: PolicyIcon,
		license: License.ICEGUARD,
		state: "iceguardCollapse",
		views: getViews(License.ICEGUARD).concat([
			{
				path: "/iceguard/metric/history/:id",
				matchPath: "/iceguard/metric/history",
				name: "ICEGUARD.METRIC_HISTORY",
				icon: HistoryIcon,
				component: MetricHistory,
				layout: "/admin",
				hidden: true,
				license: License.ICEGUARD,
				permissions: [
				],
			},
		]),
	},
	{
		path: "/legacy/intranet",
		name: "LEGACYINTRANET",
		icon: History,
		component: LegacyIntranet,
		layout: "/admin",
		hidden: false,
		license: License.LEGACYINTRANET,
		permissions: [
			Permissions.LEGACYINTRANET_USE,
		],
	},
];
export default dashRoutes;
