import * as actionType from './LayersActionType';

const initialState = {
  trackedObject: null,
  trackedLocation: null,
  blipHeightFillingDiv: false,
}

const I18nReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.SET_TRACKED_OBJECT:
      return {...state, trackedObject: action.payload};
    case actionType.CLEAR_TRACKED_OBJECT:
      return {...state, trackedObject: null};
    case actionType.SET_TRACKED_LOCATION:
      return {...state, trackedLocation: action.payload};
    case actionType.SET_BLIP_HEIGHT_FILLING_DIV:
      return {...state, blipHeightFillingDiv: action.payload};
    default:
      return state
  }
}

export default I18nReducer;