import React, {Component} from "react"
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from "react-intl";
import { Redirect, Link } from "react-router-dom";
import {store} from "index";
import { setToken, setAuthScreen, setRedirectFrom } from "Redux/AuthActions";
import { setLocale, clearOverrideIntl, setOverrideIntl } from "Redux/I18nActions";
import API from 'api';
import { setNavbarNotifications } from "Redux/NotificationsActions";
import { updateNavbarNotifications, legacyLocaleToMoment, parseJwt, randomString } from "utils";
import queryString from 'query-string';

// @material-ui/core components
import { withStyles } from '@material-ui/core/styles';
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CircularIndeterminate from 'components/CircularIndeterminate/CircularIndeterminate.js';
import SnackbarContent from "components/Snackbar/SnackbarContent.js";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

const i18nBase = "PAGES.SCREEN_PAGE.";
class ScreenPage extends Component {
	constructor(props) {
		super(props);

		let params = queryString.parse(this.props.location.search);

		const public_code = (params.public_code ?? localStorage.getItem("public_code") ?? process.env.REACT_APP_DEFAULT_PUBLIC_CODE ?? randomString(8, "0123456789ABCDEF"));
		const private_code = (params.private_code ?? localStorage.getItem("private_code") ?? process.env.REACT_APP_DEFAULT_PRIVATE_CODE ?? randomString(32));
		
		this.state = {
			cardAnimaton: "cardHidden",
			busyLoading: false,
			public_code: public_code,
			private_code: private_code,
			errorMessage: null,

			redirectToSetup: false,
		};

		this.handleChange =this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.getAuthScreen = this.getAuthScreen.bind(this);
		this.onResetPasswordClicked = this.onResetPasswordClicked.bind(this);
	}

	componentDidMount() {
		const that = this;

		setTimeout(function() {
			that.setState({
				cardAnimaton: "",
			});

			that.submitInterval = setInterval(that.handleSubmit, 5000);
			that.handleSubmit();
		}, 700);
	}

	componentWillUnmount() {
		if (this.submitInterval) clearInterval(this.submitInterval);
	}

	async handleChange(event) {
		const {name, value, type, checked} = event.target;
		const target = event.target;
		let cursor = -1;
		
		if (target.setSelectionRange &&
				(
						type === 'text' ||
						type === 'search' ||
						type === 'password' ||
						type === 'url' ||
						type === 'tel'
				)
		) {
				cursor = ('selectionStart' in event.target ? (event.target.selectionStart ?? -1) : -1);
		}
		await (type === "checkbox" ? this.setState({ [name]: checked, errorMessage: null, }) : this.setState({ [name]: value, errorMessage: null, }));
		if (cursor != -1 && target.setSelectionRange) {
				target.setSelectionRange(cursor, cursor);
		}
		if (this.state.continuesValidation) {
				this.validateForm(false);
		}
	}

	handleSubmit(event) {
		const that = this;
		const { classes, intl } = this.props;
		if (event) event.preventDefault();

		that.setState({
			busyLoading: true,
		});

		API.post("screenauth/login", {
			"public_code": this.state.public_code,
			"private_code": this.state.private_code,
		}).then(res => {
			localStorage.setItem("public_code", that.state.public_code);
			localStorage.setItem("private_code", that.state.private_code);

			const data = res.data;
			const jwt = parseJwt(data.access_token);
			store.dispatch(setToken(data.access_token, jwt.exp, data.expires_in));
			that.getAuthScreen();
		}).catch(function (error) {
			console.log(error);

			that.setState({
				busyLoading: false,
				// errorMessage: intl.formatMessage({id: i18nBase+"PASSWORD_OR_EMAIL_INCORRECT"}),
			});
		}).finally(function () {
			// always executed
		});
	}

	getAuthScreen() {
		const that = this;
		const { classes, intl } = this.props;

		that.setState({
			busyLoading: true,
		});

		API.get("screenauth/screen").then(res => {
			const data = res.data.data;

			store.dispatch(clearOverrideIntl());
			fetch(process.env.REACT_APP_OVERRIDE_INTL_BASEHREF+(data.company_id)+"-"+(data.default_locale)+".json")
				.then((res) => res.json())
				.then((overrideMessages) => {
					console.log(overrideMessages);
					store.dispatch(setOverrideIntl(overrideMessages));
					
					store.dispatch(setAuthScreen(data));

					const localeImport = import("moment/locale/"+legacyLocaleToMoment(data.default_locale)).then(() => {store.dispatch(setLocale(data.default_locale));});
					that.setState({
						redirectToSetup: data.default_path ?? true,
					});
				})
				.catch((error) => {
					console.error(error);

					store.dispatch(setAuthScreen(data));

					const localeImport = import("moment/locale/"+legacyLocaleToMoment(data.default_locale)).then(() => {store.dispatch(setLocale(data.default_locale));});
					that.setState({
						redirectToSetup: data.default_path ?? true,
					});
				}
			);
		}).catch(function (error) {
			console.log(error);
			that.setState({
				busyLoading: false,
				// errorMessage: intl.formatMessage({id: i18nBase+"PASSWORD_OR_EMAIL_INCORRECT"}),
			});
		}).finally(function () {
			// always executed
		});
	}

	onResetPasswordClicked() {
		this.setState({
			redirectToResetPassword: true,
		});
	}

	render() {
		const { classes, intl } = this.props;

		if (this.state.redirectToSetup) {
			store.dispatch(setRedirectFrom(null));
			return <Redirect push to={ (this.state.redirectToSetup === true ? '/screen/setup' : this.state.redirectToSetup) } />
		}

		return (
			<div className={classes.container}>
				<GridContainer justify="center">
					<GridItem xs={12} sm={6} md={4}>
						<GridContainer>
							<GridItem xs={12}>
								<form onSubmit={this.handleSubmit}>
									<Card login className={classes[this.state.cardAnimaton]}>
										<CardBody>
											<h1 style={{textAlign: "center"}}>{this.state.public_code}</h1>
										</CardBody>
										<CardFooter className={classes.justifyContentCenter}>
											<FormattedMessage id={i18nBase+"EXPLAIN"} />
										</CardFooter>
									</Card>
								</form>
							</GridItem>
						</GridContainer>
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		authScreen: state.auth.authScreen ?? null,
	}
}

export default injectIntl(withStyles(styles, { withTheme: true })(connect(mapStateToProps)(ScreenPage)));