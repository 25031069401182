import React from 'react';
import PandoraModel from "traits/PandoraModel";
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import * as License from "license.js";
import * as Permissions from "permission.js";

class WageAgreement extends PandoraModel {
	static modelName = "WageAgreement";
	static getLicense() { return License.ICECLOUD}

	static icon = AssignmentIndIcon;

	// static orderable = true;
	// static copyable = true;
	// static loadBeforeCopy = false;
	static hasCrudPages = false;
	static crudAsNotification = true;
	// static editableOnTable = true;

	// PANDORA_ANCHOR_INSERT_OVERRIDE_VARIABLES_HERE

	static cards = [
		{
			title: "GENERAL_INFO",
			icon: AssignmentIndIcon,
			fields: {
				// PANDORA_ANCHOR_INSERT_FIELDS_HERE
				employee_id: { type: "hidden", },
				division: { type: "hidden", },
				division_id: { type: "division", showOnTable: true, valueOnTable: (resource) => resource.division.name + " (" + resource.division.establishment_unit.name + ")", required: true, },
				function: { type: "text", md: 8, maxLength: "255", required: true, showOnTable: true, },
				dimona: { type: "select", md: 4, options: ["STU", "FLX", "EXT", "DWD", "BCW", "IVT", "RTA", "TRI", "A17", "TEA", "PMP", "OTH"], required: true, showOnTable: true, },
			},
		},
		{
			title: "CONTRACT_INFO",
			icon: FiberNewIcon,
			fields: {
				// PANDORA_ANCHOR_INSERT_FIELDS_HERE
				contract_template: { type: "hidden", },
				contract_template_id: { type: "contractTemplate", md: 8, },
				validate_contract: { type: "checkbox", required: false, md: 4, visible: (state) => state.contract_template_id, },
				legal_document_template: { type: "hidden", },
				legal_document_template_id: { type: "contractTemplate", md: 8, },
				validate_legal_document: { type: "checkbox", required: false, md: 4, visible: (state) => state.legal_document_template_id, },
			},
		},
		{
			title: "FISCAL_INFO",
			icon: FiberNewIcon,
			fields: {
				// PANDORA_ANCHOR_INSERT_FIELDS_HERE
				workers_statute: { type: "select", options: ["BLUE_COLLAR", "WHITE_COLLAR"], required: true, md: 6, },
				category: { type: "select", options: [1, 2, 3, 4, 5, 6, 7, 8, 9], required: true, md: 2, },
				override_wage: { type: "number", step: "0.0000000001", required: false, md: 4, },
				benefits: { type: "text", maxLength: "255", required: false, },
			},
		},
		// {
		// 	title: "GENERAL_INFO",
		// 	icon: FiberNewIcon,
		// 	fields: {
		//
		// 	},
		// },
		
		// {
		// 	title: "GENERAL_INFO",
		// 	icon: FiberNewIcon,
		// 	fields: {
		// 		// text: {type: "text", required: true, },
		// 		// textarea: {type: "text", multiline: true, rows: 12, required: true, },
		// 		// number: {type: "number", required: true, inputProps: {min: 0, max: 99999, step: 1}, },
		// 		// select: {type: "select", required: true, options: ["OPTION"], },
		// 		// checkbox: {type: "checkbox", },
				
		// 		// md12: {type: "text", md: 12, },
		// 		// showOnTable: {type: "text", showOnTable: true, },
		// 	},
		// },

	];

	// PANDORA_ANCHOR_INSERT_OVERRIDE_FUNCTIONS_HERE
	// static getCopyableChildren() { return [WageAgreementItem]; };
}

export default WageAgreement;