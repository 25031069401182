import React, {Component} from "react"
import {store} from "index";
import { Redirect, Link } from "react-router-dom";
import { setToken, setAuthEmployee, clearAuth } from "Redux/AuthActions";
import { setLocale, clearOverrideIntl, setOverrideIntl } from "Redux/I18nActions";
import API from 'api';
import { setNavbarNotifications } from "Redux/NotificationsActions";
import { updateNavbarNotifications, legacyLocaleToMoment, parseJwt } from "utils";

class SwitchPage extends React.Component {
		constructor(props) {
				super(props);
				this.state = {
					redirectToDashboard: false,
					redirectToLogout: false,
					redirectToLogin: false,
				};

				this.getAuthEmployee = this.getAuthEmployee.bind(this);
		}
		
		async componentDidMount() {
			const that = this;
			API.post("auth/switch", { 
				"employee_id": this.props.match.params.id,
			}).then(res => {
				const data = res.data;
				const jwt = parseJwt(data.access_token);
				store.dispatch(setToken(data.access_token, jwt.exp, data.expires_in));
				that.getAuthEmployee();
			}).catch(function (error) {
				console.log(error);
				store.dispatch(clearAuth());
				that.setState({
						redirectToLogin: true,
				});
			}).finally(function () {
				// always executed
			});
		}

		getAuthEmployee() {
			const that = this;
			const { classes, intl } = this.props;

			that.setState({
				busyLoading: true,
			});

			API.get("auth/employee").then(res => {
				const data = res.data.data;

				store.dispatch(clearOverrideIntl());
				fetch(process.env.REACT_APP_OVERRIDE_INTL_BASEHREF+(data.company_id)+"-"+(data.user.default_locale)+".json")
					.then((res) => res.json())
					.then((overrideMessages) => {
						console.log(overrideMessages);
						store.dispatch(setOverrideIntl(overrideMessages));
						
						store.dispatch(setAuthEmployee(data));
						
						const localeImport = import("moment/locale/"+legacyLocaleToMoment(data.user.default_locale)).then(() => {store.dispatch(setLocale(data.user.default_locale));});
						updateNavbarNotifications(API, store, setNavbarNotifications);
						that.setState({
							redirectToDashboard: true,
						});
					})
					.catch((error) => {
						console.error(error);

						store.dispatch(setAuthEmployee(data));
				
						const localeImport = import("moment/locale/"+legacyLocaleToMoment(data.user.default_locale)).then(() => {store.dispatch(setLocale(data.user.default_locale));});
						updateNavbarNotifications(API, store, setNavbarNotifications);
						that.setState({
							redirectToDashboard: true,
						});
					}
				);
			}).catch(function (error) {
				console.log(error);
				that.setState({
					busyLoading: false,
					// errorMessage: intl.formatMessage({id: i18nBase+"PASSWORD_OR_EMAIL_INCORRECT"}),
				});
			}).finally(function () {
				// always executed
			});
		}

		render() {
			if (this.state.redirectToDashboard === true) {
				return <Redirect to='/admin/dashboard' />
			}

			if (this.state.redirectToLogout === true) {
				return <Redirect to='/auth/logout-page' />
			}

			if (this.state.redirectToLogin === true) {
				return <Redirect to='/auth/login-page' />
			}

			return <></>;
		}
}

export default SwitchPage;