import PandoraModel from "traits/PandoraModel";
import FastfoodIcon from '@material-ui/icons/Fastfood';
import * as License from "license.js";
import Menu from "./Menu";

class MenuItem extends PandoraModel {
	static modelName = "MenuItem";
	static getLicense() { return License.ICECASH}

	static icon = FastfoodIcon;

	static orderable = true;
	static copyable = true;
	static loadBeforeCopy = false;
	static hasCrudPages = false;
	static crudAsNotification = true;
	static editableOnTable = true;

	// PANDORA_ANCHOR_INSERT_OVERRIDE_VARIABLES_HERE

	static cards = [
		{
			title: "GENERAL_INFO",
			icon: FastfoodIcon,
			fields: {
				// PANDORA_ANCHOR_INSERT_FIELDS_HERE
				menu_id: {type: "hidden", },
				
				name: {type: "text", required: true, md: 9, showOnTable: true, showOnCopy: true, },
				vat_rate: {type: "number", required: true, endAdornment: "%", inputProps: {min: 0, max: 100, step: 1}, default: 21, md: 3, showOnTable: true, showOnCopy: true, },
				is_discount: {type: "checkbox", md: 3, },
				price_vati: {type: "number", required: true, startAdornment: (state) => (state.is_discount ? "‑" : "")+(state.currency == "EUR" ? "€\u00A0" : ""), endAdornment: (state) => (state.currency == "PCT" ? "%" : ""), value: (resource) => (Math.abs(resource.price_vati)), formdataValue: (state) => (Math.abs(state.price_vati) * (state.is_discount ? -1 : 1)), inputProps: {min: 0.00, step: 0.01}, md: 6, showOnTable: true, showOnCopy: true, },
				currency: {type: "select", required: true, options: ["EUR", "PCT"], default: "EUR", md: 3, showOnTable: true, showOnCopy: true, },
				color_text: {type: "color", palette: "text", required: true, default: "#000000", md: 6, },
				color_background: {type: "color", palette: "background", required: true, default: "#FFFFFF", md: 6, },
				
				is_newline: {type: "hidden", default: false},
				is_space: {type: "hidden", default: false},
			},
		},
		// {
		// 	title: "GENERAL_INFO",
		// 	icon: FiberNewIcon,
		// 	fields: {
		//
		// 	},
		// },
		
		// {
		// 	title: "GENERAL_INFO",
		// 	icon: FiberNewIcon,
		// 	fields: {
		// 		// text: {type: "text", required: true},
		// 		// textarea: {type: "text", multiline: true, rows: 12, required: true},
		// 		// number: {type: "number", required: true, inputProps: {min: 0, max: 99999, step: 1}},
		// 		// select: {type: "select", required: true, options: ["OPTION"]},
				
		// 		// md12: {type: "text", md: 12},
		// 		// showOnTable: {type: "text", showOnTable: false},
		// 	},
		// },

	];

	// PANDORA_ANCHOR_INSERT_OVERRIDE_FUNCTIONS_HERE
	static getPermissionsFromParent() { return Menu; };
}

export default MenuItem;