import { clearAuth, setLastValidCall, setToken } from "Redux/AuthActions";
import moment from "moment";
import PandoraModel from "traits/PandoraModel";

export function removeCSSClass(ele, cls) {
	const reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
	ele.className = ele.className.replace(reg, " ");
}

export function addCSSClass(ele, cls) {
	ele.classList.add(cls);
}

export const toAbsoluteUrl = pathname => process.env.PUBLIC_URL + pathname;

export function setupAxios(axios, store) {
	axios.interceptors.request.use(
		function (config) {
			// Do something before request is sent
			const token = store.getState().auth.token;
			
			if (token) {
				config.headers.Authorization = `Bearer ${token}`;
			}

			return config;
		}, function (error) {
			// Do something with request error
			return Promise.reject(error);
		}
	);

	// Add a response interceptor
	axios.interceptors.response.use(function (response) {
		// Do something with response data
		store.dispatch(setLastValidCall(Math.floor((+ new Date())/1000)));
		return response;
	}, async function (error) {
		// Do something with response error
		console.error(JSON.stringify(error));
		console.log(error.response);
		const originalRequest = error.config;
		
		if (error && !error.response && error.config.method == "get") {
			//CORS Error because Varnish fucked up
			//Try again
			if (!originalRequest._retry) {
				originalRequest._retry = true;
				return axios(originalRequest);
			}
		} else if (error.response?.status === 401) { //Unauthorized
			//The jwt-token has expired,
			//Check if we're gonna logout or refresh the token
			const lvc = store.getState().auth.lvc;
			const expires_in = store.getState().auth.expires_in;
			if (Math.floor((+ new Date()) /1000) <= lvc+expires_in) {
				//User is still using the frontend,
				//Check if this request has already been retried
				if (!originalRequest._retry) {
					originalRequest._retry = true;
					await refreshAccessToken(axios, store);
    			// axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
    			return axios(originalRequest);
				} else {
					//Something went wrong
					//Clear the authEmployee and redirect to mainpage
					store.dispatch(clearAuth());
				}
			} else {
				//Clear the authEmployee and redirect to mainpage
				store.dispatch(clearAuth());
			}
		}

		return Promise.reject(error);
	});
}

export async function refreshAccessToken(API, store) {
	const access_token = store.getState().auth.token;

	const res = await API.post("auth/refresh", {});
	const data = res.data;
	const jwt = parseJwt(data.access_token);
	await store.dispatch(setToken(data.access_token, jwt.exp, data.expires_in));
}

/*	removeStorage: removes a key from localStorage and its sibling expiracy key
		params:
				key <string>		 : localStorage key to remove
		returns:
				<boolean> : telling if operation succeeded
	*/
export function removeStorage(key) {
	try {
		localStorage.setItem(key, "");
		localStorage.setItem(key + "_expiresIn", "");
	} catch (e) {
		console.log(
			"removeStorage: Error removing key [" +
				key +
				"] from localStorage: " +
				JSON.stringify(e)
		);
		return false;
	}
	return true;
}

/*	getStorage: retrieves a key from localStorage previously set with setStorage().
		params:
				key <string> : localStorage key
		returns:
				<string> : value of localStorage key
				null : in case of expired key or failure
	*/
export function getStorage(key) {
	const now = Date.now(); //epoch time, lets deal only with integer
	// set expiration for storage
	let expiresIn = localStorage.getItem(key + "_expiresIn");
	if (expiresIn === undefined || expiresIn === null) {
		expiresIn = 0;
	}

	expiresIn = Math.abs(expiresIn);
	if (expiresIn < now) {
		// Expired
		removeStorage(key);
		return null;
	} else {
		try {
			const value = localStorage.getItem(key);
			return value;
		} catch (e) {
			console.log(
				"getStorage: Error reading key [" +
					key +
					"] from localStorage: " +
					JSON.stringify(e)
			);
			return null;
		}
	}
}
/*	setStorage: writes a key into localStorage setting a expire time
		params:
				key <string>		 : localStorage key
				value <string>	 : localStorage value
				expires <number> : number of seconds from now to expire the key
		returns:
				<boolean> : telling if operation succeeded
	*/
export function setStorage(key, value, expires) {
	if (expires === undefined || expires === null) {
		expires = 24 * 60 * 60; // default: seconds for 1 day
	}

	const now = Date.now(); //millisecs since epoch time, lets deal only with integer
	const schedule = now + expires * 1000;
	try {
		localStorage.setItem(key, value);
		localStorage.setItem(key + "_expiresIn", schedule);
	} catch (e) {
		console.log(
			"setStorage: Error setting key [" +
				key +
				"] in localStorage: " +
				JSON.stringify(e)
		);
		return false;
	}
	return true;
}

export function hasLicense(authEmployee, licenses) {
	if (!Array.isArray(licenses)) {
		licenses = [licenses];
	}

	return authEmployee.company.licenses.some(function(license) {
		return licenses.includes(license.product_name);
	});
}

export function hasPermission(authEmployee, permissions) {
	if (!Array.isArray(permissions)) {
		permissions = [permissions];
	}

	if (permissions.length == 0) return true;

	return authEmployee.is_root || (authEmployee.permissions ?? []).some(function(permission) {
		return permissions.includes(permission.name);
	}) || (authEmployee.permission_groups && authEmployee.permission_groups.some(function(permissionGroup) {
		return permissionGroup.permissions.some(function(permission) {
			return permissions.includes(permission.name);
		});
	}));
}

export function getPermissionId(authEmployee, searchPermission) {
	let retValue = false;
	authEmployee.permissions.some(function(permission) {
		if (permission.name === searchPermission) {
			retValue = permission.id;
			return true;
		} else {
			return false;
		}
	});
	return retValue;
}

export function getParams(url) {
	var params = {};
	var parser = document.createElement('a');
	parser.href = url;
	var query = parser.search.substring(1);
	var vars = query.split('&');
	for (var i = 0; i < vars.length; i++) {
		var pair = vars[i].split('=');
		params[pair[0]] = decodeURIComponent(pair[1]);
	}
	return params;
};

export function setUrlParam(key, value) {
  if (window.history.pushState) {
		let searchParams = new URLSearchParams(window.location.search);
		key.includes("[]") ? searchParams.append(key, value) : searchParams.set(key, value);
		let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
		window.history.pushState({path: newurl}, '', newurl);
  }
}

export function unsetUrlParam(key) {
  if (window.history.pushState) {
		let searchParams = new URLSearchParams(window.location.search);
		searchParams.delete(key);
		let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
		window.history.pushState({path: newurl}, '', newurl);
  }
}

export function groupBy(xs, key) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export function randomString(length, possible="ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789") {
	var text = "";
	for(var i = 0; i < length; i++) {
		text += possible.charAt(Math.floor(Math.random() * possible.length));
	}
	return text;
}

export function timestamp(str) {
    return new Date(str).getTime();
}

export const mapAdjacent = (mapping, array) => {
    const {length} = array, size = length - 1, result = new Array(size);
    for (let i = 0; i < size; i++) result[i] = mapping(array[i], array[i + 1]);
    return result;
};

export const secondsToFormattedTime = (totalTimeSeconds) => {
	const hours = Math.floor(totalTimeSeconds / 3600);
	totalTimeSeconds %= 3600;
	const minutes = Math.floor(totalTimeSeconds / 60);
	const seconds = totalTimeSeconds % 60;

	return hours+":"+(minutes < 10 ? "0" : "")+minutes+"'"+(seconds < 10 ? "0" : "")+seconds+"\"";
};

export const hexToRgb = (hex) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

export const nthOccurrence = (str, pat, n) => {
	n++;
	var L= str.length, i= -1;
	while(n-- && i++<L){
			i= str.indexOf(pat, i);
			if (i < 0) break;
	}
	return i;
}

export const uppercaseFirstLetterLowercaseRest = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export const uppercaseCaseFirstLetter = (string) => {
	if (!string) return string; 
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export const lowerCaseFirstLetter = (string) => {
	return string.charAt(0).toLowerCase() + string.slice(1);
}

export const licenseToSourcecodeName = (license) => {
	return license.slice(0, 3) + license.slice(3).toLowerCase();
}

export const updateNavbarNotifications = (API, store, setNavbarNotifications) => {
	API.get("notification/navbar").then(res => {
		const data = res.data.data;

		store.dispatch(setNavbarNotifications(data));
	}).catch(function (error) {
		console.log(error);
	}).finally(function () {
		// always executed
	});
}

export const convertToHex = (str) => {
	var hex = '';
	for(var i=0;i<str.length;i++) {
			hex += ''+str.charCodeAt(i).toString(16);
	}
	return hex;
}

export const pickTextColorBasedOnBgColorAdvanced = (bgColor, lightColor = "#FFFFFF", darkColor = "#000000") => {
	var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
	var r = parseInt(color.substring(0, 2), 16); // hexToR
	var g = parseInt(color.substring(2, 4), 16); // hexToG
	var b = parseInt(color.substring(4, 6), 16); // hexToB
	var uicolors = [r / 255, g / 255, b / 255];
	var c = uicolors.map((col) => {
		if (col <= 0.03928) {
			return col / 12.92;
		}
		return Math.pow((col + 0.055) / 1.055, 2.4);
	});
	var L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2]);
	return (L > 0.179) ? darkColor : lightColor;
}

export const standardisePhoneNr = (raw, defaultPrefix = "32") => {
	defaultPrefix = defaultPrefix.replace(/[^0-9]/g, '');

	if (raw.startsWith("+") || raw.startsWith("00") || raw.startsWith(defaultPrefix)) {
		return "+" + raw.replace(/[^0-9]/g, '').replace(/^(\+)|(00)/, "");
	} else {
		return "+" + defaultPrefix + raw.replace(/[^0-9]/g, '').replace(/^0/, "");
	}
}

export const openWindowWithPost = (url, data) => {
	var form = document.createElement("form");
	form.target = "_blank";
	form.method = "POST";
	form.action = url;
	form.style.display = "none";

	for (var key in data) {
			var input = document.createElement("input");
			input.type = "hidden";
			input.name = key;
			input.value = data[key];
			form.appendChild(input);
	}

	document.body.appendChild(form);
	form.submit();
	document.body.removeChild(form);
}

export const chunkArray = (a, size = 25) => {
	return Array.from(
			new Array(Math.ceil(a.length / size)),
			(_, i) => a.slice(i * size, i * size + size)
	);
}

export const getEveryNth = (arr, nth, offset = 0) => {
  const result = [];

  for (let i = offset; i < arr.length; i += nth) {
    result.push(arr[i]);
  }

  return result;
}

export const camelCaseToUnderscore = (s) => {
	return s.replace(/\.?([A-Z])/g, function (x,y){return "_" + y.toLowerCase()}).replace(/^_/, "");
}

export const legacyLocaleToMoment = (locale) => {
	switch (locale) {
		case "nl":
			return "nl-be";
		case "en":
			return "en-gb";
		case "fr":
			return "fr";
		case "de":
			return "de";
	}
	return locale;
}

export const parseJwt = (token) => {
	var base64Url = token.split('.')[1];
	var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
			return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
	}).join(''));

	return JSON.parse(jsonPayload);
};

export async function handleBulkArray(array, functionPayload, functionProgress, functionDone, chunkSize = 5) {
	if (array.length > chunkSize) {
		const chunkedArray = chunkArray(array, chunkSize);

		for (const chunk of chunkedArray) {
			await handleBulkArray(chunk, functionPayload, functionProgress, null, chunkSize);
		}
		if(functionDone) functionDone();
	} else {
		await Promise.all(array.map(async (element) => {
			const ret = await functionPayload(element);
			if(functionProgress) functionProgress(ret);
		}));
		if(functionDone) functionDone();
	}
}

export const isSameDay = (moment1, moment2) => {
	return (moment1 && moment2) ? (moment1.format("MM-DD") == moment2.format("MM-DD")) : false;
}

export const joinWithSeparator = (array, separator, separatorLast = null) => {
	if (!separatorLast || array.length <= 1) return array.join(separator);
	return array.slice(0, -1).join(separator)+separatorLast+array.slice(-1);
}

export const getCRUDKeywordGroup = (cRUDKeyword) => {
	if (cRUDKeyword == "index" || cRUDKeyword == "show") {
		return "view";
	} else if (cRUDKeyword == "update" || cRUDKeyword == "store" || cRUDKeyword == "fileupload") {
		return "moderate";
	} else {
		return "unknown";
	}
}

export const isAttributeVisible = (props) => {
	if (props.type == "hidden") {
		return false;
	}

	if (props.hasOwnProperty('visible')) {
		if (props.visible === false) {
			return false;
		} else if (typeof props.visible == "string") {
			if (props.visible.charAt(0) == "!") {
				if (props.state[props.visible.substring(1)]) {
					return false;
				}
			} else {
				if (!props.state[props.visible]) {
					return false;
				}
			}
		} else if (typeof props.visible == "function") {
			if (!props.visible(props.state, props.props)) {
				return false;
			}
		}
	}

	const showValue = (props["showOn"+uppercaseCaseFirstLetter(props.method)] ?? props["showOn"+uppercaseCaseFirstLetter(getCRUDKeywordGroup(props.method))] ?? props.show ?? (props.method == "copy" ? false : true));
	if (typeof showValue == "boolean") {
		return showValue;
	} else if (typeof showValue == "string") {
		if (showValue.charAt(0) == "!") {
			return (props.state[showValue.substring(1)] ? false : true);
		} else {
			return (props.state[showValue] ? true : false);
		}
	} else if (typeof showValue == "function") {
		return showValue(props.state);
	} else {
		return true;
	}
}

export const getAttributeValidationValues = (model, extraState, method) => {
	let ret = {};
	(model.getAttributesAsArray?.() ?? PandoraModel.getAttributesAsArray(model)).forEach(attribute => {
		if (attribute.readonly ?? false) {
			return;
		}
		ret[attribute.name] = (
			method == "store" ? (
				(attribute.required ?? false) ? ((attribute.default || attribute.defaultOnStore || extraState[attribute.name] || (attribute.type == "checkbox") || (attribute.type == "color") || ((attribute.type == "datetime" || attribute.type == "date" || attribute.type == "time") && (attribute.default !== ""))) ? "success" : "") : "success"
			) : (method == "show" ? "" : (
				(method == "update" || method == "copy") ? "success" : ""
			))
		);
	});
	return ret;
}

export const getAttributeValues = (model, resource, props, method = "") => {
	let ret = {};
	(model.getAttributesAsArray?.() ?? PandoraModel.getAttributesAsArray(model)).forEach(attribute => {
		if (attribute.hasOwnProperty('defaultOn' + uppercaseFirstLetterLowercaseRest(method))) {
			if (typeof attribute['defaultOn' + uppercaseFirstLetterLowercaseRest(method)] == "function") {
				ret[attribute.name] = attribute['defaultOn' + uppercaseFirstLetterLowercaseRest(method)](resource, props, model);
			} else {
				ret[attribute.name] = attribute['defaultOn' + uppercaseFirstLetterLowercaseRest(method)];
			}
		} else if (attribute.hasOwnProperty('value')) {
			if (typeof attribute.value == "function") {
				ret[attribute.name] = attribute.value(resource, props, model);
			} else {
				ret[attribute.name] = attribute.value;
			}
		} else if (attribute.type == "json") {
			ret[attribute.name] = JSON.stringify(resource[attribute.name] ?? [], null, "	");
		} else if (attribute.type == "datetimejson") {
			ret[attribute.name] = ((resource[attribute.name] && !Array.isArray(resource[attribute.name]) ? resource[attribute.name] : {}));
		} else if (attribute.type == "jsontable") {
			ret[attribute.name] = (resource[attribute.name] ?? []);
		} else if (attribute.type == "datetime") {
			if (!attribute.required && resource[attribute.name] == null) {
				ret[attribute.name] = null;
			} else {
				ret[attribute.name] = moment.utc(resource[attribute.name]).local();
			}
		} else if (attribute.type == "date") {
			let m = moment(resource[attribute.name], "YYYY-MM-DD",true);
			m = m.local().set({hour:0,minute:0,second:0,millisecond:0});
			ret[attribute.name] = (m?._isValid ?? false) ? m : resource[attribute.name];
			// ret[attribute.name] = moment.utc(resource[attribute.name]).local().set({hour:0,minute:0,second:0,millisecond:0});
		} else if (attribute.type == "time") {
			let m = moment("1970-01-01" + " " + resource[attribute.name], "YYYY-MM-DD HH:mm:ss",true);
			ret[attribute.name] = (m?._isValid ?? false) ? m : resource[attribute.name];
		} else if (attribute.type == "employee" || attribute.type == "division" || attribute.type == "deliveryVan" || attribute.type == "icecreamVan" || attribute.type == "contractTemplate") {
			ret[attribute.name] = resource[attribute.name] ? (typeof resource[attribute.name] === 'object' ? resource[attribute.name] : {id: resource[attribute.name], __metadata: {loading: true},}) : null;
		} else {
			ret[attribute.name] = (resource[attribute.name] ?? "");
		}
	});
	if (model.orderable ?? false) ret["sort_key"] = resource.sort_key;
	ret.id = resource.id;
	return ret;
}

export const getAttributeValuesForFormdata = (model, resource, props, method = "") => {
	let ret = {};
	(model.getAttributesAsArray?.() ?? PandoraModel.getAttributesAsArray(model)).forEach(attribute => {
		if (attribute.readonly ?? false) {
			return;
		}
		if (attribute.type == "file") {
			if (resource[attribute.name] !== null && typeof resource[attribute.name] !== 'string' && !(resource[attribute.name] instanceof String)) {
				return;
			}
		}
		if (attribute.hasOwnProperty('defaultFormdataOn' + uppercaseFirstLetterLowercaseRest(method))) {
			if (typeof attribute['defaultFormdataOn' + uppercaseFirstLetterLowercaseRest(method)] == "function") {
				ret[attribute.name] = attribute['defaultFormdataOn' + uppercaseFirstLetterLowercaseRest(method)](resource, props, model);
			} else {
				ret[attribute.name] = attribute['defaultFormdataOn' + uppercaseFirstLetterLowercaseRest(method)];
			}
		} else if (attribute.hasOwnProperty('formdataValue')) {
			if (typeof attribute.formdataValue == "function") {
				ret[attribute.name] = attribute.formdataValue(resource, props, model);
			} else {
				ret[attribute.name] = attribute.value;
			}
		} else if (attribute.hasOwnProperty('defaultOn' + uppercaseFirstLetterLowercaseRest(method))) {
			if (typeof attribute['defaultOn' + uppercaseFirstLetterLowercaseRest(method)] == "function") {
				ret[attribute.name] = attribute['defaultOn' + uppercaseFirstLetterLowercaseRest(method)](resource, props, model);
			} else {
				ret[attribute.name] = attribute['defaultOn' + uppercaseFirstLetterLowercaseRest(method)];
			}
		} else if (attribute.hasOwnProperty('value')) {
			if (typeof attribute.value == "function") {
				ret[attribute.name] = attribute.value(resource, props, model);
			} else {
				ret[attribute.name] = attribute.value;
			}
		} else if (attribute.type == "json") {
			ret[attribute.name] = JSON.parse(resource[attribute.name] ?? "[]");
		} else if (attribute.type == "datetime") {
			ret[attribute.name] = (resource[attribute.name]?._isValid ?? false) ? resource[attribute.name].startOf(attribute.startOf ?? null).endOf(attribute.endOf ?? null).toISOString() : null;
		} else if (attribute.type == "date") {
			ret[attribute.name] = (resource[attribute.name]?._isValid ?? false) ? resource[attribute.name].format("YYYY-MM-DD") : resource[attribute.name];
		} else if (attribute.type == "time") {
			ret[attribute.name] = (resource[attribute.name]?._isValid ?? false) ? resource[attribute.name].format("HH:mm:ss") : resource[attribute.name];
		} else if (attribute.type == "employee" || attribute.type == "division" || attribute.type == "deliveryVan" || attribute.type == "icecreamVan" || attribute.type == "contractTemplate") {
			ret[attribute.name] = resource[attribute.name] ? (resource[attribute.name]?.id ?? "") : "";
		} else if (attribute.type == "file") {
			ret[attribute.name] = (resource[attribute.name] == "") ? null : resource[attribute.name];
		} else {
			ret[attribute.name] = (resource[attribute.name] ?? "");
		}
	});
	if (model.orderable ?? false) ret["sort_key"] = resource.sort_key;
	return ret;
}

export const getFileValuesForFormdata = (model, resource, props, method = "") => {
	let hasFiles = false;
	let ret = new FormData();
	(model.getAttributesAsArray?.() ?? PandoraModel.getAttributesAsArray(model)).forEach(attribute => {
		if (attribute.type != "file") {
			return;
		}
		if (resource[attribute.name] !== null && typeof resource[attribute.name] !== 'string' && !(resource[attribute.name] instanceof String)) {
			hasFiles = true;
			const nameWithoutUri = attribute.name.replace(/_uri$/, "");
			ret.append(nameWithoutUri, resource[attribute.name]);
		}
	});
	return (hasFiles ? ret : null);
}

export const getAttributeDefaults = (model, state = {}, props = {}, method = "") => {
	let ret = {};
	(model.getAttributesAsArray?.() ?? PandoraModel.getAttributesAsArray(model)).forEach(attribute => {
		if (attribute.hasOwnProperty('defaultOn' + uppercaseFirstLetterLowercaseRest(method))) {
			if (typeof attribute['defaultOn' + uppercaseFirstLetterLowercaseRest(method)] == "function") {
				ret[attribute.name] = attribute['defaultOn' + uppercaseFirstLetterLowercaseRest(method)](state, props, model);
			} else {
				ret[attribute.name] = attribute['defaultOn' + uppercaseFirstLetterLowercaseRest(method)];
			}
		} else if (attribute.hasOwnProperty('default')) {
			if (typeof attribute.default == "function") {
				ret[attribute.name] = attribute.default(state, props, model);
			} else {
				ret[attribute.name] = attribute.default;
			}
		} else if (attribute.type == "datetime" || attribute.type == "date" || attribute.type == "time") {
			ret[attribute.name] = moment().local()
		} else if (attribute.type == "checkbox") {
			ret[attribute.name] = false;
		} else if (attribute.type == "json") {
			ret[attribute.name] = "[]";
		} else if (attribute.type == "datetimejson") {
			ret[attribute.name] = {};
		} else if (attribute.type == "jsontable") {
			ret[attribute.name] = [];
		} else if (attribute.type == "color") {
			if ((attribute.palette ?? "") == "background") {
				ret[attribute.name] = "#FFFFFF";
			} else {
				ret[attribute.name] = "#000000";
			}
		} else if (attribute.type == "employee" || attribute.type == "division" || attribute.type == "deliveryVan" || attribute.type == "icecreamVan" || attribute.type == "contractTemplate") {
			ret[attribute.name] = null;
		} else {
			ret[attribute.name] = "";
		}
	});
	return ret;
}