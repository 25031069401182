import React, {Component} from "react"
import { withStyles } from '@material-ui/core/styles';

import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";

class VanButton extends Component {	
	render() {
		const { classes } = this.props;
		
		return (
			<Button
				size="sm"
				className={classes.marginRight + " " + classes.transform}
				style={{
					color: this.props.color_text,
					backgroundColor: this.props.color_background,
				}}
			>
				{this.props.name}
			</Button>
		)
	}
}

export default withStyles((theme) => ({ ...styles }), { withTheme: true })(VanButton)