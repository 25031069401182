// This is used to determine if a user is authenticated and
// if they are allowed to visit the page they navigated to.

// If they are: they proceed to the page
// If not: they are redirected to the login page.
import React from 'react'
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom'
import {store} from "index";
import { setAuthScreen, clearAuth, setRedirectFrom } from "Redux/AuthActions";
import API from 'api';
import { setNavbarNotifications } from "Redux/NotificationsActions";
import { updateNavbarNotifications, legacyLocaleToMoment } from "utils";

class PrivateScreenRoute extends React.Component {
	constructor(props) {
		super(props);

		this.handleLoad = this.handleLoad.bind(this);
	}

	componentDidMount() {
		window.addEventListener("load", this.handleLoad);
	}

	handleLoad() {
		const that = this;
		const { authScreen } = this.props;

		const localeImport = import("moment/locale/"+legacyLocaleToMoment(authScreen?.default_locale ?? "en"));

		API.get("screenauth/screen").then(res => {
			const data = res.data.data;
			store.dispatch(setAuthScreen(data));
		}).catch(function (error) {
			console.log(error);
			store.dispatch(clearAuth());
		}).finally(function () {
			// always executed
		});
	}

	render() {
		const { component: Component, authScreen, ...rest } = this.props;

		// Add your own authentication on the below line.
		const isLoggedIn = !(!this.props.authScreen);
		// const isLoggedIn = true;

		if (!isLoggedIn) {
			store.dispatch(setRedirectFrom(this.props.location.pathname+this.props.location.search));
		}

		return (
			<Route
				{...rest}
				render={props =>
					isLoggedIn ? (
						<Component {...props} authScreen={authScreen} />
					) : (
						<Redirect push to={{ pathname: '/auth/screen', state: { from: props.location } }} />
					)
				}
			/>
		)
	}
}

function mapStateToProps(state) {
	return {
			authScreen: state.auth.authScreen ?? null,
	}
}

export default connect(mapStateToProps)(PrivateScreenRoute);