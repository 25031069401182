import * as actionType from './AuthActionType';

const initialState = {
		authEmployee: null,
		authScreen: null,
		token: null,
		expires_in: 3600,
		exp: 0,
		lvc: 0,
		redirectFrom: null,
}

const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionType.SET_AUTH_EMPLOYEE:
			return {...state, authEmployee: action.payload, authScreen: null};
			
		case actionType.SET_AUTH_SCREEN:
			return {...state, authScreen: action.payload, authEmployee: null};

		case actionType.SET_LAST_VALID_CALL:
			return {...state, lvc: action.payload.lvc};

		case actionType.SET_TOKEN:
			return {...state, token: action.payload.token, exp: action.payload.exp, expires_in: action.payload.expires_in};

		case actionType.CLEAR_AUTH:
			return {...state, token: null, exp: 0, authEmployee: null, authScreen: null};

		case actionType.SET_REDIRECT_FROM:
			return {...state, redirectFrom: action.payload};
		
		default:
			return state
	}
}

export default authReducer;