import React, {Component} from "react"
import {store} from "index";
import { Redirect, Link } from "react-router-dom";
import { clearAuth } from "Redux/AuthActions";

class LogoutPage extends React.Component {
		constructor(props) {
				super(props);
				this.state = {
					redirectToLogin: false,
				};
		}
		
		async componentDidMount() {
				await store.dispatch(clearAuth());
				this.setState({
						redirectToLogin: true,
				});
		}

		render() {
				if (this.state.redirectToLogin === true) {
						return <Redirect to='/auth/login-page' />
				}

				return <></>;
		}
}

export default LogoutPage;