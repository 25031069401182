import React, {Component} from "react"
import { BrowserRouter as Router, Route, Link, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import { connect } from 'react-redux';

// core components
import PrivateRoute from './PrivateRoute'
import PrivateScreenRoute from './PrivateScreenRoute'
import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import ScreenLayout from "layouts/Screen.js";

const hist = createBrowserHistory();

class App extends Component {
		render() {
				return (
						<Router history={hist}>
								<Switch>
										<Route path="/auth/login-page" component={AuthLayout} />
										<Route path="/auth/screen" component={AuthLayout} />
										<Route path="/auth/password-reset" component={AuthLayout} />
										<Route path="/auth/logout-page" component={AuthLayout} />
										<Route path="/auth/switch" component={AuthLayout} />
										<PrivateRoute path="/admin" component={AdminLayout} authEmployee={this.props.authEmployee} />
										<PrivateScreenRoute path="/screen" component={ScreenLayout} authScreen={this.props.authScreen} />
										<Redirect from="/" to="/admin/dashboard" />
								</Switch>
						</Router>
				);
		}
}

function mapStateToProps(state) {
		return {
				authEmployee: state.auth.authEmployee ?? null,
				authScreen: state.auth.authScreen ?? null,
		}
}

export default connect(mapStateToProps)(App);