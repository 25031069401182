import React from 'react';
import PandoraModel from "traits/PandoraModel";
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import * as License from "license.js";

class Contract extends PandoraModel {
	static modelName = "Contract";
	static getLicense() { return License.ICECLOCK}

	static icon = AssignmentIndIcon;

	// static orderable = true;
	// static copyable = true;
	// static loadBeforeCopy = false;
	// static hasCrudPages = false;
	static crudAsNotification = true;
	// static editableOnTable = true;

	// PANDORA_ANCHOR_INSERT_OVERRIDE_VARIABLES_HERE

	static cards = [
		{
			title: "GENERAL_INFO",
			icon: AssignmentIndIcon,
			fields: {
				// PANDORA_ANCHOR_INSERT_FIELDS_HERE
				working_day: {type: "hidden", },

				employee: {type: "json", showOnTable: true, valueOnTable: (resource) => resource.working_day.employee.last_name.toUpperCase() + " " + resource.working_day.employee.first_name, },
				division: {type: "json", showOnTable: true, valueOnTable: (resource) => resource.working_day.division.name+" ("+resource.working_day.division.establishment_unit.name+")", },
				created_at: {type: "datetime", showOnTable: true, },
				printed: {type: "checkbox", showOnTable: true, editableOnTable: false, },
				validated: {type: "checkbox", showOnTable: true, editableOnTable: false, },
				pdf_public_uri: {type: "hidden", },
			},
		},
		// {
		// 	title: "GENERAL_INFO",
		// 	icon: UnknownIcon,
		// 	fields: {
		//
		// 	},
		// },
		
		// {
		// 	title: "GENERAL_INFO",
		// 	icon: UnknownIcon,
		// 	fields: {
		// 		// text: {type: "text", required: true, },
		// 		// textarea: {type: "text", multiline: true, rows: 12, required: true, },
		// 		// number: {type: "number", required: true, inputProps: {min: 0, max: 99999, step: 1}, },
		// 		// select: {type: "select", required: true, options: ["OPTION"], },
		// 		// checkbox: {type: "checkbox", },
				
		// 		// md12: {type: "text", md: 12, },
		// 		// showOnTable: {type: "text", showOnTable: true, },
		// 	},
		// },

	];

	// PANDORA_ANCHOR_INSERT_OVERRIDE_FUNCTIONS_HERE
	// static getCopyableChildren() { return [ContractItem]; };
	static getIndexPermissions() {
		return [
			'ICECLOCK_CONTRACTS_MODERATE',
			'ICECLOCK_CONTRACTS_VIEW',
			'ICECLOCK_CONTRACTS_EDIT_NOT_VALIDATED',
		];
	}
}

export default Contract;