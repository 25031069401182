import PandoraModel from "traits/PandoraModel";
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import * as License from "license.js";

class ContractTemplate extends PandoraModel {
	static modelName = "ContractTemplate";
	static getLicense() { return License.ICECLOCK}

	static icon = BookmarksIcon;

	// PANDORA_ANCHOR_INSERT_OVERRIDE_VARIABLES_HERE
	static copyable = true;

	static cards = [
		{
			title: "GENERAL_INFO",
			icon: BookmarksIcon,
			fields: {
				// PANDORA_ANCHOR_INSERT_FIELDS_HERE
				name: {type: "text", required: true, showOnCopy: true, showOnTable: true, },
				print_schedule: {type: "select", options: ["ALWAYS", "ONCE_PER_DAY", "ONCE_EVERY_6_MONTHS"], required: true, showOnTable: true, },
				html_content: {type: "text", required: true, multiline: true, rows: 12, },
				html_content_replace: {type: "label", parseHtml: true, }
				
				// text: {type: "text", required: true},
				// textarea: {type: "text", multiline: true, rows: 12, required: true},
				// number: {type: "number", required: true, inputProps: {min: 0, max: 99999, step: 1}},
				// select: {type: "select", required: true, options: ["OPTION"]},
				
				// md12: {type: "text", md: 12},
				// showOnTable: {type: "text", showOnTable: false},
			},
			md: 12,
		},
	];

	// PANDORA_ANCHOR_INSERT_OVERRIDE_FUNCTIONS_HERE
}

export default ContractTemplate;