import React from 'react';
import PandoraModel from "traits/PandoraModel";
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import * as License from "license.js";
import DeliverableIcon from "components/DeliverableIcon/DeliverableIcon.js";
import moment from "moment";

const seedConfigStructure = {
	active: {type: "checkbox", default: true, },
	icon_type: {type: "hidden", default: "FEATHER", },
	icon: {type: "icon", default: "calendar", types: ["FEATHER", "FONT_AWESOME"], },
	title: {type: "text", default: "All day", },
	time_start: {type: "time", default: "09:00:00", },
	time_stop: {type: "time", default: "17:00:00", },
	price_vati: {type: "number", startAdornment: "€\u00A0", inputProps: {min: 0.00, step: 0.01}, default: "0", },
};

class DeliveryZone extends PandoraModel {
	static modelName = "DeliveryZone";
	static getLicense() { return License.ICESHOP}

	static icon = LocalShippingIcon;

	// PANDORA_ANCHOR_INSERT_OVERRIDE_VARIABLES_HERE
	// static orderable = true;
	static copyable = true;

	static cards = [
		{
			title: "GENERAL_INFO",
			icon: LocalShippingIcon,
			fields: {
				// PANDORA_ANCHOR_INSERT_FIELDS_HERE
				active: {type: "checkbox", sm: 6, showOnTable: true, defaultOnCopy: false, },
				"//empty_after_active": {type: "empty", sm: 6, },
				abbreviation: {type: "text", sm: 4, showOnCopy: true, },
				name: {type: "text", required: true, sm: 8, showOnTable: true, valueOnTable: (resource) => <DeliverableIcon resource={resource} type="DELIVERY_ZONE" />, showOnCopy: true, },
			},
		},
		{
			title: "SEED_CONFIG",
			sm: 12,
			icon: AccessAlarmIcon,
			fields: {
				seed_config: {type: "datetimejson", structure: seedConfigStructure, timeslotShorthand: (timeslot) => (<span style={{marginLeft: "10px", marginRight: "30px"}}>{"€ " + timeslot.price_vati + " (" + moment("1970-01-01" + " " + timeslot.time_start).format("HH:mm") + "-" + moment("1970-01-01" + " " + timeslot.time_stop).format("HH:mm") +")"}</span>), },
			},
		},
		// {
		// 	title: "GENERAL_INFO",
		// 	icon: LocalShippingIcon,
		// 	fields: {
		//
		// 	},
		// },
		
		// {
		// 	title: "GENERAL_INFO",
		// 	icon: LocalShippingIcon,
		// 	fields: {
		// 		// text: {type: "text", required: true, },
		// 		// textarea: {type: "text", multiline: true, rows: 12, required: true, },
		// 		// number: {type: "number", required: true, inputProps: {min: 0, max: 99999, step: 1}, },
		// 		// select: {type: "select", required: true, options: ["OPTION"], },
		// 		// checkbox: {type: "checkbox", },
				
		// 		// md12: {type: "text", md: 12, },
		// 		// showOnTable: {type: "text", showOnTable: true, },
		// 	},
		// },

	];

	// PANDORA_ANCHOR_INSERT_OVERRIDE_FUNCTIONS_HERE
	// static getCopyableChildren() { return [DeliveryZoneItem]; };
}

export default DeliveryZone;